import { SelectableModel } from './general/selectable.model';

export class ListItemModel extends SelectableModel {
  id: number;
  value: string;

  private _isSelected: boolean;
  constructor(id: number,
    value: string) {
    super();
    this.id = id;
    this.value = value;
    this._isSelected = false;
  }
  public static Parse(x): ListItemModel {
    return new ListItemModel(x['id'], x['value']);
  }

  getSelected() {
    return this._isSelected;
  }
  toggleSelected() {
    this._isSelected = !this._isSelected;
  }
  setSelected(value: boolean) {
    this._isSelected = value;
  }

  public toString = (): string => {
    return `${this.value} (id: ${this.id})`;
  }
}
