import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';

@Injectable()
export class LicenseService {
  constructor(private srv: CoreService) {

  }

  public getLicenseInvoice(id: number): Observable<any> {
    return this.srv.get(`${this.srv.baseUrl}/token/GetByInvoice/${id}`);
  }

  public getCheckStatus(orderId:number,token:string, versionId:number): Observable<any>{
    return this.srv.get(`${this.srv.baseUrl}/Token/CheckStatus/${orderId}/token/${token}/version/${versionId}`);
  }
  public getReturnToken(orderId:number,token:string, versionId:number): Observable<any>{
    return this.srv.get(`${this.srv.baseUrl}/Token/Return/${orderId}/token/${token}/version/${versionId}`);
  }

  public provisionOrder(orderId: string): Observable<any>{
    return this.srv.post(`${this.srv.baseUrl}/Token/ProvisionOrder/${orderId}`, null);
  }
}
