import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-new-client-modal',
    templateUrl: './new-client-modal.component.html',
    styleUrls: ['./new-client-modal.component.scss']
})

/** newClientModal component*/
export class NewClientModalComponent {
    title = 'Create new Account';
    /** newClientModal ctor */
    constructor(public activeModal: NgbActiveModal) {

    }


}
