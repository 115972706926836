import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  returnUrl: string;
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private msalService: MsalService) { }

  ngOnInit(): void {
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];
    if(!this.returnUrl) {
      this.logout();
    }
  }

  goBack() {
    this.router.navigate([this.returnUrl || '/']);
  }

  logout() {
    localStorage.clear();
    this.msalService.logout();
  }

}
