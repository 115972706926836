import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuditModel } from '../../../models/audit.model';
import { SalesInvoiceModel } from '../../../models/billing/sales-invoice.model';
import { AuditService, CommonService, ExcelService } from '../../../services';

@Component({
    selector: 'app-audit-modal',
    templateUrl: './audit-modal.component.html',
    styleUrls: ['./audit-modal.component.scss']
})
export class AuditModalComponent implements OnInit {

    @Input() set open(open: boolean) {
        this._open = open;
    }
    get open(): boolean {
        return this._open;
    }
    private _open: boolean;

    @Input() model: SalesInvoiceModel;

    @Output() modalClose = new EventEmitter();

    loading: boolean;

    auditData: AuditModel[] = [];
    dataFiltered: AuditModel[] = [];

    openPayload: boolean;
    payload: string;

    constructor(private accountService: AuditService,
        private excelSvc: ExcelService,
        public common: CommonService) { }

    ngOnInit(): void {
        this.getAuditList();
    }

    getAuditList() {
        this.loading = true;
        this.accountService.getAuditList(this.model?.invoiceGuid).subscribe(data => {
            this.loading = false;
            const result = (data?.result || []).sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
            this.auditData = result;
            this.dataFiltered = result;
        }, () => this.loading = false);
    }

    refresh() {
        this.getAuditList();
    }

    close(): void {
        this.closeModal(false);
    }

    closeModal(refresh?: boolean): void {
        this.open = false;
        this.modalClose.emit(refresh);
    }

    export(): void {
        if (this.dataFiltered?.length > 0) {
            this.excelSvc.exportAsExcelFile(this.dataFiltered, 'version');
        }
    }

    filter(filterValue: string) {
        filterValue = filterValue?.toUpperCase();
        this.dataFiltered = this.auditData?.filter(x => x.userFullName?.toUpperCase().indexOf(filterValue) > -1
        || x.eventType?.toString()?.toUpperCase().indexOf(filterValue) > -1
        || x.payload?.toString()?.toUpperCase().indexOf(filterValue) > -1
        || x.createdOn?.toString()?.toUpperCase().indexOf(filterValue) > -1);
    }

    openPayloadModal(selectedAudit: AuditModel) {
        this.openPayload = true;
        try {
            this.payload = JSON.parse(selectedAudit?.payload);
        } catch(ex) {
            console.log(ex);
            this.payload = '';
        }
    }

    closePayloadModal() {
        this.openPayload = false;
    }

}
