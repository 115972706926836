export class ProductAlertModel {
    id: number;
    title: string;
    limit: number;
    frequency: Object;
    recipients: string[];

    constructor(id: number,
        title: string,
        limit: number,
        frequency: Object,
        recipients: string[]) {
        this.id = id;
        this.title = title;
        this.limit = limit;
        this.frequency = frequency['daily'] + ' | ' + frequency['weekly'] + ' | ' + frequency['montly'];
        this.recipients = recipients;
    }
}
