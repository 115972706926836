import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { AccountService, BillingSalesService, CommonService, MainDashboardService } from '../../../services';
import { Subscription, catchError, forkJoin, of } from 'rxjs';
import { AccountRegionModel } from '../../../models/account.model';
import { SalesInvoiceListViewModel } from '../../../models/billing/sales-invoice.model';
import swal from 'sweetalert2';
import { AccountRegistratinModel } from '../../../models/account/account-registration.model';
import { ClientMainDashModel } from '../../../models/ClientMainDash.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.html',
  styleUrls: [
    './dashboard.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})


export class DashboardPageComponent implements OnInit, OnDestroy {
  regionId: number;
  regionList: AccountRegionModel[] = [];

  private subscripe$: Subscription = new Subscription();


  orderQueueData: Array<SalesInvoiceListViewModel> = [];
  loadingQueue: boolean;
  deniedOrdereData: Array<SalesInvoiceListViewModel> = [];
  loadingDeniedOrder: boolean;

  loadingAll: boolean;
  initLoading: boolean;

  selectedNoOfDays = 1;

  registrationQueueData: Array<AccountRegistratinModel> = [];
  loadingRegistrationQueue: boolean;

  accountRegionData: Array<ClientMainDashModel> = [];
  loadingAccountRegion: boolean;

  constructor(private authService: AuthService, private common: CommonService,
    private billingSvc: BillingSalesService, private svc: AccountService,
    private srv: MainDashboardService) { }

  ngOnInit() {
    this.init();
    this.subscripe$.add(this.common.selectedRegionsChange$.subscribe((list: AccountRegionModel[]) => {
      this.regionList = list || [];
      if (this.regionList?.length > 0) {
        this.getDatas();
      }
    }));
  }

  init() {
    this.regionId = Number.parseInt((localStorage.getItem('current_region') ? localStorage.getItem('current_region') : this.authService.getRegion()) ?? undefined);
    this.regionList = this.common.selectedRegions;
    if (this.regionList?.length > 0) {
      this.getDatas();
    }
  }

  getDatas() {
    this.orderQueueData = [];
    this.deniedOrdereData = [];
    this.registrationQueueData = [];
    this.accountRegionData = [];
    this.loadingAll = true;
    this.initLoading = true;
    this.regionList.forEach((x, i) => {
        this.common.onLoadingRegionChange(x.id);
        forkJoin({
          orderQueue: this.billingSvc.getQueueInvoices(x.id).pipe(catchError(error => of(error))),
          deniedOrder: this.billingSvc.getDeniedInvoices(x.id, this.selectedNoOfDays).pipe(catchError(error => of(error))),
          registrationQueue: this.svc.getRegistrationByRegion(x.id).pipe(catchError(error => of(error))),
          accountRegion: this.srv.getAccounts(x.id).pipe(catchError(error => of(error)))
        }).toPromise().then(data => {
          this.initLoading = false;
          if(i === this.regionList?.length-1) {
            this.common.onLoadingRegionChange(-1);
            this.loadingAll = false;
          }
          if (data?.orderQueue) {
            this.onGetOrderQueue(data?.orderQueue, this.regionList[i].name);
          }
          if(data?.deniedOrder) {
            this.onGetDeniedOrder(data?.deniedOrder, this.regionList[i].name);
          }
          if(data?.registrationQueue) {
            this.onGetRegistrationQueue(data?.registrationQueue, this.regionList[i].name);
          }
          if(data?.accountRegion) {
            this.onGetAccountRegion(data?.accountRegion, this.regionList[i].name);
          }
        });
    });
  }

  refreshOrderQueue() {
    this.orderQueueData = [];
    const list: any = {};
    this.regionList.forEach((x, i) => {
      list[`orderQueue${i}`] = this.billingSvc.getQueueInvoices(x.id).pipe(catchError(error => of(error)));
    });
    this.loadingQueue = true;
    forkJoin(list).toPromise().then((data: any) => {
      this.loadingQueue = false;
      if (data) {
        Object.keys(data || {}).forEach((d, j) => {
          if (d) {
            this.onGetOrderQueue(data[d], this.regionList[j].name);
          }
        })
      }
    });
  }

  onGetOrderQueue(data, region) {
    if ( data['result']) {
      data['result'].forEach(x => {
        const parseData = SalesInvoiceListViewModel.parse(x);
        parseData['region'] = region;
        this.orderQueueData.push(parseData);
      });
    } else if(data?.errorMessage) {
      this.onGetError(data?.errorMessage);
    }
  }


  refreshDeniedOrder(noOfDays: number) {
    this.selectedNoOfDays = noOfDays;
    this.deniedOrdereData = [];
    const list: any = {};
    this.regionList.forEach((x, i) => {
      list[`deniedOrder${i}`] = this.billingSvc.getDeniedInvoices(x.id, this.selectedNoOfDays).pipe(catchError(error => of(error)));
    });
    this.loadingDeniedOrder = true;
    forkJoin(list).toPromise().then((data: any) => {
      this.loadingDeniedOrder = false;
      if (data) {
        Object.keys(data || {}).forEach((d, j) => {
          if (d) {
            this.onGetDeniedOrder(data[d], this.regionList[j].name);
          }
        })
      }
    });
  }

  onGetDeniedOrder(data, region) {
    if (data['result']) {
      data['result'].forEach(x => {
        const parseData = SalesInvoiceListViewModel.parse(x);
        parseData['region'] = region;
        this.deniedOrdereData.push(parseData);
      });
    } else if(data?.errorMessage) {
      this.onGetError(data?.errorMessage);
    }
  }

  refreshRegistrationQueue() {
    this.registrationQueueData = [];
    const list: any = {};
    this.regionList.forEach((x, i) => {
      list[`registrationQueue${i}`] = this.svc.getRegistrationByRegion(x.id).pipe(catchError(error => of(error)));
    });
    this.loadingRegistrationQueue = true;
    forkJoin(list).toPromise().then((data: any) => {
      this.loadingRegistrationQueue = false;
      if (data) {
        Object.keys(data || {}).forEach((d, j) => {
          if (d) {
            this.onGetRegistrationQueue(data[d], this.regionList[j].name);
          }
        })
      }
    });
  }

  onGetRegistrationQueue(data, region) {
    if (data['result']) {
      data['result'].forEach(x => {
        const parseData = AccountRegistratinModel.parse(x);
        parseData['region'] = region;
        this.registrationQueueData.push(parseData);
      });
    } else if(data?.errorMessage) {
      this.onGetError(data?.errorMessage);
    }
  }

  refreshAccountRegion() {
    this.accountRegionData = [];
    const list: any = {};
    this.regionList.forEach((x, i) => {
      list[`accountRegion${i}`] = this.srv.getAccounts(x.id).pipe(catchError(error => of(error)));
    });
    this.loadingAccountRegion = true;
    forkJoin(list).toPromise().then((data: any) => {
      this.loadingAccountRegion = false;
      if (data) {
        Object.keys(data || {}).forEach((d, j) => {
          if (d) {
            this.onGetAccountRegion(data[d], this.regionList[j].name);
          }
        })
      }
    });
  }

  onGetAccountRegion(data, region) {
    if (data['result']) {
      data['result'].forEach(x => {
        const parseData = ClientMainDashModel.parse(x);
        parseData['region'] = region;
        this.accountRegionData.push(parseData);
      });
    } else if(data?.errorMessage) {
      this.onGetError(data?.errorMessage);
    }
  }

  onGetError(err) {
    this.onError(err);
  }
  onError(msg) {
    swal({
      position: 'top-end',
      type: 'error',
      title: 'Oops...',
      text: msg || '',
      footer: '<a href>Why do I have this issue?</a>'
    });
  }


  ngOnDestroy(): void {
    this.subscripe$?.unsubscribe();
  }

}
