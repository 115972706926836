import { SelectableModel } from '../general/selectable.model';
import { CurrencyModel } from './currency.model';
import { AccountModel } from '../account.model';
export class SalesInvoiceItemModel extends SelectableModel {
    id: number;
    invoiceId: number;
    productName: number;
    internalSku: string;
    versionImage: string;
    versionId: number;
    bundlerId: number;
    qty: number;
    pricePerUnit: number;
    priceTotal: number;
    costPerItem: number;
    cortTotal: number;
    regularPricePerItem?: number;

    public static parse(data: any): SalesInvoiceItemModel {
        return new SalesInvoiceItemModel(
            data['id'],
            data['invoiceId'],
            data['productName'],
            data['internalSku'],
            data['versionImage'],
            data['versionId'],
            data['bundlerId'],
            data['qty'],
            data['pricePerUnit'],
            data['priceTotal'],
            data['costPerItem'],
            data['cortTotal'],
            data['regularPricePerItem']);
    }

    constructor(id: number,
        invoiceId: number,
        productName: number,
        internalSku: string,
        versionImage: string,
        versionId: number,
        bundlerId: number,
        qty: number,
        pricePerUnit: number,
        priceTotal: number,
        costPerItem: number,
        cortTotal: number,
        regularPricePerItem?: number) {
        super();
        this.id = id;
        this.invoiceId = invoiceId;
        this.productName = productName;
        this.internalSku = internalSku;
        this.versionImage = versionImage;
        this.versionId = versionId;
        this.bundlerId = bundlerId;
        this.qty = qty;
        this.pricePerUnit = pricePerUnit;
        this.priceTotal = priceTotal;
        this.costPerItem = costPerItem;
        this.cortTotal = cortTotal;
        this.regularPricePerItem = regularPricePerItem;
    }
}
export class SalesInvoiceListViewModel extends SelectableModel {
    accountName: string;
    accountNumber: string;
    accountCreditAvailable: number;
    status: string;
    id: number;
    invoiceGuid: string;
    items: number;
    cost: number;
    subTotal: number;
    tax: number;
    total: number;
    createdOn: Date;
    updateOn: Date;
    IsAttached: boolean;
    currencySymbol: string;
    currencyCode: string;
    referenceEmail?: string;
    referenceNumber?: number;

    public static parse(data: any): SalesInvoiceListViewModel {
        return new SalesInvoiceListViewModel(
            data['accountName'],
            data['accountNumber'],
            data['status'],
            data['id'],
            data['invoiceGuid'],
            data['items'],
            data['cost'],
            data['subTotal'],
            data['tax'],
            data['total'],
            data['createdOn'],
            data['updateOn'],
            data['currencySymbol'],
            data['currencyCode'],
            data['accountCreditAvailable'],
            data['referenceEmail']);
    }


    constructor(accountName: string,
        accountNumber: string,
        status: string,
        id: number,
        invoiceGuid: string,
        items: number,
        cost: number,
        subTotal: number,
        tax: number,
        total: number,
        createdOn: Date,
        updateOn: Date,
        currencySymbol: string,
        currencyCode: string,
        accountCreditAvailable: number,
        referenceEmail:string
    ) {
        super();
        this.accountName = accountName;
        this.accountNumber = accountNumber;
        this.status = status;
        this.id = id;
        this.invoiceGuid = invoiceGuid;
        this.items = items;
        this.cost = cost;
        this.subTotal = subTotal;
        this.tax = tax;
        this.total = total;
        this.createdOn = createdOn;
        this.updateOn = updateOn;
        this.currencySymbol = currencySymbol;
        this.currencyCode = currencyCode;
        this.accountCreditAvailable = accountCreditAvailable;

    }

    isValid(): any {
        return true;
    }
}
export class SalesInvoiceModel extends SelectableModel {
    status: string;
    invoiceId: number;
    invoiceGuid: string;
    cost: number;
    subTotal: number;
    taxPer: number;
    tax: number;
    total: number;
    createdOn: Date;
    updateOn: Date;
    userId: string;
    discount: number;
    promoCode: string;
    mopName: string;
    mopId: number;
    mopTransactionId: number;
    shipingInfoId: number;
    priceList: number;
    taxId: number;
    referenceNumber: string;
    referenceEmail: string;
    clientEmail: string;
    clientId: number;
    isAttach: boolean;
    items: Array<SalesInvoiceItemModel>;
    currency: CurrencyModel;
    account: AccountModel;
    shippingTotal: number;

    public static parse(data: any): SalesInvoiceModel {
        const items: Array<SalesInvoiceItemModel> = [];
        data['items'].forEach(x => items.push(SalesInvoiceItemModel.parse(x)));
        return new SalesInvoiceModel(
            data['status'],
            data['invoiceId'],
            data['invoiceGuid'],
            data['cost'],
            data['subTotal'],
            data['taxPer'],
            data['tax'],
            data['total'],
            data['createdOn'],
            data['updateOn'],
            data['userId'],
            data['discount'],
            data['promoCode'],
            data['mopName'],
            data['mopId'],
            data['mopTransactionId'],
            data['shipingInfoId'],
            data['priceList'],
            data['taxId'],
            data['referenceNumber'],
            data['referenceEmail'],
            data['clientEmail'],
            data['clientId'],
            data['isAttach'],
            items,
            CurrencyModel.parse(data['currency']),
            data['account'],
            data['shippingTotal']);
    }

    // Array<SalesInvoiceItemModel>,
    constructor(status: string,
        invoiceId: number,
        invoiceGuid: string,
        cost: number,
        subTotal: number,
        taxPer: number,
        tax: number,
        total: number,
        createdOn: Date,
        updateOn: Date,
        userId: string,
        discount: number,
        promoCode: string,
        mopName: string,
        mopId: number,
        mopTransactionId: number,
        shipingInfoId: number,
        priceList: number,
        taxId: number,
        referenceNumber: string,
        referenceEmail: string,
        clientEmail: string,
        clientId: number,
        isAttach: boolean,
        items: Array<SalesInvoiceItemModel>,
        currency: CurrencyModel,
        account: AccountModel,
        shippingTotal: number) {
        super();
        this.userId = userId;
        this.discount = discount;
        this.status = status;
        this.invoiceId = invoiceId;
        this.invoiceGuid = invoiceGuid;
        this.items = items;
        this.cost = cost;
        this.subTotal = subTotal;
        this.taxPer = taxPer;
        this.tax = tax;
        this.total = total;
        this.createdOn = createdOn;
        this.updateOn = updateOn;
        this.isAttach = isAttach;
        this.currency = currency;
        this.account = account;
        this.clientId = clientId;
        this.clientEmail = clientEmail;
        this.referenceEmail = referenceEmail;
        this.referenceNumber = referenceNumber;
        this.taxId = taxId;
        this.priceList = priceList;
        this.shipingInfoId = shipingInfoId;
        this.mopTransactionId = mopTransactionId;
        this.mopId = mopId;

        this.mopName = mopName;
        this.promoCode = promoCode;
        this.shippingTotal = shippingTotal;
    }

    isValid(): any {
        return true;
    }
}

