export class ChannelModel {
  id: number;
  provider: string;
  engine: string;
  name: string;
  status: string;
  type: string;
  products: number;
  isActive: boolean;
  stockEmail: string;

  constructor(id: number,
    provider: string,
    engine: string,
    name: string,
    status: string,
    type: string,
    products: number,
    isActive: boolean,
    stockEmail: string) {
    this.id = id;
    this.provider = provider;
    this.engine = engine;
    this.name = name;
    this.status = status;
    this.type = type;
    this.products = products;
    this.isActive = isActive;
    this.stockEmail = stockEmail;
  }
}

export enum Status {
  OFFLINE = 'Offline',
  ERROR = 'Error',
  INTERMITTENT = 'Intermittent',
  CREATE = 'Create',
  ONLINE = 'Online'
}
