import { ListItemModel } from '../list-item.model';

export class UserViewModel {
  Username: string;
  Email: string;
  PhoneNumber: string;
    TwoFactorEnabled: boolean;

  id: string;
  languageOption: string;
  tier: number;
  FirstName: string;
  LastName: string;

  Regions: Array<ListItemModel>;
  Accounts: Array<ListItemModel>;
  Roles: Array<string>;
  isActive: boolean;
  isEnable: boolean;

  languageId: number;
  accountId: number;

  private IsSelected: boolean;

  constructor(Username: string,
    Email: string,
    PhoneNumber: string,
      TwoFactorEnabled: boolean,

    Regions: Array<ListItemModel>,
    Accounts: Array<ListItemModel>,
    Roles: Array<string>,
    id: string,
    languageOption: string,
    tier: number,
    FirstName: string,
    LastName: string,
    isActive: boolean,
    isEnable: boolean,
    languageId: number,
    accountId: number) {
    this.Username = Username;
    this.Email = Email;
    this.PhoneNumber = PhoneNumber;
    this.TwoFactorEnabled = TwoFactorEnabled;
      this.Regions = [];
      if (Regions) {
        Regions.forEach(x => this.Regions.push(ListItemModel.Parse(x)));
      }
      if (Accounts) {
          this.Accounts = [];
          Accounts.forEach(x => this.Accounts.push(ListItemModel.Parse(x)));
      }

    this.Roles = Roles;
    this.id = id;
    this.languageOption = languageOption;
    this.tier = tier;
    this.FirstName = FirstName;
    this.LastName = LastName;
    this.isActive = isActive;
    this.isEnable = isEnable;
    this.languageId = languageId;
    this.accountId = accountId;
  }

  static Parse(x: any): UserViewModel {
    return new UserViewModel(
      x['username'],
      x['email'],
      x['phoneNumber'],
      x['twoFactorEnabled'],
      x['regions'],
      x['accounts'],
      x['roles'],
      x['id'],
      x['languageOption'],
      x['tier'],
      x['firstName'],
      x['lastName'],
      x['isActive'],
      x['isEnable'],
      x['languageId'],
      x['accountId']);
  }


  getSelected(): boolean {
    return this.IsSelected;
  }
  toggleSelected() {
    this.IsSelected = !this.IsSelected;
  }
}
