import { SelectableModel } from '../general/selectable.model';
import { UserDetailModel } from '../user/user-detail.model';
import { AccountModel } from '../account.model';

export class AccountRegistratinModel extends SelectableModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  languageId: number;
  currencyId: number;
  companyName: string;
  companyPhone: string;
  companyEmail: string;
  webSite: string;
  estimateYearlySales: number;
  regionId: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  whiteLabelSite: boolean;
  storeSite: boolean;
  apiAccess: boolean;
  notes: string;
  status: string;
  createdOn: Date;

  constructor(id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    languageId: number,
    currencyId: number,
    companyName: string,
    companyPhone: string,
    companyEmail: string,
    webSite: string,
    estimateYearlySales: number,
    regionId: number,
    address1: string,
    address2: string,
    city: string,
    state: string,
    country: string,
    whiteLabelSite: boolean,
    storeSite: boolean,
    apiAccess: boolean,
    notes: string,
    status: string,
    createdOn: Date) {
    super();
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.languageId = languageId;
    this.currencyId = currencyId;
    this.companyName = companyName;
    this.companyPhone = companyPhone;
    this.companyEmail = companyEmail;
    this.webSite = webSite;
    this.estimateYearlySales = estimateYearlySales;
    this.regionId = regionId;
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.country = country;
    this.whiteLabelSite = whiteLabelSite;
    this.notes = notes;
    this.storeSite = storeSite;
    this.apiAccess = apiAccess;
    this.status = status;
    this.createdOn = createdOn;
  }

  public getAccount(): AccountModel {
    return new AccountModel(null, this.companyName, '', '', '',
        true, false, true, false, true, true, true, false, 0, false, false,
        '15', true, true, this.notes, null, null, null, null, null, null, null, this.languageId, null, null, this.regionId, this.address1, this.address2, this.city, this.state, '', 0);
  }
    public getUser(): UserDetailModel {
        let username = (this.firstName + '-' + this.lastName).replace(/\s/g, "");
        return new UserDetailModel(username, this.firstName, this.lastName,
      this.email, this.phoneNumber, false, false, false, 3, null, null, null, null, null, null,false);
  }
  

  public static createNew(): AccountRegistratinModel {
    return new AccountRegistratinModel('', '', '', '', '', 1, 1, '', '', '', '',
      0, 1, '', '', '', '', '', false, false, false, '', '', null);
  }

  public static parse(data: any): AccountRegistratinModel {
    return new AccountRegistratinModel(
      data['id'],
      data['firstName'],
      data['lastName'],
      data['email'],
      data['phoneNumber'],
      data['languageId'],
      data['currencyId'],
      data['companyName'],
      data['companyPhone'],
      data['companyEmail'],
      data['webSite'],
      data['estimateYearlySales'],
      data['regionId'],
      data['address1'],
      data['address2'],
      data['city'],
      data['state'],
      data['country'],
      data['whiteLabelSite'],
      data['storeSite'],
      data['apiAccess'],
      data['notes'],
      data['status'],
      data['createdOn']);
  }
}
