import { animate, style, trigger, transition, state } from '@angular/animations';
import { Component, Input, Output, EventEmitter, ElementRef, HostListener, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    animations: [
        trigger('expandCollapse', [
            state('expand', style({ height: '*', overflow: 'hidden', display: 'block' })),
            state('collapse', style({ height: '0px', overflow: 'hidden', display: 'block' })),
            state('active', style({ height: '*', overflow: 'hidden', display: 'block' })),
            transition('expand <=> collapse', animate(100)),
            transition('active => collapse', animate(100))
        ])
    ]
})

export class SidebarComponent implements OnInit {

    navProfileState = 'collapse';
    @Output() toggleSidebarMinified = new EventEmitter<boolean>();
    @Output() hideMobileSidebar = new EventEmitter<boolean>();
    @Input() pageSidebarTransparent;
    mobileMode: any;
    desktopMode: any;

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.hideMobileSidebar.emit(true);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(_) {
        this.setSideBarMode();
    }

    constructor(private eRef: ElementRef, public auth: AuthService, private router: Router) {
        this.setSideBarMode();
    }

    setSideBarMode() {
        if (window.innerWidth <= 767) {
            this.mobileMode = true;
            this.desktopMode = false;
        } else {
            this.mobileMode = false;
            this.desktopMode = true;
        }
    }

    ngOnInit(): void {
        this.auth.getMenu();
    }

  

    get username(): string {
        return this.auth.getUserName();
    }

    logout() {
        this.auth.logout();
        this.router.navigate(['/login']);
    }

    toggleNavProfile() {
        if (this.navProfileState === 'collapse') {
            this.navProfileState = 'expand';
        } else {
            this.navProfileState = 'collapse';
        }
    }

    toggleMinified() {
        this.toggleSidebarMinified.emit(true);
    }

    expandCollapseSubmenu(currentMenu, allMenu, active) {
        for (const menu of allMenu) {
            if (menu !== currentMenu) {
                menu.state = 'collapse';
            }
        }
        if (currentMenu.state === 'expand' || (active.isActive && !currentMenu.state)) {
            currentMenu.state = 'collapse';
        } else {
            currentMenu.state = 'expand';
        }
    }

}
