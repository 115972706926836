import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';
import { AccountService, AuthService, CommonService, InternationalizationService, UserService } from '../services';
import { BrandingService } from '../services/branding.service';
import { AccountRegionModel } from '../models/account.model';
import { API_CONFIG, build_Configurations, build_info, environment } from '../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() pageSidebarTwo;
  @Output() toggleSidebarRightCollapsed = new EventEmitter<boolean>();
  @Output() toggleMobileSidebar = new EventEmitter<boolean>();
  @Output() toggleMobileRightSidebar = new EventEmitter<boolean>();

  regions: Array<AccountRegionModel> = [];
  selectedRegion: AccountRegionModel;
  clrPosition: string = 'right';
  clrSize: string = 'right';
  openModal = false;

  languages: Array<{ id: number, name: string }> = [{ id: 1, name: 'EN' }, { id: 2, name: 'FR' }];
  languageId;
  logoUrl: string;

  private subscibe$: Subscription = new Subscription();

  supportModal: boolean;
  environment_values: any = {};
  API_CONFIG_values: any = {};
  build_Configurations_values: any = {};
  build_info_values: any = {};
  tenantName: string;

  constructor(private notifySvc: NotificationService, private router: Router,
    private i18Svc: InternationalizationService, public branding: BrandingService, public auth: AuthService,
    private accountSrv: AccountService, private common: CommonService,
    public userSrv: UserService) { }


  get username(): string {
    return this.auth.getUserName();
  }

  getRegions() {
    this.common.onLoadingAllRegionChange(true);
    this.accountSrv.getAllRegions()
      .subscribe(data => this.onRegionGet(data), err => this.onRegioGetError(err));
  }

  onRegionGet(data) {
    this.common.onLoadingAllRegionChange(false);
    if (data.result) {
      this.regions = [];
      data.result.forEach(x => this.regions.push(AccountRegionModel.parse(x)));
      this.common.onRegionAllChange(this.regions || []);
      if(this.regions?.length > 0) {
        if (this.regions?.length === 1) {
          this.setInitialRegion();
        } else if (this.selectedRegion?.id) {
          this.selectedRegion = this.regions.find(x => x.id === this.selectedRegion.id);
          if(!this.selectedRegion) {
            this.setInitialRegion();
          }
        } else {
          this.changeRegion();
        }
      } else {
        this.setEmptyRegion();
        this.changeRegionAction();
      }
    } else {
      this.setEmptyRegion();
      this.changeRegionAction();
      this.onRegioGetError(data.errorMessage);
    }
  }

  setInitialRegion() {
    this.selectedRegion = this.regions[0];
    this.changeRegionAction();
  }
  
  setEmptyRegion() {
    this.selectedRegion = AccountRegionModel.create();
    this.selectedRegion.id = undefined;
    this.selectedRegion.name = 'Select a region';
  }

  onRegioGetError(err) {
    this.common.onLoadingAllRegionChange(false);
    console.log(err);
  }

  changeRegion() {
    this.openModal = true;
  }
  changeRegionAction() {
    this.openModal = false;
    localStorage.setItem('current_region', this.selectedRegion?.id?.toString() || '');
    this.common.onRegionChange(this.selectedRegion?.id);
  }

  ngOnInit() {

    this.subscibe$ = this.auth.languageChanges$.subscribe(language => {
      const languageId = this.languages.find(x => x.name?.toString()?.toLowerCase() === language?.toString()?.toLowerCase())?.id;
      if(languageId !== this.languageId) {
        this.setLanguage(languageId);
      }
    });

    this.logoUrl = build_Configurations.logoUrl;
    let regionId = Number.parseInt((localStorage.getItem('current_region') ? localStorage.getItem('current_region') : this.auth.getRegion()) ?? undefined);
    this.selectedRegion = AccountRegionModel.create();
    this.selectedRegion.id = regionId;
    this.selectedRegion.name = 'Select a region';
    this.getRegions();
    this.branding.getBranding("");
    this.initLanguage();
    this.auth.setTier0Admin();

    this.environment_values = environment || {};
    this.API_CONFIG_values = API_CONFIG || {};
    this.build_Configurations_values = build_Configurations || {};
    this.build_info_values = build_info || {};

    this.tenantName = (this.common.tenantList || []).find(x => x.guid === this.common.tenantId)?.name || '';
  }

  initLanguage() {
    const langCode = localStorage.getItem('current_lang');
    let defaultLanguage;
    if (langCode) {
      defaultLanguage = this.languages.find(x => x.name === langCode.toUpperCase())?.id;
    } else {
      defaultLanguage = this.languages.find(x => x.id === this.common.languageId)?.id;
    }
    this.setLanguage(defaultLanguage);
  }

  logout() {
    this.router.navigate(['/logout'], { queryParams: { returnUrl: this.router.url } });
  }

  editProfile() {
    window.location.href = `https://${API_CONFIG.AzureB2C.AuthorityDomain}/${API_CONFIG.AzureB2C.ServiceTenant}/oauth2/v2.0/authorize?p=${API_CONFIG.AzureB2C.EditProfile}&client_id=${API_CONFIG.AzureB2C.ClientId}&nonce=defaultNonce&redirect_uri=${window.location.origin}&scope=openid&response_type=id_token&prompt=login`
  }

  setToSoftRead(): void {
    this.notifySvc.setToSoftRead({}).subscribe(() => console.log('done'), err => this.errorHandler(err));
  }

  onNavigate(redirectUrl: string, index: number): void {
    this.router.navigate([redirectUrl]);
  }

  toSearch() {
    this.router.navigate(['search/search']);
  }

  mobileSidebarToggle() {
    this.toggleMobileSidebar.emit(true);
  }
  mobileRightSidebarToggle() {
    this.toggleMobileRightSidebar.emit(true);
  }
  toggleSidebarRight() {
    this.toggleSidebarRightCollapsed.emit(true);
  }
  setLocalLanguage(languageId: number): void {
    if(languageId) {
    const selectedLanguage = this.languages.find(x => Number(x.id) === Number(languageId));
    this.languageId = selectedLanguage?.id || this.common.languageId;
    localStorage.setItem('current_lang', selectedLanguage?.name.toLowerCase());
    this.i18Svc.initialize();
    }
  }

  setLanguage(languageId: number): void {
    this.setLocalLanguage(languageId);
    const language = this.languages.find(x => Number(x.id) === Number(languageId))?.name;

    this.userSrv.changeLanguage(language?.toString()?.toLowerCase())
      .subscribe(x => this.onChangeLanguage(x));

  }

  onChangeLanguage(data) {
    if (data.statusCode === 200 && data.result) {
      const languageId = this.languages.find(x => data.result?.value?.toString()?.toLowerCase() === x.name?.toString()?.toLowerCase())?.id;
      this.setLocalLanguage(languageId);
    } else {
      console.log('Error changing language' + data.message);
    }
  }

  onChangeTenant(tenantId: string) {
    this.common.setTenant(tenantId);
    this.setEmptyRegion();
    localStorage.setItem('current_region', this.selectedRegion?.id?.toString() || '');
    this.getRegions();
    this.common.selectedRegionsChange([]);
    this.reloadCurrentRoute();
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    const routeUrl = currentUrl === '/' || currentUrl === '/dashboard' ? '/error' : '/';
    this.router.navigateByUrl(routeUrl, { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  // General Functions
  errorHandler(error) {
    console.log(error);
  }

  openSupportModal() {
    this.supportModal = true;
  }
  
  closeSupportModal() {
    this.supportModal = false;
  }

  ngOnDestroy(): void {
    this.subscibe$?.unsubscribe();
  }

}
