import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreService } from './core.service';


@Injectable()
export class AuthService {

    public menus = [];
    isTier0Admin: boolean;
    public languageChanges$ = new Subject<string>();

    get canViewSettings(): boolean {
        return (this.isDBAdminUser ||
            this.haveRole('Tax-Admin') || this.haveRole('Tax-View')
            || this.haveRole('Language-Admin') || this.haveRole('Language-View')
            || this.haveRole('Region-Admin') || this.haveRole('Region-View')
            || this.haveRole('Currency-Admin') || this.haveRole('Currency-View'));
    }
    get canViewCurrency(): boolean {
        return (this.canEditCurrency || this.haveRole('Currency-View'));
    }
    get canEditCurrency(): boolean {
        return (this.isDBAdminUser || this.haveRole('Currency-Admin'));
    }
    get canViewLanguage(): boolean {
        return (this.canEditLanguage || this.haveRole('Language-View'));
    }
    get canEditLanguage(): boolean {
        return (this.isDBAdminUser || this.haveRole('Language-Admin'));
    }
    get canViewRegion(): boolean {
        return (this.canEditRegion || this.haveRole('Region-View'));
    }
    get canEditRegion(): boolean {
        return (this.isDBAdminUser || this.haveRole('Region-Admin'));
    }
    get canViewTax(): boolean {
        return (this.canEditTax || this.haveRole('Tax-View'));
    }
    get canEditTax(): boolean {
        return (this.isDBAdminUser || this.haveRole('Tax-Admin'));
    }

    get canViewReports(): boolean {
        return (this.isDBAdminUser || this.haveRole('Report-View'));
    }
    get canViewMarketing(): boolean {
        return (this.isDBAdminUser || this.haveRole('Marketing-Admin') || this.haveRole('Marketing-View'));
    }

    get canViewUsers(): boolean {
        return (this.canEditUsers|| this.haveRole('User-View'));
    }
    get canEditUsers(): boolean {
        return (this.isDBAdminUser || this.haveRole('User-Admin'));
    }
    
    get canViewProducts(): boolean {
        return (this.isDBAdminUser || this.haveRole('Product-Admin') || this.haveRole('Product-View'));
    }
    get canViewAccounts(): boolean {
        return (this.canEditAccounts || this.haveRole('Account-View'));
    }
    get canEditAccounts(): boolean {
        return (this.isDBAdminUser || this.haveRole('Account-Admin'));
    }

    get canViewCredit(): boolean {
        return (this.canEditCredit || this.haveRole('Credit-View'));
    }
    get canEditCredit(): boolean {
        return (this.isDBAdminUser || this.haveRole('Credit-Admin'));
    }
    
    get canViewDashboard(): boolean {
        return (this.canViewAccounts);
    }
    get isDBAdminUser(): boolean {
        return this.haveRole('DB-Admin');
    }

    

    private haveRole(roleName: string): boolean {
        if (this.userRoles && this.userRoles.length > 0) {
            return this.userRoles.indexOf(roleName) > -1;
        } else {
            return false;
        }
        
    }
    get userRoles() {
        try {
            return localStorage.getItem('roles');
        }
        catch {
            return [];
        }
        
    }
  constructor(private svc: CoreService) { }

  getUserName(): string {
    if (localStorage.getItem('currentUser') && JSON.parse(localStorage.getItem('currentUser')).hasOwnProperty('fullName')) {
      return JSON.parse(localStorage.getItem('currentUser'))?.fullName;
    }
    return null;
  }

    getRegion(): string {
        if (localStorage.getItem('currentUser') && JSON.parse(localStorage.getItem('currentUser')).hasOwnProperty('fullName')) {
            return JSON.parse(localStorage.getItem('currentUser')).regionId;
        }
        return null;
    }
  getTier(): number {
    if (localStorage.getItem('currentUser') && JSON.parse(localStorage.getItem('currentUser')).hasOwnProperty('tier')) {
      return JSON.parse(localStorage.getItem('currentUser'))?.tier;
    }
    return null;
  }

  GetCurrentUser() {
    localStorage.removeItem('current_lang');
    return this.svc.get(`${this.svc.identityUrl}/auth/me`)
        .pipe(map(user => {
            if (user?.result) {
                const result = user['result'];
                localStorage.setItem('currentUser', JSON.stringify(result));
                const language = result.languageId === 1 ? 'en': 'fr';
                localStorage.setItem('current_lang', language);
                this.setTier0Admin();
                this.languageChanges$.next(language)
                return user;
            }
            throw new Error('Incorrect username or password');
        }));
}


setTier0Admin() {
    this.isTier0Admin = this.getTier() === 0;
  }
 

  login(username: string, password: string) {
    return this.svc.post(`${this.svc.baseUrl}/auth/login`, { username: username, password: password })
      .pipe(map(user => {
        if (user?.result?.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user['result']));
          localStorage.setItem('jwt', user['result'].token);
          this.getMenu();
        }
        return user;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('jwt');
    localStorage.removeItem('roles');
      this.menus = [];
  }

  getRoles() {
    this.svc.get(`${this.svc.identityUrl}/Role/getMyRoles`).subscribe(data => {
      if (data?.result) {
          localStorage.setItem('roles', JSON.stringify(data['result']));
          this.getMenu();
      }
    });
    }

    public getMenu() {
        this.menus = [];
        if (this.canViewDashboard) {
            this.menus.push({
                'icon': 'fa fa-th-large',
                'title': 'nav.menu.region_dashboard_text',
                'url': 'dashboard'
            });
        }
        if (this.canViewAccounts) {
            this.menus.push({
                'icon': 'fas fa-building',
                'title': 'nav.menu.accounts_text',
                'url': 'accountManagement'
            });
        }
        if (this.canViewProducts) {
            this.menus.push({
                'icon': 'fa fa-barcode',
                'title': 'nav.menu.products_text',
                'url': 'productManagement'
            });
        }
        if (this.canViewUsers) {
            this.menus.push({
                'icon': 'fa fa-user',
                'title': 'nav.menu.users_text',
                'url': 'user'
            });
        }
        if (this.canViewMarketing) {
            this.menus.push({
                'icon': 'fas fa-newspaper',
                'title': 'nav.menu.marketing_text',
                'url': 'marketing',
            });
        }
        if (this.canViewReports) {
            this.menus.push({
                'icon': 'fas fa-chart-pie',
                'title': 'nav.menu.reports_text',
                'url': 'report',
            });
        }
        if (this.canViewSettings) {
            this.menus.push({
                'icon': 'fa fa-cog',
                'title': 'nav.menu.settings_text',
                'url': 'settings',
            });
        }
    }

}
