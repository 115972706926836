import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/core/ResponseModel.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private srv: CoreService) { }

  public getUnreadNotifications(): Observable<ResponseModel<any>> {
    return this.srv.get(this.srv.baseUrl + '/notification/GetNotifications?unReadOnly=true');
  }

  public getAllNotifications(): Observable<ResponseModel<any>> {
    return this.srv.get(this.srv.baseUrl + '/notification/GetNotifications/');
  }

  public setToSoftRead(model: any): Observable<ResponseModel<any>> {
    return this.srv.post(this.srv.baseUrl + '/notification/SetToSoftRead/', model);
  }

  public setToReadById(model: any): Observable<ResponseModel<any>> {
    return this.srv.post(this.srv.baseUrl + '/notification/SetToRead/', model);
  }

}
