import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ListItemModel } from '../../../models/list-item.model';

@Component({
  selector: 'app-ddl-control',
  templateUrl: './ddl-control.component.html',
  styleUrls: ['./ddl-control.component.scss']
})

/** dropdown-user component*/
export class DdlControlComponent {
  propertyValue;
  isDisableValue: boolean;
  @Input() title: string;
  @Input() items: Array<ListItemModel>;
  @Input('')
  get isDisabled() {
    return this.isDisableValue;
  }
  set isDisabled(value: boolean) {
    this.isDisableValue = value;
  }

  @Input() get property() {
    return this.propertyValue;
  }

  @Output() propertyChange = new EventEmitter();

  set property(val) {
    this.propertyValue = val;
    this.propertyChange.emit(this.propertyValue);
  }

  change() {
    this.property = !this.propertyValue;
  }
}
