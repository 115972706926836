export class SelectableModel {
  public _Selected: boolean;
  private _OriginalSelection: boolean;
  public _Loading: boolean;

  constructor() {
    this._Selected = false;
    this._Loading = false;
  }

  isSelected() {
    return this._Selected;
  }
  setOriginalSelection(value: boolean) {
    this._OriginalSelection = value;
  }
  selectionChange() {
    return this._OriginalSelection !== this._Selected;
  }
  getOriginalSelection() {
    return this._OriginalSelection;
  }
  setSelected(value: boolean) {
    this._Selected = value;
  }
  selectedToggle() {
    this._Selected = !this._Selected;
  }
  setLoading(value: boolean) {
    this._Loading = value;
  }
  isLoading(): boolean {
    return this._Loading;
  }

}
