// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep mat-label {
  font-size: 16px !important;
}

::ng-deep .mat-input-element {
  font-size: 16px !important;
}

::ng-deep .mat-select {
  font-size: 16px !important;
}

.dbc-material-full-width {
  width: 100%;
}

::ng-deep .cdk-overlay-container {
  z-index: 1200 !important;
}

.datagrid-compact {
  height: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/modals/user-edit-modal/user-edit-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;AACJ;;AAEE;EACE,0BAAA;AACJ;;AACE;EACE,0BAAA;AAEJ;;AACE;EACI,WAAA;AAEN;;AACE;EACE,wBAAA;AAEJ;;AAAE;EACE,uBAAA;AAGJ","sourcesContent":["::ng-deep mat-label {\n    font-size: 16px !important;\n  }\n  \n  ::ng-deep .mat-input-element {\n    font-size: 16px !important;\n  }\n  ::ng-deep .mat-select {\n    font-size: 16px !important;\n  }\n\n  .dbc-material-full-width {\n      width: 100%;\n  }\n\n  ::ng-deep .cdk-overlay-container {\n    z-index: 1200 !important;\n  }\n  .datagrid-compact{\n    height: auto !important\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
