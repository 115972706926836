// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group-text {
  width: 120px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/controls/ddl-user-control/ddl-user-control.component.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,gBAAA;AAAF","sourcesContent":["\n.input-group-text {\n  width: 120px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
