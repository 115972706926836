import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportModel } from '../../../../models/report/report.model';
import { InternationalizationService, ReportService, ExcelService, AccountService, CommonService } from '../../../../services';
import { AccountRegionModel } from '../../../../models/account.model';
import { ToastrService } from 'ngx-toastr';
import { ListItemModel } from '../../../../models/list-item.model';
import { DatePipe } from '@angular/common';
import { Subscription, catchError, forkJoin, of } from 'rxjs';

@Component({
    selector: 'app-report-table',
    templateUrl: './report-table.component.html',
    styleUrls: ['./report-table.component.scss']
})
export class ReportTableComponent implements OnInit, OnDestroy {
    reportsLoading = false;
    reports: ReportModel[] = [];
    reportsDataFiltered: ReportModel[] = [];
    startDateSelected: Date;
    endDateSelected: Date;

    regions: Array<AccountRegionModel> = [];
    allAccounts: Array<ListItemModel> = [];
    accounts: Array<ListItemModel> = [];

    regionSelected: AccountRegionModel[] = [];
    accountSelected: number;
    typeSelected = 'All';

    dropdownSettings = {};
    private subscripe$: Subscription = new Subscription();

     
    constructor(
        private reportSvc: ReportService,
        private i18Svc: InternationalizationService,
        private excelSvc: ExcelService,
        private accountSrv: AccountService,
        private toastr: ToastrService,
        public common: CommonService
        ) {
        this.i18Svc.initialize();
    }

    ngOnInit() {
        this.getRegions();
        this.dropdownSettings = {
            singleSelection: false,
          idField: 'id',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
        };
    }
      

    getRegions() {
        this.accountSrv.getAllRegions()
            .subscribe(data => this.onRegionGet(data), err => this.onGetError(err));
    }

    getAccounts() {
            this.allAccounts = [];
            this.accounts = [];
            this.accountSelected = 0;
            const obj = {};
            (this.regionSelected || []).forEach(x => {
                obj[`${x.id}`] = this.accountSrv.getAccountsAsListItemForRegions([x.id]).pipe(catchError(error => of(error)));
            });
            forkJoin(obj).subscribe(data => this.onAccountGet(data), err => this.onGetError(err));
    }

    onRegionGet(data) {
        if (data.result) {
            this.regions = [];
            data.result.forEach(x => this.regions.push(AccountRegionModel.parse(x)));
            this.regionSelected = JSON.parse(JSON.stringify(this.regions));
            this.getAccounts();
        } else {
            this.onGetError(data.errorMessage);
        }
    }

    onAccountGet(dataList) {
        if (dataList) {
            for (let d in dataList) {
                if (d) {
                    const data = dataList[d] || {};
                    if (data.result) {
                        data.result.forEach(x => {
                            const account = ListItemModel.Parse(x);
                            account['regionId'] = Number(d || '') || 0;
                            this.allAccounts.push(account);
                            this.accounts.push(account);
                        });
                    } else {
                        this.onGetError(data.errorMessage);
                    }
                }
            }
        }
    }

    onGetError(data) {
        this.toastr.error('Error getting data');
    }

    getReports(): void {
        this.reports = [];
        this.reportsDataFiltered = [];
        this.reportsLoading = true;

        if (!this.typeSelected) {
            this.typeSelected = 'All';
        }
        const apiList = {};
        const isAllAccountSelected = !this.accountSelected || this.accountSelected?.toString() === '0';
        const isAllRegionSelected = this.regionSelected?.length === 0 || this.regionSelected?.length === this.regions?.length;
        if(isAllAccountSelected && isAllRegionSelected) {
            apiList['region'] = this.reportsApi(0, 0);
        } else if(isAllAccountSelected) {
            (this.regionSelected || []).forEach(x => {
                apiList[x.id] = this.reportsApi(0, x.id);
            });
        } else {
            const selectedAccount = (this.allAccounts || []).find(x => x.id?.toString() === this.accountSelected?.toString());
            if(selectedAccount) {
                apiList[selectedAccount['regionId']] = this.reportsApi(selectedAccount?.id, selectedAccount['regionId']);
            }
        }
        

        forkJoin(apiList).subscribe(data => this.onGetReports(data), err => this.errorHandler(err));

    }

    reportsApi(accountId: number, regionId: number) {
        if(this.typeSelected.toString() === 'ESD') {
            return this.reportSvc.getReportsEsd(accountId, regionId, this.startDateSelected, this.endDateSelected).pipe(catchError(error => of(error)));
        } else if(this.typeSelected.toString() === 'OTK') {
            return this.reportSvc.getReportsOtk(accountId, regionId, this.startDateSelected, this.endDateSelected).pipe(catchError(error => of(error)));
        } else {
            return this.reportSvc.getReportsAll(accountId, regionId, this.startDateSelected, this.endDateSelected).pipe(catchError(error => of(error)));
        }
    }

    onGetReports(dataList): void {
        for(let d in dataList) {
            const data = dataList[d] || {};
            const results = data['result'] || [];
            this.reports.push(...results);
            this.reportsDataFiltered.push(...results);
        }
        this.reportsDataFiltered[1].purchaseCurrency.name = '';
        this.reportsDataFiltered[1].purchaseCurrency.code = '';
        this.reportsDataFiltered[1].soldCurrency.name = '';
        this.reportsDataFiltered[1].soldCurrency.code = '';
        this.reportsLoading = false;
    }

    reportFilter(filterValue: string) {
        filterValue = filterValue?.toUpperCase();
        this.reportsDataFiltered = this.reports.filter(x =>
            x.id?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.status?.toUpperCase().indexOf(filterValue) > -1
            || x.accountName?.toUpperCase().indexOf(filterValue) > -1
            || x.accountEmail?.toUpperCase().indexOf(filterValue) > -1
            || x.refEmail?.toUpperCase().indexOf(filterValue) > -1
            || x.purchaseSku?.toUpperCase().indexOf(filterValue) > -1
            || x.internalSku?.toUpperCase().indexOf(filterValue) > -1
            || x.qty?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.regionName?.toUpperCase().indexOf(filterValue) > -1
            || x.soldCurrency?.name?.toUpperCase().indexOf(filterValue) > -1
            || x.purchaseCurrency?.name?.toUpperCase().indexOf(filterValue) > -1
            || x.invoiceQty?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.placedByName?.toUpperCase().indexOf(filterValue) > -1);
    }
    refreshReport(): void {
        this.getReports();
    }

    errorHandler(error) {
        console.log(error);
    }

    reportExport(): void {
        if (this.reportsDataFiltered.length > 0) {
            let data = [];
            let arrTitles = 
            [
                this.i18Svc.getTranslateVal('internal_id_text'),
                this.i18Svc.getTranslateVal('status_text'),
                this.i18Svc.getTranslateVal('acc_name_text'),
                this.i18Svc.getTranslateVal('acc_email_text'),
                this.i18Svc.getTranslateVal('ref_email_text'),
                this.i18Svc.getTranslateVal('purchase_SKU_text'),
                this.i18Svc.getTranslateVal('internal_SKU_text'),
                this.i18Svc.getTranslateVal('qty_text'),
                this.i18Svc.getTranslateVal('report.invoice_qty_text'),
                this.i18Svc.getTranslateVal('report_purchaseCurrency_text'),
                this.i18Svc.getTranslateVal('report_cpu_text'),
                this.i18Svc.getTranslateVal('report_clt_text'),
                this.i18Svc.getTranslateVal('report_soldCurrency_text'),
                this.i18Svc.getTranslateVal('report_ppu_text'),
                this.i18Svc.getTranslateVal('report_plt_text'),
                this.i18Svc.getTranslateVal('report_tlc_text'),
                this.i18Svc.getTranslateVal('report_tt_text'),
                this.i18Svc.getTranslateVal('report_cst_text'),
                this.i18Svc.getTranslateVal('discount_text'),
                this.i18Svc.getTranslateVal('total_text'),
                this.i18Svc.getTranslateVal('report_createdOn_text'),
                this.i18Svc.getTranslateVal('report_createdOn_text_time'),
                this.i18Svc.getTranslateVal('report_updatedOn_text'),
                this.i18Svc.getTranslateVal('report_updatedOn_text_time'),
                this.i18Svc.getTranslateVal('report.placedBy_name_text'),
                this.i18Svc.getTranslateVal('report_regionName_text'),
                this.i18Svc.getTranslateVal('report_transactionId_text')
            ];
            this.reportsDataFiltered.forEach(x =>{
                const obj={
                    id: x.id.toString(),
                    status:this.i18Svc.getTranslateVal(x.status),
                    accountName:x.accountName,
                    accountEmail:x.accountEmail,
                    refEmail:x.refEmail,
                    purchaseSku:x.purchaseSku,
                    internalSku:x.internalSku,
                    qty:x.qty,
                    invoiceQty: x.invoiceQty,
                    purchaseCurrency:x.purchaseCurrency.name,
                    costPerUnit:x.costPerUnit,
                    costLineTotal:x.costLineTotal,
                    soldCurrency:x.soldCurrency.name,
                    pricePerUnit:x.pricePerUnit,
                    priceLineTotal:x.priceLineTotal,
                    taxLineCalculated:x.taxLineCalculated,
                    taxTotal:x.taxTotal,
                    costSubTotal:x.costSubTotal,
                    discount:x.discount,
                    total:x.total,
                    createdOn: new DatePipe("en-US").transform( x.createdOn, 'yyyy-MM-dd'),
                    createdTimeOn: new DatePipe("en-US").transform( x.createdOn, 'HH:mm:ss'),
                    updatedOn:this.isDateValid(x.updatedOn.toString())? new DatePipe("en-US").transform(x.updatedOn.toString(), 'yyyy-MM-dd'):null,
                    updatedTimeOn:new DatePipe("en-US").transform(x.updatedOn, 'HH:mm:ss'),
                    placedByName: x.placedByName,
                    regionName:x.regionName,
                    transactionId: x.transactionId
                }
                data.push(obj)
            });
            this.excelSvc.exportDocument('xls', data, arrTitles,'ESD_Report');
        }
    }

    get localeData() {
        if(localStorage.getItem('current_lang')?.toLowerCase() === 'fr') {
            return { locale: 'fr-FR', languageCode: 'fr' };
        }
        return { locale: 'en-US', languageCode: 'en' };
    }

    getDate(date: Date): string {
        return new DatePipe(this.localeData.locale).transform(date, 'mediumDate', undefined, this.localeData.locale);
    }

    getTime(date: Date): string {
        return new DatePipe(this.localeData.locale).transform(date, 'shortTime');
    }

    isDateValid(dateString: string): boolean {
        const date = new Date(dateString);
      
        // Check if the date is a valid instance of Date
        if (isNaN(date.getTime())) {
          return false;
        }
      
        // Check if the date string matches the parsed date
        // This additional check is useful to handle cases where the input date string is not in a recognized format
        const parsedDate = date.toISOString().split('T')[0];
        if (dateString !== parsedDate) {
          return false;
        }
      
        // Optionally, you can add more specific checks here
        // For example, you can validate the year range, month range, or day range
      
        return true;
      }

      onItemSelect() {
          setTimeout(() => {
            this.filterAccounts();
          }, 1);
      }

      filterAccounts() {
          if(this.regionSelected?.length === 0) {
              this.accounts = JSON.parse(JSON.stringify(this.allAccounts || []));
          } else {
              this.accounts = [];
              (this.regionSelected || []).forEach(x => {
                const selectedAccountsForRegion = this.allAccounts.filter(a => a['regionId'] === x.id);
                this.accounts.push(...selectedAccountsForRegion);
              });
          }
          this.accountSelected = 0;
      }

    ngOnDestroy(): void {
        this.subscripe$?.unsubscribe();
    }

}
