import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';
import { ChannelModel } from '../models/product/channel.model';
import { ResponseModel } from '../models/core/ResponseModel.model';

@Injectable()
export class ChannelService {
  constructor(private srv: CoreService) {
  }

  public getProviders(): Observable<ResponseModel<any>> {
    return this.srv.get(this.srv.baseUrl + '/channel/getProviders/');
  }

  public getStatuses(): Observable<ResponseModel<any>> {
    return this.srv.get(this.srv.baseUrl + '/channel/getStatuses/');
  }

  public getTypes(): Observable<ResponseModel<any>> {
    return this.srv.get(this.srv.baseUrl + '/channel/getTypes/');
  }

  public getEngines(): Observable<ResponseModel<any>> {
    return this.srv.get(this.srv.baseUrl + '/channel/getEngines/');
  }

  public get(_id: number): Observable<ResponseModel<any>> {
    return this.srv.get(this.srv.baseUrl + '/channel/get/' + _id);
  }

  public getAll(): Observable<ResponseModel<any>> {
    return this.srv.get(this.srv.baseUrl + '/channel/getAll/');
  }

  public create(model: ChannelModel): Observable<ResponseModel<any>> {
    return this.srv.post(this.srv.baseUrl + '/channel/create', model);
  }

  public update(model: ChannelModel): Observable<ResponseModel<any>> {
    return this.srv.post(this.srv.baseUrl + '/channel/update', model);
  }

  public enableChannel(_id: number): Observable<ResponseModel<any>> {
    return this.srv.post(this.srv.baseUrl + '/channel/Enable?id=' + _id, {});
  }

  public disableChannel(_id: number): Observable<ResponseModel<any>> {
    return this.srv.post(this.srv.baseUrl + '/channel/Disable?id=' + _id, {});
  }

  public checkChannelStatus(_id: number): Observable<ResponseModel<any>> {
    return this.srv.post(this.srv.baseUrl + '/channel/CheckStatus?i?id=' + _id, {});
  }

}
