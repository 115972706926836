import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent {

  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;

  @Output() modalClose = new EventEmitter();

  save(): void {
    this.closeModal(true);
  }

  close(): void {
    this.closeModal(false);
  }

  closeModal(refresh?: boolean): void {
    this.open = false;
    this.modalClose.emit(refresh);
  }

}
