import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';

import { AuthService } from '../../../../services/auth.service';
import { AlertMsgModel } from '../../../../models/general/alert.model';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { AppComponent } from '../../../../app.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
    constructor(private app: AppComponent, private router: Router,
        private activatedRoute: ActivatedRoute,
        private authenticationService: AuthService, private authService: MsalService, private msalBroadcastService: MsalBroadcastService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) {
        this.app.setPageSettings({
            pageEmpty: true
        });
    }

    bg;
    loginForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    message: AlertMsgModel;
    get f() { return this.loginForm.controls; }

    private readonly _destroying$ = new Subject<void>();

    loginDisplay = false;
    dataSource: any = [];

    ngOnInit() {
        this.bg = '/assets/img/login-bg/login-bg-17.jpg';


        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                const payload = result.payload as AuthenticationResult;
                this.authService.instance.setActiveAccount(payload.account);

                this.authenticationService.getRoles();
                this.loading = false;
                this.router.navigate(['/dashboard']);
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
                this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims)
            });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
    }

    loginB2C(userFlowRequest?: RedirectRequest | PopupRequest) {
        console.log('loginB2C DBK')
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(response.account);
                    });
            } else {
                this.authService.loginPopup(userFlowRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(response.account);
                    });
            }
        } else if (this.msalGuardConfig.authRequest) {
            console.log('guard cofig')
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect(userFlowRequest);
        }
    }
    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        let activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }
    }

    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    getClaims(claims: any) {
        this.dataSource = [
            { id: 1, claim: "Display Name", value: claims ? claims['name'] : null },
            { id: 2, claim: "Object ID", value: claims ? claims['oid'] : null },
            { id: 3, claim: "Job Title", value: claims ? claims['jobTitle'] : null },
            { id: 4, claim: "City", value: claims ? claims['city'] : null },
        ];
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

}
