
import { switchMap } from 'rxjs/operators';
import { ProductService } from '../../services';
import { SelectableModel } from '../general/selectable.model';
import { ListItemModel } from '../list-item.model';
import { ProductVersionModel } from './product-version.model';
import { of } from 'rxjs';

export class ProductModel extends SelectableModel {
    id: number;
    name: string;

    channelId: number;
    channelName: string;

    channelTypeId: number;
    channelTypeName: string;

    defaultVersionId: number;
    defaultVersionName: string;
    defaultVersionFeatureImage: string;

    versions: number;
    versionIdString:string;
    isActive: boolean;

    tags: Array<string>;

    channel: ListItemModel;
    channelType: ListItemModel;
    defaultVersion: ListItemModel;

    versionLowStock = 0;
    versionOutOfStock = 0;
    
    childVersions: Array<ProductVersionModel> = [];

    versionStock() {
        this.versionLowStock = 0;
        this.versionOutOfStock = 0;
        if (this.childVersions) {
            this.childVersions.forEach(x => {
                if (!x.isActive || !(x.isPhysical || x.isOtk)) return;
                if (x.otkStock === 0) this.versionOutOfStock++;
                if (x.stockLimit && x.otkStock <= x.stockLimit) this.versionLowStock++;
            });
        }
    }

    getVersions(productSvc: ProductService) {
        return productSvc.getVersionsForProduct(this.id).pipe(switchMap(data =>  this.onGetVersions(data)));
    }

    onGetVersions(data) {
        this.childVersions = [];
        if (data['result']) {
            data['result'].forEach(x => {
                this.childVersions.push(ProductVersionModel.parse(x));
            });
            this.versionStock();
        }
        if(this.childVersions?.length > 0) {
            return this.childVersions;
        } else {
            return of(null);
        }
    }

    static parse(data: any): any {
        return new ProductModel(data['id'],
            data['name'],
            data['channelId'],
            data['channelName'],
            data['channelTypeId'],
            data['channelTypeName'],
            data['defaultVersionId'],
            data['defaultVersionFeatureImage'],
            data['defaultVersionName'],
            data['versions'],
            data['versionIdString'],
            data['isActive']);
    }

    constructor(id: number,
        name: string,
        channelId: number,
        channelName: string,
        channelTypeId: number,
        channelTypeName: string,
        defaultVersionId: number,
        defaultVersionFeatureImage: string,
        defaultVersionName: string,
        versions: number,
        versionIdString:string,
        isActive: boolean) {
        super();
        this.id = id;
        this.name = name;
        this.channelId = channelId;
        this.channelName = channelName;
        this.channelTypeId = channelTypeId;
        this.channelTypeName = channelTypeName;
        this.defaultVersionId = defaultVersionId;
        this.defaultVersionName = defaultVersionName;
        this.defaultVersionFeatureImage = defaultVersionFeatureImage;
        this.versions = versions;
        this.versionIdString = versionIdString;
        this.isActive = isActive;

        this.channel = new ListItemModel(0, '');
        this.channelType = new ListItemModel(0, '');
        this.defaultVersion = new ListItemModel(0, '');
    }

    public static create(): ProductModel {
        return new ProductModel(0, '', 0, '', 0, '', 0, '', '', 0,'', true);
    }

    public flatModel() {
        this.channelId = this.channel.id;
        this.channelName = this.channel.value;
        this.channelTypeId = this.channelType.id;
        this.channelTypeName = this.channelType.value;

        if (this.defaultVersion.id === 0) {
            this.defaultVersionId = this.defaultVersion.id;
            this.defaultVersionName = this.defaultVersion.value;
        }
    }
    public expandModel() {
        this.channel = new ListItemModel(this.channelId, this.channelName);
        this.channelType = new ListItemModel(this.channelTypeId, this.channelTypeName);
        this.defaultVersion = new ListItemModel(this.defaultVersionId, this.defaultVersionName);
    }
}
