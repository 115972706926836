import { Component } from '@angular/core';
import { AuthService, InternationalizationService } from '../../../../services';

@Component({
    selector: 'app-settings-management',
    templateUrl: './settings-management.component.html',
    styleUrls: ['./settings-management.component.scss']
})
/** settingsManagement component*/
export class SettingsManagementComponent {

    constructor(private auth: AuthService, private i18Svc: InternationalizationService) {
        this.i18Svc.initialize();
    }
    public currencyView = this.auth.canViewCurrency;
    public languageView = this.auth.canViewLanguage;
    public regionView = this.auth.canViewRegion;
    public taxView = this.auth.canViewTax;
}
