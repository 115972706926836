import { Component, Input, OnInit } from '@angular/core';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { UserDetailModel } from '../../../models/user/user-detail.model';
import { UserViewModel } from '../../../models/user/user-view.model';
import { AuthService, CommonService, ExcelService, InternationalizationService, UserService } from '../../../services';
import { UserEditModalComponent } from '../../modals';
import {ClrDatagridSortOrder} from '@clr/angular';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss']
})
/** userManagement component*/
export class UserManagementComponent implements OnInit {
    // 1 Hidden
    // 2 Collapse
    // 3 Show

    // 1 Loading
    // 2 Table
    // 3 No Data Table
    // 4 Error
    @Input() id: number;
    @Input() open: boolean;

    userDisplayCollapse = false;
    userDisplayHidden = false;
    userTier0TableState: number;

    users: UserViewModel[] = [];
    filterUsers: UserViewModel[] = [];
    selectedUsers: UserViewModel[] = [];
    currentTab: number;

    openUserEditModal: boolean;
    modalReference: ModalTransferModel;

    openUserImportModal: boolean;
    ascSort = ClrDatagridSortOrder.ASC;

    showInactiveUsers: boolean;

    constructor(
        private usrService: UserService,
        private i18Svc: InternationalizationService,
        private excelSvc: ExcelService,
        private auth: AuthService,
        public common: CommonService) {
        this.i18Svc.initialize();
    }

    canView = this.auth.canViewUsers;
    canEdit = this.auth.canEditUsers;
    tier = this.auth.getTier();
    ngOnInit(): void {
        this.getTier0Users(false);
    }

      
    
    onGetUser(data) {
        const user = data;
        this.openUserEditModal = true;
        this.modalReference = new ModalTransferModel('Edit', UserEditModalComponent, user, null, null);
    }

    usersFilter(filterValue: string) {
        if (filterValue) {
            filterValue = filterValue.trim().toLowerCase();
            this.filterUsers = this.users.filter(x => {
                return x.Username?.toLowerCase().indexOf(filterValue) >= 0
                    || x.Email?.toLowerCase().indexOf(filterValue) >= 0
                    || x.PhoneNumber?.toLowerCase().indexOf(filterValue) >= 0
            });
        }
        else {
            this.filterUsers = this.users;
        }
    }

    getTier0Users(force: boolean) {
        this.userTier0TableState = 1; // Loading
        this.usrService.getTier0Users(force)
            .subscribe(
                data => this.onGetTier0Users(data),
                err => this.onGetTier0UserError(err));
    }
    onGetTier0UserError(err) {
        this.userTier0TableState = 4; // Error
    }
    onGetTier0Users(data) {
        this.selectedUsers = [];
        if (data['result']) {
            this.users = [];
            this.filterUsers = [];
            data['result'].forEach(x => {
                this.users.push(UserViewModel.Parse(x));
            });
            if (this.users.length > 0) {
                this.userTier0TableState = 2; // Table
            } else {
                this.userTier0TableState = 3; // No Data
            }
            this.filterUsers = this.users;
        } else {
            this.userTier0TableState = 4; // Error
        }
    }

    closeUsers() {
        this.userDisplayHidden = true;
    }
    refreshTier0Users() {
        this.selectedUsers = [];
        this.getTier0Users(true);
    }

    userEdit(user: UserDetailModel) {
        if (this.filterUsers) {
            this.openUserEditModal = true;
            this.modalReference = new ModalTransferModel('Edit', UserEditModalComponent, user, null, null);
        }
    }

    userCreate() {
        if (this.canEdit) {
            const modelUser = UserDetailModel.Create();
            modelUser.tier = 0;
            this.openUserEditModal = true;
            this.modalReference = new ModalTransferModel('New', UserEditModalComponent, modelUser, null, null);
        }
    }

    onUserModalCloseModalClose(refresh: boolean): void {
        this.openUserEditModal = false;
        if (refresh) {
            this.refreshTier0Users();
        }
    }

    tabSelection(e): void {
        this.currentTab = e['index'];
        if (e['index'] === 1) {
            this.refreshTier0Users();
        }
    }

    onUsersExport(): void {
        if (this.filterUsers.length > 0) {
            const exportData: any[] = this.selectedUsers?.length > 0 ? this.selectedUsers : this.filterUsers;
            exportData?.forEach(x => {
                x.Regions = x.Regions?.join(",");
                x.Accounts = x.Accounts?.join(",");
                x.Roles = x.Roles?.join(",");
            });
            this.excelSvc.exportAsExcelFile(exportData, 'Tier0Users');
        }
    }

    importUser() {
        this.openUserImportModal = true;
    }

    onUserImportModalClose(refresh?: boolean) {
        this.openUserImportModal = false;
        if (refresh) {
            this.refreshTier0Users();
        }
    }
    statusString:string='';
    onSelect(){
        
        for (let index = 0; index < this.selectedUsers.length ; index++) {
            if (index === 0) {
                if(this.selectedUsers[index].isActive && this.selectedUsers[index].isEnable){
                    this.statusString = 'disable_text';
                }else{
                    this.statusString = 'enable_text';
                } 
                if (this.selectedUsers.length <= 1) {
                    return;
                }
            }else{
                if(this.selectedUsers[index].isEnable !== this.selectedUsers[index-1].isEnable){
                    this.statusString = 'enable_disable_text';
                    return;
                }
                if(this.selectedUsers[index].isActive && this.selectedUsers[index].isEnable){
                    this.statusString = 'disable_text';
                }else{
                    this.statusString = 'enable_text';
                } 
            }
            
        }

        return this.statusString;

    }
    userDisable(selectedUsers:UserViewModel[],filter:string){
        if (filter === 'enable') {
            selectedUsers = selectedUsers.filter(item => item.isEnable === false);
        } else {
            selectedUsers = selectedUsers.filter(item => item.isEnable === true);
        }
        selectedUsers.forEach(element => {
            this.usrService.disableUser(element.id,element.isEnable).subscribe(()=>{
                this.refreshTier0Users();
            });
        });
    }

}
