import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  template: `
  <div style="font-size: 35px">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    <label style="color: #616161;">Page Not Found.</label>
  </div>`
})
export class PageNotFoundComponent {

}
