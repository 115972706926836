
import { SelectableModel } from './general/selectable.model';


export class ClientMainDashModel extends SelectableModel {
    id: string;
    name: string;
    sales: number;
    salesChange: number;
    cost: number;
    costChange: number;
    revenue: number;
    revenueChange: number;
    accounts: number;
    accountsChange: number;
    credit: number;
    creditChange: number;
    balance: number;
    balanceChange: number;
    invoices: number;
    invoicesChange: number;
    invoicesPastDue: number;
    invoicesPastDueChange: number;
    billingDate: string;
    openRequest: number;
    satisfactionScore: number;
    satisfactionScoreChange: number;
    currencyCode: string;


    public static parse(data: any): ClientMainDashModel {
        return new ClientMainDashModel(
            data['id'],
            data['name'],
            data['sales'],
            data['salesChange'],
            data['cost'],
            data['costChange'],
            data['revenue'],
            data['revenueChange'],
            data['accounts'],
            data['accountsChange'],
            data['credit'],
            data['creditChange'],
            data['balance'],
            data['balanceChange'],
            data['invoices'],
            data['invoicesChange'],
            data['invoicesPastDue'],
            data['invoicesPastDueChange'],
            data['billingDate'],
            data['openRequest'],
            data['satisfactionScore'],
            data['satisfactionScoreChange'],
            data['currencyCode']);
    }

    constructor(id: string,
        name: string,
        sales: number,
        salesChange: number,
        cost: number,
        costChange: number,
        revenue: number,
        revenueChange: number,
        accounts: number,
        accountsChange: number,
        credit: number,
        creditChange: number,
        balance: number,
        balanceChange: number,
        invoices: number,
        invoicesChange: number,
        invoicesPastDue: number,
        invoicesPastDueChange: number,
        billingDate: string,
        openRequest: number,
        satisfactionScore: number,
        satisfactionScoreChange: number,
        currencyCode: string) {
        super();
        this.id = id;
        this.name = name;
        this.sales = sales;
        this.salesChange = salesChange;
        this.cost = cost;
        this.costChange = costChange;
        this.revenue = revenue;
        this.revenueChange = revenueChange;
        this.accounts = accounts;
        this.accountsChange = accountsChange;
        this.credit = credit;
        this.creditChange = creditChange;
        this.balance = balance;
        this.balanceChange = balanceChange;
        this.invoices = invoices;
        this.invoicesChange = invoicesChange;
        this.invoicesPastDue = invoicesPastDue;
        this.invoicesPastDueChange = invoicesPastDueChange;
        this.billingDate = billingDate;
        this.openRequest = openRequest;
        this.satisfactionScore = satisfactionScore;
        this.satisfactionScoreChange = satisfactionScoreChange;
        this.currencyCode = currencyCode;
    }
}


export class CreditClientLineModel extends ClientMainDashModel {
    newBalance: number;
    newCredit: number;
    get isEdited(): boolean {
        return this.newBalance !== this.balance || this.newCredit !== this.credit;
    }
    constructor(id: string,
        name: string,
        sales: number,
        salesChange: number,
        cost: number,
        costChange: number,
        revenue: number,
        revenueChange: number,
        accounts: number,
        accountsChange: number,
        credit: number,
        creditChange: number,
        balance: number,
        balanceChange: number,
        invoices: number,
        invoicesChange: number,
        invoicesPastDue: number,
        invoicesPastDueChange: number,
        billingDate: string,
        openRequest: number,
        satisfactionScore: number,
        satisfactionScoreChange: number,
        currencyCode: string
    ) {
        super(id, name, sales, salesChange, cost, costChange, revenue, revenueChange, accounts,
            accountsChange, credit, creditChange, balance, balanceChange, invoices, invoicesChange,
            invoicesPastDue, invoicesPastDueChange, billingDate, openRequest, satisfactionScore,
            satisfactionScoreChange, currencyCode);
        this.newBalance = balance;
        this.newCredit = credit;
    }

    public static parse(data: any): CreditClientLineModel {
        return new CreditClientLineModel(
            data['id'],
            data['name'],
            data['sales'],
            data['salesChange'],
            data['cost'],
            data['costChange'],
            data['revenue'],
            data['revenueChange'],
            data['accounts'],
            data['accountsChange'],
            data['credit'],
            data['creditChange'],
            data['balance'],
            data['balanceChange'],
            data['invoices'],
            data['invoicesChange'],
            data['invoicesPastDue'],
            data['invoicesPastDueChange'],
            data['billingDate'],
            data['openRequest'],
            data['satisfactionScore'],
            data['satisfactionScoreChange'],
            data['currencyCode']);
    }
}
