import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox-control',
  templateUrl: './checkbox-control.component.html',
  styleUrls: ['./checkbox-control.component.scss']
})
/** checkbox-control component*/
export class CheckboxControlComponent {
  propertyValue;
  @Input() title: string;
  @Input() isDisabled: boolean;

  @Input() get property() {
    return this.propertyValue;
  }

  @Output() propertyChange = new EventEmitter();

  set property(val) {
    this.propertyValue = val;
    this.propertyChange.emit(this.propertyValue);
  }

  change() {
    if (!this.isDisabled) {
      this.property = this.propertyValue;
    }
    
  }
}
