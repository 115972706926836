import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { ModalTransferModel } from '../../../../../models/modal-transfer.model';
import { CurrencyModel } from '../../../../../models/billing/currency.model';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { CurrencyModalComponent } from '../../currency-modal/currency-modal.component';
import { AuthService, CommonService, InternationalizationService } from '../../../../../services';

@Component({
    selector: 'app-currency-table',
    templateUrl: './currency-table.component.html',
    styleUrls: ['./currency-table.component.scss']
})

export class CurrencyTableComponent implements OnInit {
    data: Array<CurrencyModel> = [];
    dataFiltered: Array<CurrencyModel> = [];
    selectedCurrency: CurrencyModel;

    tableState = 0;

    openCurrencyModal: boolean;
    modalReference: ModalTransferModel;
     


    constructor(private confSvc: ConfigurationService, private auth: AuthService,
        private i18Svc: InternationalizationService,
        public common: CommonService) {
        this.i18Svc.initialize();
    }
    canView = this.auth.canViewCurrency;
    canEdit = this.auth.canEditCurrency;

    ngOnInit() {
        this.get();
    }
      
    filter(filterValue: string) {
        filterValue = filterValue?.toUpperCase();
        this.dataFiltered = this.data.filter(x => x.id?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.name?.toUpperCase().indexOf(filterValue) > -1
            || x.symbol?.toUpperCase().indexOf(filterValue) > -1
            || x.code?.toUpperCase().indexOf(filterValue) > -1
            || x.exchangeRate?.toString()?.toUpperCase().indexOf(filterValue) > -1);
    }
    refresh() {
        this.get();
    }

    edit(): void {
        let modalTitle = '';
        if (this.canEdit) {
            modalTitle = 'edit';
        }

        else {
            modalTitle = 'open';
        }
        this.openCurrencyModal = true;
        const selected = JSON.parse(JSON.stringify(this.selectedCurrency));
        this.modalReference = new ModalTransferModel(modalTitle, CurrencyModalComponent, selected, null, null);
    }

    add(): void {
        if (this.canEdit) {
            this.openCurrencyModal = true;
            this.modalReference = ModalTransferModel.createMode(CurrencyModalComponent);
        }
    }

    onCurrencyModalClose(refresh: boolean): void {
        this.openCurrencyModal = false;
        if(refresh) {
            this.refresh();
        }
    } 

    get() {
        if (this.canView) {
            this.tableState = 1;
            this.data = [];
            this.dataFiltered = [];
            this.confSvc.getCurrency()
                .subscribe(data => this.onGet(data), err => this.onGetError(err));
        }
    }

    onGet(data) {
        if (data['result']) {
            this.data = [];
            this.dataFiltered = [];
            data['result'].forEach(x => {
                this.data.push(CurrencyModel.parse(x));
                this.dataFiltered.push(CurrencyModel.parse(x));
            });
            this.tableState = 2;
        } else {
            this.tableState = 4;
        }
    }
    onGetError(err) {
        this.onError(err);
    }
    onError(msg) {
        swal({
            position: 'top-end',
            type: 'error',
            title: 'Oops...',
            text: msg,
            footer: '<a href>Why do I have this issue?</a>'
        });
    }
}
