// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.region-sec {
  justify-content: center;
  height: 100%;
  flex-wrap: wrap;
  padding: 0.5rem 0.25rem 1rem;
  align-items: center;
}
.region-sec .region-sec-items {
  height: 20px;
}

.region-loader {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/region-banner/region-banner.component.scss"],"names":[],"mappings":"AACA;EACI,uBAAA;EACA,YAAA;EACA,eAAA;EACA,4BAAA;EACA,mBAAA;AAAJ;AACI;EACI,YAAA;AACR;;AAGA;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAAJ","sourcesContent":["\n.region-sec {\n    justify-content: center;\n    height: 100%;\n    flex-wrap: wrap;\n    padding: .5rem 0.25rem 1rem;\n    align-items: center;\n    .region-sec-items {\n        height: 20px;\n    }\n}\n\n.region-loader {\n    margin-top: 1rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
