import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BillingSalesService } from '../../../services/billing-sales.service';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { SalesInvoiceModel } from '../../../models/billing/sales-invoice.model';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import  jsPDF from 'jspdf';
import  html2canvas from 'html2canvas';
import { IAccountRegionModel } from '../../../models/account.model';
import { AccountService, InternationalizationService } from '../../../services';
import { build_Configurations } from '../../../../environments/environment';
import { EmailService } from '../../../services/email.service';
import { ResponseModel } from '../../../models/core/ResponseModel.model';

@Component({
    selector: 'app-invoice-view-modal',
    templateUrl: './invoice-view-modal.component.html',
    styleUrls: ['./invoice-view-modal.component.scss']
})
/** invoce-modal component*/
export class InvoiceViewModalComponent implements OnInit {
    @Input() reference: ModalTransferModel;
    model: SalesInvoiceModel;
    actionInProgress = false;

    status: string;
    statusClass: string;
    pdfLoading: boolean;
    regionLoading: boolean;

    region: IAccountRegionModel;
    toRegion: IAccountRegionModel;

    @Input() loadingAll: boolean;

    setStatus() {
        if (this.model) {
            switch (this.model.status) {
                case 'P':
                    this.status = 'Placed';
                    this.statusClass = 'label-warning';
                    break;
                case 'A':
                    this.status = 'Approved';
                    this.statusClass = 'label-info';
                    break;
                case 'D':
                    this.status = 'Denied';
                    this.statusClass = 'label-danger';
                    break;
                default:
                    this.status = 'Injected';
                    this.statusClass = 'label-success';
            }
        }
    }

    @Input() set open(open: boolean) {
        this._open = open;
    }
    get open(): boolean {
        return this._open;
    }
    private _open: boolean;

    @Output() modalClose = new EventEmitter();

    logoUrl: string;
    name: string;

    openLogsModal: boolean;

    constructor(
        public activeModal: NgbActiveModal,
        private billingSvc: BillingSalesService,
        private toastr: ToastrService,
        private accountSrv: AccountService,
        private emailService: EmailService,
        private i18Svc: InternationalizationService
        ) {
            this.i18Svc.initialize();
    }

    ngOnInit() {
        this.logoUrl = '../' + build_Configurations.logoUrl;
        this.name = build_Configurations.Name;
        console.log('build_Configurations',build_Configurations);
        this.get();
    }
    get() {
        this.billingSvc.getInvoice(this.reference.object).subscribe(
            data => this.onGet(data),
            err => this.onGetError(err));
    }

    shippingLabel = "";

    openLabel() {
        window.open(this.shippingLabel, '_blank')
    }
    generateShippingLabel() {
        this.billingSvc.generateLabel(this.model.invoiceGuid)
            .subscribe(data => this.onGetShipping(data))
    }

    getShipping() {
        this.billingSvc.getLabel(this.model.invoiceGuid)
            .subscribe(data => this.onGetShipping(data))
    }

    onGetShipping(data) {
        console.log(this.model);
        console.log(data);
        if (data.result.length > 0) {
            if (data.result[0].pdfLabel) {
                this.shippingLabel = data.result[0].pdfLabel;
                console.log(this.shippingLabel );
            }
        }
    }

    onGet(data:ResponseModel<SalesInvoiceModel>) {
        if (data.result) {
            this.regionLoading = true;
            this.setModel(data.result);
            this.getRegion();
            this.getForRegion();
            this.setStatus();
            this.getShipping();
        } else {
            this.onError(data['errorMessage']);
        }
        this.actionInProgress = false;
    }

    setModel(data:SalesInvoiceModel){
        this.model = SalesInvoiceModel.parse(data);
    }

    getRegion() {
        this.region = null;
        this.accountSrv.getRegion(this.model.account.regionId)
            .subscribe(data => this.onGetRegion(data), err => this.handleError(err));
    }

    onGetRegion(data) {
        this.regionLoading = false;
        this.region = data['result'];
    }

    getForRegion() {
        this.toRegion = null;
        this.accountSrv.getForRegion(this.model.account.id)
            .subscribe(data => this.onGetForRegion(data), err => this.handleError(err));
    }

    onGetForRegion(data) {
        this.regionLoading = false;
        this.toRegion = data['result'];
    }

    handleError(err) {
        console.log(err);
        this.regionLoading = false;
    }

    onGetError(err) {
        this.actionInProgress = false;
        this.onError('Something went wrong!');
    }
    onError(msg: string) {
        swal({
            type: 'error',
            title: 'Oops...',
            text: msg,
            footer: '<a href>Why do I have this issue?</a>'
        });
    }
    approve() {
        this.actionInProgress = true;
        this.billingSvc.approveOrder(this.model.invoiceId).subscribe(
            (data:ResponseModel<SalesInvoiceModel>) => this.onChangeStatus(data,0),
            err => this.onChangeStatusError(err, this.model.invoiceId));
    }
    deny() {
        this.actionInProgress = true;
        this.billingSvc.denyOrder(this.model.invoiceId).subscribe(
            (data:ResponseModel<SalesInvoiceModel>) => this.onChangeStatus(data, 1),
            err => this.onChangeStatusError(err, this.model.invoiceId));
    }

    onChangeStatus(data: ResponseModel<SalesInvoiceModel>, action: number) {
        if (action===0) {
            this.toastr.success(this.i18Svc.getTranslateVal('approve_txt'));
        }else{
            this.toastr.success(this.i18Svc.getTranslateVal('deny_txt'));
        }
        this.setModel(data.result);
    }

    onChangeStatusError(error: any, id: number) {
        console.log('error', error)
        this.toastr.error(`Error while changing status for order ID:${id}`);
        this.get();
    }

    close() {
        this.open = false;
        this.modalClose.emit(this.open);
    }

    download() {
        this.pdfLoading = true;
        const data = document.getElementById('print_id');
        setTimeout(() => {
            html2canvas(data).then(canvas => {
                const imgWidth = 208;
                const imgHeight = canvas.height * imgWidth / canvas.width;
                const contentDataURL = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.save('MYPdf.pdf');
                this.pdfLoading = false;
            });
        }, 2000);
    }
    sendMail(opt:string){

        this.emailService.sendMail(opt,this.model?.invoiceGuid)
      
      }

      openLogs() {
        this.openLogsModal = true;
        console.log(this.model);
      }

      onLogsModalClose() {
        this.openLogsModal = false;
      }
}
