import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';
import { TokenEsdFilter } from '../models/tokens/token.model';

@Injectable()
export class TokenService {


  constructor(private srv: CoreService) {
  }
  public getTokenGroups(): Observable<any> {
    return this.srv.get(`${this.srv.baseUrl}/token/TokenGroups/`);
  }
  public getToken(model: TokenEsdFilter): Observable<any> {
    return this.srv.get(`${this.srv.baseUrl}/token/get?regionId${model.regionId}`
      + `&accountId${model.accountId}&clientId${model.clientId}&versionId${model.regionId}`);
  }

  public getCurrentToken(): string | null {
    const token = localStorage.getItem('jwt');
    if (token) {
      return token;
    }
    return null;
  }

}
