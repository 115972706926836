import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalTransferModel } from '../../../../models/modal-transfer.model';
import { UserDetailModel } from '../../../../models/user/user-detail.model';
import { UserViewModel } from '../../../../models/user/user-view.model';
import { CommonService, ExcelService, InternationalizationService, UserService } from '../../../../services';
import { UserEditModalComponent } from '../../../modals';
import {ClrDatagridSortOrder} from '@clr/angular';

@Component({
    selector: 'app-internal-user',
    templateUrl: './internal-user.component.html',
    styleUrls: ['./internal-user.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InternalUserComponent implements OnInit {
    
    internalTableState: number;
    users: UserViewModel[] = [];
    filterUsers: UserViewModel[] = [];
    selectedUsers: UserViewModel[] = [];
    
    openUserEditModal: boolean;
    modalReference: ModalTransferModel;
    openUserImportModal: boolean;
    ascSort = ClrDatagridSortOrder.ASC;
    statusString:string='';

    showInactiveUsers: boolean;
    

    constructor(
        private usrService: UserService,
        private excelSvc: ExcelService,
        private i18Svc: InternationalizationService,
        public common: CommonService
        ) {
        this.i18Svc.initialize();
    }

    ngOnInit() {
        this.getUsers(false);
    }
      

    refreshUsers(): void {
        console.log('object');
        this.selectedUsers = [];
        this.getUsers(true);
    }

    getUsers(force: boolean) {
        this.internalTableState = 1; // Loading
        this.usrService.getUsersTab(1, force)
            .subscribe(
                data => this.onGetUsers(data),
                err => this.onGetUserError(err));
    }
    onGetUserError(err) {
        this.internalTableState = 4; // Error
    }
    onGetUsers(data) {
        this.selectedUsers = [];
        if (data['result']) {
            this.users = [];
            this.filterUsers = [];
            data['result'].forEach(x => {
                this.users.push(UserViewModel.Parse(x));
            });
            if (this.users.length > 0) {
                this.internalTableState = 2; // Table
            } else {
                this.internalTableState = 3; // No Data
            }
            this.filterUsers = this.users;
        } else {
            this.internalTableState = 4; // Error
        }
    }

    usersFilter(filterValue: string) {
        if (filterValue) {
            filterValue = filterValue.trim().toLowerCase();
            this.filterUsers = this.users.filter(x => {
                return x.Username?.toLowerCase().indexOf(filterValue) >= 0
                    || x.Email?.toLowerCase().indexOf(filterValue) >= 0
                    || x.PhoneNumber?.toLowerCase().indexOf(filterValue) >= 0
            });
        }
        else {
            this.filterUsers = this.users;
        }
    }

    userEdit(user: UserDetailModel) {
        if (this.filterUsers) {
            this.openUserEditModal = true;
            this.modalReference = new ModalTransferModel('Edit', UserEditModalComponent, user, null, null);
        }
    }

    onUserModalCloseModalClose(refresh: boolean): void {
        this.openUserEditModal = false;
        if (refresh) {
            this.refreshUsers();
        }
    }

    userCreate() {
        const modelUser = UserDetailModel.Create();
        modelUser.tier = 1;
        this.openUserEditModal = true;
        this.modalReference = new ModalTransferModel('New', UserEditModalComponent, modelUser, null, null);
    }

    onExport(): void {
        if (this.filterUsers.length > 0) {
            const exportData: any[] = this.selectedUsers?.length > 0 ? this.selectedUsers : this.filterUsers;
            exportData?.forEach(x => {
                x.Regions = x.Regions?.join(",");
                x.Accounts = x.Accounts?.join(",");
                x.Roles = x.Roles?.join(",");
            });
            this.excelSvc.exportAsExcelFile(exportData, 'InternalUsers');
        }
    }
    userDisable(selectedUsers:UserViewModel[],filter:string){
        if (filter === 'enable') {
            selectedUsers = selectedUsers.filter(item => item.isEnable === false);
        } else {
            selectedUsers = selectedUsers.filter(item => item.isEnable === true);
        }
        selectedUsers.forEach(element => {
            this.usrService.disableUser(element.id,element.isEnable).subscribe(()=>{
                this.refreshUsers();
            });
        });
    }

    importUser() {
        this.openUserImportModal = true;
    }

    onUserImportModalClose(refresh?: boolean) {
        this.openUserImportModal = false;
        if (refresh) {
            this.refreshUsers();
        }
    }

    onSelect(){
        
        for (let index = 0; index < this.selectedUsers.length ; index++) {
            if (index === 0) {
                if(this.selectedUsers[index].isActive && this.selectedUsers[index].isEnable){
                    this.statusString = 'disable_text';
                }else{
                    this.statusString = 'enable_text';
                } 
                if (this.selectedUsers.length <= 1) {
                    return;
                }
            }else{
                if(this.selectedUsers[index].isEnable !== this.selectedUsers[index-1].isEnable){
                    this.statusString = 'enable_disable_text';
                    return;
                }
                if(this.selectedUsers[index].isActive && this.selectedUsers[index].isEnable){
                    this.statusString = 'disable_text';
                }else{
                    this.statusString = 'enable_text';
                } 
            }
            
        }

        return this.statusString;

    }

}
