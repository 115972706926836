import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { ResponseModel } from '../models/core/ResponseModel.model';
import { API_CONFIG } from '../../environments/environment';

@Injectable()
export class CoreService2<T> {
  constructor(private http: HttpClient) {

  }
  public baseUrl = API_CONFIG.CORE_API;
  public storeUrl = API_CONFIG.STORE_API;
  public manageURL = API_CONFIG.ACCOUNTMANAGEMENT_API2;

  private createAuthorizationHeader() {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token, content-type');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT');
    headers.append('Access-Control-Allow-Origin', '*'); // TODO: this needs to be configure
    return headers;
  }

  private formatErrors(error: any) {
    console.log(error.error);
    return throwError(error.error);
  }


  public get(url: string): Observable<ResponseModel<T>> {
    const headers = this.createAuthorizationHeader();
    return this.http.get<ResponseModel<T>>(url, { headers: headers })
      .pipe(catchError(this.formatErrors));
  }
}
