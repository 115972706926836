import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceModel } from '../../../models/invoice.model';
import { InvoiceViewModalComponent } from '../invoice-view-modal/invoice-view-modal.component';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { BillingSalesService } from '../../../services/billing-sales.service';
import { SalesInvoiceListViewModel } from '../../../models/billing/sales-invoice.model';
import { LicenseOrderTableComponent } from '../license-order-table/license-order-table.component';
import { CommonService, InternationalizationService } from '../../../services';
import { EmailService } from '../../../services/email.service';


@Component({
  selector: 'app-invoice-modal',
  templateUrl: './invoice-modal.component.html',
  styleUrls: ['./invoice-modal.component.scss']
})
/** support-modal component*/
export class InvoiceModalComponent implements OnInit {
  @Input() name: string;
  @Input() id: number;
  @Input() reference: ModalTransferModel;

  data: Array<SalesInvoiceListViewModel> = [];
  dataFiltered: Array<SalesInvoiceListViewModel> = [];
  dataSelected: SalesInvoiceListViewModel;

  tableState = 0;

  invoices: Array<InvoiceModel> = [];

  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;

  @Output() modalClose = new EventEmitter();

  openInvoiceViewModal: boolean;
  openLicenseModal: boolean;
  modalReference: ModalTransferModel;
  selectedLicenseData: SalesInvoiceListViewModel;
  isTableLoading: boolean;
   

  constructor(public activeModal: NgbActiveModal,
     private srv: BillingSalesService,
      private i18Svc: InternationalizationService,
      private emailService: EmailService,private cdref: ChangeDetectorRef,
      public common: CommonService
      ) {
    this.i18Svc.initialize();
  }

  ngOnInit() {
    this.get();
  }

  ngDoCheck() {
    this.reloadLbl();
}
ngAfterViewInit(){
    this.reloadLbl();
}
reloadLbl(){
       
    this.cdref.detectChanges();
}

  get() {
    if (this.id) {
      this.tableState = 1;
      this.data = [];
      this.dataFiltered = [];
      this.resetDefaultFilter();
      this.srv.getInvoicesByAccount(this.id).subscribe(
        data => this.onGet(data),
        err => this.onGetError(err));
    }
  }

  onGet(data) {
    if (data['result']) {
      this.data = [];
      this.dataFiltered = [];
      const result = (data['result'] || []).sort((a, b) => new Date(b.updateOn || '').getTime() - new Date(a.updateOn || '').getTime());
      (result || []).forEach(x => {
        this.data.push(SalesInvoiceListViewModel.parse(x));
        this.dataFiltered.push(SalesInvoiceListViewModel.parse(x));
      });
      this.tableState = 2;
    } else {
      this.tableState = 4;
    }
  }

  resetDefaultFilter() {
    this.isTableLoading = true;
    setTimeout(() => {
      this.isTableLoading = false;
    }, 1);
  }

  licenses() {
    if (this.dataSelected) {
      this.openLicenseModal = true;
      this.selectedLicenseData = JSON.parse(JSON.stringify(this.dataSelected || {}));
      this.reference.transerWithReturn(LicenseOrderTableComponent, { windowClass: 'dbcLargeModal' }, this.dataSelected.id);
      this.modalReference = JSON.parse(JSON.stringify(this.reference));
    }
  }

  onLicenseModalClose(_): void {
    this.openLicenseModal = false;
  }

  edit() {
    if (this.dataSelected) {
      this.openInvoiceViewModal = true;
      this.reference.transerWithReturn(InvoiceViewModalComponent, { windowClass: 'dbcLargeModal' }, this.dataSelected.id);
      this.modalReference = JSON.parse(JSON.stringify(this.reference));
    }
  }

  onInvoiceViewModalClose(_): void {
    this.openInvoiceViewModal = false;
  }

  get hideInvoiceModal(): boolean {
    return this.openInvoiceViewModal || this.openLicenseModal;
  }

  onGetError(err) {
    this.onError(err);
  }

  onError(msg) {
    console.log(msg);
  }

  filter(filterValue: string) {
    filterValue = filterValue?.trim().toLowerCase();
    this.dataFiltered = this.data.filter(x => x.id?.toString().trim().toLowerCase().indexOf(filterValue) > -1
      || x.invoiceGuid?.toString().trim().toLowerCase().indexOf(filterValue) > -1
      || x.referenceNumber?.toString().trim().toLowerCase().indexOf(filterValue) > -1
      || x.accountName?.toString().trim().toLowerCase().indexOf(filterValue) > -1);
  }

  close(): void {
    this.open = false;
    this.modalClose.emit(false);
  }

  sendMail(opt:string){

    this.emailService.sendMail(opt,this.dataSelected?.invoiceGuid.toUpperCase())
  
  }

}
