// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.req-icon {
  color: red;
  font-size: 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/modals/account-contact-form-modal/account-contact-form-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,kBAAA;AACJ","sourcesContent":[".req-icon {\n    color: red;\n    font-size: .75rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
