import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/core/ResponseModel.model';
import { AuditModel } from '../models/audit.model';

@Injectable({
    providedIn: 'root'
})
export class AuditService {

    constructor(private srv: CoreService) {  }

    public getAuditList(invoiceId: string): Observable<ResponseModel<AuditModel[]>> {
        return this.srv.get(`${this.srv.baseUrl}/audit/List/${invoiceId}`);
    }
}
