// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .cdk-overlay-container {
  z-index: 1200 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/main/settings/controls/region-table/region-table.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF","sourcesContent":["::ng-deep .cdk-overlay-container {\n  z-index: 1200 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
