import { Component } from '@angular/core';

@Component({
    selector: 'app-accounts-management',
    template: `
        <div>
          <router-outlet></router-outlet>
        </div>
  `,
})
export class AccountComponent {

}
