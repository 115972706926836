import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { MarketingService } from '../../../services/marketing.service';
import { ToastrService } from 'ngx-toastr';
import { BannerModel } from '../../../models/marketing/banner.model';
import { AccountRegionModel } from '../../../models/account.model';
import { AccountService, CommonService, InternationalizationService } from '../../../services';

@Component({
  selector: 'app-banner-table-modal',
  templateUrl: './banner-table-modal.component.html',
  styleUrls: ['./banner-table-modal.component.scss']
})
export class BannerTableModalComponent implements OnInit {
  @Input() reference: ModalTransferModel;

  model: BannerModel;
  regions: Array<AccountRegionModel> = [];
  isNew: boolean;
  title: string;
  dropzoneConfig: DropzoneConfigInterface;
  readonly imagePath: string = 'https://dbcvalhallaimage.blob.core.windows.net/dbcvalhallaimage/';

  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;

  @Output() modalClose = new EventEmitter();

  loading: boolean;

  constructor(private readonly svc: MarketingService, private readonly toastr: ToastrService,
    private readonly accountSrv: AccountService, private readonly i18Svc: InternationalizationService,
    private readonly common: CommonService) {
    this.i18Svc.initialize();
  }

  ngOnInit() {
    this.initMode();
    this.getRegionsForBanner();
    this.dropzoneConfig = this.common.getBannerDropzoneConfig();
  }

  getRegionsForBanner() {
    this.accountSrv.getAllRegions()
      .subscribe(data => this.onRegionGetForBanner(data), err => this.onRegioGetErrorForBanner(err));
  }

  onRegionGetForBanner(data) {
    if (data.result) {
      this.regions = [];
      data.result.forEach(x => this.regions.push(AccountRegionModel.parse(x)));
    } else {
      this.onRegioGetErrorForBanner(data.errorMessage);
    }
  }

  onRegioGetErrorForBanner(data) {
    this.showError('Error getting regions');
  }

  initMode() {
    // Set Mode
    this.isNew = this.reference.action === 'newBottomBanner' || this.reference.action === 'newSideBanner';
    // Set Model
    if (!this.isNew) {
      this.model = this.reference.object;
    } else {
      const positionId = this.reference.action === 'newBottomBanner' ? 2 : 1;
      this.model = new BannerModel(0, 0, positionId, 0, '', '', null, 0, null, null, true);
    }

    // Set Title
    if (this.isNew) {
      this.title = 'Create Slider';
    } else {
      this.title = 'Slider ' + this.model.id;
    }
  }

  onImageUploadSuccess(data) {
    this.model.imageUrl = this.imagePath + data[1]['result'];
  }

  onImageUploadError(data) { }

  get isValid() {
    return this.model.imageUrl;
  }

  save(): void {
    if (this.isNew) {
      this.createSlider();
    } else {
      this.updateSlider();
    }
  }

  createSlider(): void {
    if (this.isValid) {
      this.loading = true;
      this.svc.createBottomBanner(this.model).subscribe(data => {
        this.loading = false;
        if (data.result) {
            this.showSuccess('Successfully Created.');
        } else {
          this.showError(data.errorMessage);
        }
      }, () => this.loading = false);
    }
  }

  updateSlider(): void {
    this.loading = true;
    this.svc.updateBottomBanner(this.model).subscribe(data => {
      this.loading = false;
      if (data.result) {
        this.showSuccess('Successfully Updated.');
      } else {
        this.showError(data.errorMessage);
      }
    }, () => this.loading = false);
  }

  showSuccess(message: string): void {
    this.toastr.success(message);
  }

  showError(message: string): void {
    this.toastr.error(message);
  }

  close() {
    this.open = false;
    this.modalClose.emit(true);
  }

}
