import { SelectableModel } from '../general/selectable.model';

export class CurrencyModel extends SelectableModel {
  id: number;
  name: string;
  symbol: string;
  code: string;
  exchangeRate: number;
  isActive: boolean;

  public static parse(data: any): CurrencyModel {
    return new CurrencyModel(
      data['id'],
      data['name'],
      data['symbol'],
      data['code'],
      data['exchangeRate'],
      data['isActive']);
  }
  public static create(): CurrencyModel {
    return new CurrencyModel(0, '', '', '', 1, true);
  }

  toString(): string {
    return `${this.name} (${this.symbol})`;
  }

  constructor(id: number,
    name: string,
    symbol: string,
    code: string,
    exchangeRate: number,
    isActive: boolean) {
    super();
    this.id = id;
    this.name = name;
    this.symbol = symbol;
    this.code = code;
    this.exchangeRate = exchangeRate;
    this.isActive = isActive;
  }

  isValid(): any {
    return true;
  }
}

