import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ResponseModel } from '../../../models/core/ResponseModel.model';
import { ListItemModel } from '../../../models/list-item.model';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { UserDetailModel } from '../../../models/user/user-detail.model';
import { UserViewModel } from '../../../models/user/user-view.model';
import { AccountService, AuthService, CommonService, ConfigurationService, ExcelService, InternationalizationService, UserService } from '../../../services';

@Component({
  selector: 'app-user-import-edit',
  templateUrl: './user-import-edit.component.html',
  styleUrls: ['./user-import-edit.component.scss']
})
export class UserImportEditComponent implements OnInit {
  @Input() name: string;
  @Input() id: number;
  @Input() reference: ModalTransferModel;
  @Input() currentTier: number;

  model: UserDetailModel;
  selectedNav = 1;
  regions: Array<ListItemModel> = [];
  accounts: Array<ListItemModel> = [];
  accountsDataFiltered: Array<ListItemModel> = [];
  selectedAccounts: Array<ListItemModel> = [];
  roles: Array<ListItemModel> = [];
  filterRoles: Array<ListItemModel> = [];
  tierOptions = [];
  title: string;
  selectedRegionsIds: Array<number> = [];

  audits: Array<any> = [];
  languageList: Array<ListItemModel> = [];

  isRoleChanged: boolean;
  isRegionChanged: boolean;
  searchModel = '';
  @ViewChild('userForm') userForm: NgForm;
   

  accountState: number;
  accountsDisplayedColumns: string[] = ['select', 'id', 'value'];

  isEmailLoading: boolean;
  isEmailExist: boolean;

  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;

  @Output() modalClose = new EventEmitter();

  filterUsers: UserViewModel[] = [];
  userTableState: number;
  
  loading: boolean;

  constructor(private usrService: UserService,
    private actService: AccountService,
    private configSvc: ConfigurationService,
    private toastr: ToastrService,
    private authSvc: AuthService,
    private i18Svc: InternationalizationService,
    public common: CommonService,
    private excelSvc: ExcelService,private cdref: ChangeDetectorRef) {
    this.i18Svc.initialize();
  }

  public canView = this.authSvc.canViewUsers;
  public canEdit = this.authSvc.canEditUsers;
  public tier = this.authSvc.getTier();

  public isNew = false;

  ngOnInit(): void {
    this.tierOptions.push({ value: 'Tier 3', id: 3 });
    this.tierOptions.push({ value: 'Tier 2', id: 2 });
    if (this.tier === 0 || this.tier === 1) this.tierOptions.push({ value: 'Tier 1', id: 1 });
    if (this.tier === 0) this.tierOptions.push({ value: 'Tier 0', id: 0 });

    this.getLanguageOptions();
    this.getAccount();
    this.isNew = this.reference.action === 'New'
    if (!this.isNew) {
      let title = this.canEdit ? 'Edit' : 'View';
      this.model = this.reference.object[0] as UserDetailModel;
      this.refreshUsers();
      this.title = `${title} ${this.model.Username}`;
      if (this.model.tier === 1) {
        this.getRegions();
      }
      this.tierSelectionChange();
    } else {
      this.model = this.reference.object[0] as UserDetailModel;
      this.refreshUsers();
      this.title = 'New User';
      this.model.languageOption = <any>this.i18Svc.getLanguageID();
      this.model.Accounts = [];
      this.tierSelectionChange();
    }
  }

  ngDoCheck() {
    this.reloadLbl();
}
ngAfterViewInit(){
    this.reloadLbl();
}
reloadLbl(){
       
    this.cdref.detectChanges();
}

  getRoleGroups() {
    this.usrService.getRoleGroups().subscribe(
      data => this.onGetRoleGroups(data),
      err => this.onError(err));
  }

  getMyRoleGroups() {
    this.usrService.getMyGroupRoles(this.model['id']).subscribe(
      data => {
        this.model.RoleGroup = data.result;
        this.getRoleGroups();
      },
      err => this.onError(err));
  }

  getRegions() {
    this.actService.getAllRegionsAsListItem().subscribe(
      data => this.onGetAllRegions(data),
      err => this.onError(err));
  }
  getAccount() {
    this.selectedRegionsIds = [];
    this.accountState = 1;
    this.regions.forEach(x => this.addToListIfSelected(x));
    this.actService.getAccountsAsListItemForRegions(this.selectedRegionsIds).subscribe(
      data => this.onGetAllAccount(data),
      err => this.onError(err));
  }
  changeTabToAccounts() {
    this.selectedNav = 3;
  }
  changeTabToTask() {
    this.selectedNav = 4;
  }
  changeTabToAlert() {
    this.selectedNav = 5;
  }
  changeTabToAudit() {
    this.selectedNav = 6;
  }
  addToListIfSelected(x: ListItemModel) {
    if (x.getSelected()) {
      this.selectedRegionsIds.push(x.id);
    }
  }
  onGetRoleGroups(data: ResponseModel<any>) {
    if (data.result) {
      this.roles = [];
      data.result.forEach(x => {
        const item = new ListItemModel(0, x);
        if (this.model.RoleGroup && this.model.RoleGroup.indexOf(x) > -1) {
          item.setSelected(true);
          this.roles.push(item);
        }
        else if (this.model.tier === 1 && item.value.includes('Internal-')) this.roles.push(item);
        else if (this.model.tier === 2 && item.value.includes('Reseller-')) this.roles.push(item);

      });
      this.roles.sort((n1, n2) => {
        if (n1.value > n2.value) {
          return 1;
        }

        if (n1.value < n2.value) {
          return -1;
        }
        return 0;
      });
    }
  }

  onGetRoles(data: ResponseModel<any>) {
    if (data.result) {
      this.roles = [];
      data.result.forEach(x => {
        const item = new ListItemModel(0, x);
        if (this.model.Roles && this.model.Roles.indexOf(x) > -1) {
          item.setSelected(true);
        }
        this.roles.push(item);
      });
      this.roles.sort((n1, n2) => {
        if (n1.value > n2.value) {
          return 1;
        }

        if (n1.value < n2.value) {
          return -1;
        }
        return 0;
      });
    }
  }
  onGetAllRegions(data: ResponseModel<any>) {
    if (data.result) {
      this.regions = [];
      data.result.forEach(x => {
        const item = ListItemModel.Parse(x);
        if (this.model.Regions?.some(y => y.id === item.id)) {
          item.setSelected(true);
        }
        this.regions.push(item);
      });
    }
  }
  onGetAllAccount(data) {
    if (data['result']) {
      this.accounts = [];
      this.accountsDataFiltered = [];
      data['result'].forEach(x => {
        const account = ListItemModel.Parse(x);
        if (this.model.Accounts) {
          const isSelected = this.model.Accounts.findIndex(x => x.id === account.id) >= 0;
          account.setSelected(isSelected);
        }
        this.accounts.push(account);
        this.accountsDataFiltered.push(account);
      });
      this.accountState = this.accounts.length > 0 ? 2 : 3;
    } else {
      this.accountState = 4;
    }
  }

  onError(err) {
    this.accountState = 4;
  }

  tierSelectionChange() {
    if (!this.isNew) this.getMyRoleGroups();
    else this.getRoleGroups();

    const tier = this.reference.object[0] ? this.model['tier'] : this.model.tier;
    if (tier === 2 || tier === 3 || tier === 1) {
      this.getRegions();
    }
  }

  getLanguageOptions(): void {
    if (this.languageList.length === 0) {
      this.configSvc.getLanguageOptions().subscribe(data => {
        if (data['result']) {
          this.languageList = [];
          data['result'].forEach(x => this.languageList.push(ListItemModel.Parse(x)));
        }
      });
    }
  }

  // Modal General UI Controls
  validModel() {
    return false;
  }
  close(refresh?: boolean) {
    if (refresh || !this.userForm.dirty || confirm(this.i18Svc.getTranslateVal('userEdit_confirm_text'))) {
      this.open = false;
      this.modalClose.emit(refresh);
    }
  }

  save(): void {
    if (this.userForm?.valid) {
      const roleGroup = this.roles.filter(x => x.getSelected()).map(x => x.value);
      const regions = this.regions.filter(x => x.getSelected());
      const accounts = this.accounts.filter(x => x.getSelected());
      const roles = [];

      const body = [];

      this.filterUsers.forEach((x:any)=> {
        const user = x;
        user.RoleGroup = roleGroup;
        user.Regions = regions;
        user.Accounts = accounts;
        user.Roles = roles;
        user.accountId = this.model.accountId;
        user.languageId = this.model.languageId;
        body.push(x);
      });



      this.loading = true;
      this.usrService.postImportUser(body[0]).subscribe(() => this.onSuccess(), err => this.handleErr(err));
    }
  }

  onSuccess(): void {
    this.loading = false;
    this.toastr.success('User have been saved');
    this.close(true);
  }


  onRegionChange(index: number): void {
    if (this.authSvc.isDBAdminUser) {
      this.regions[index].toggleSelected();
      this.userForm.control.markAsDirty();
      this.isRegionChanged = true;
    }
  }

  onRoleChange(index: number): void {
    if (this.authSvc.isDBAdminUser) {
      this.roles[index].toggleSelected();
      this.userForm.control.markAsDirty();
      this.isRoleChanged = true;
    }
  }

  accountsFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();
    this.accountsDataFiltered = this.accounts.filter(x => {
      return x.id?.toString()?.toLowerCase().indexOf(filterValue) >= 0
        || x.value?.toLowerCase().indexOf(filterValue) >= 0
    });
  }

  accountIdToggle(e, element) {
    e.preventDefault();
    if (element.id !== this.model.accountId) {
      element.toggleSelected();
    }
  }

  selectAccount() {
    let selectedIndex;
    this.accounts.forEach((x, i) => {
      x.setSelected(false);
      if (x.id === this.model.accountId) {
        selectedIndex = i;
        x.setSelected(true);
      }
    });
    this.accounts.unshift(this.accounts[selectedIndex]);
    this.accounts.splice(selectedIndex + 1, 1);
    this.accountsDataFiltered = JSON.parse(JSON.stringify(this.accounts));
  }


  handleErr(err): void {
    this.loading = false;
    this.toastr.error('connection error');
  }

  checkEmail(): void {
    const emailControl = this.userForm.controls.Email;
    let err = emailControl.errors;
    if (emailControl.hasError('duplicate')) {
      delete err['duplicate'];
      if (Object.keys(err).length === 0) {
        err = null;
      }
      this.userForm.controls.Email.setErrors(err);
    }
    if (!err) {
      this.isEmailLoading = true;
      this.isEmailExist = false;
      this.usrService.isEmailExist(emailControl.value).subscribe(data => {
        this.isEmailLoading = false;
        if (data.result) {
          err = {
            duplicate: true
          };
          setTimeout(() => {
            this.userForm.controls.Email.markAsDirty();
            this.userForm.controls.Email.setErrors(err);
          }, 1);
        }
      }, () => this.isEmailLoading = false);
    }
  }

  refreshUsers() {
    this.filterUsers = JSON.parse(JSON.stringify(this.reference.object));
  }

  onUsersExport(): void {
    if (this.filterUsers.length > 0) {
      const exportData: any[] = this.filterUsers;
      exportData?.forEach(x => {
        x.Regions = x.Regions?.join(",");
        x.Accounts = x.Accounts?.join(",");
        x.Roles = x.Roles?.join(",");
      });
      this.excelSvc.exportAsExcelFile(exportData, 'ImportUsers');
    }
  }

  usersFilter(filterValue: string) {
    const users = JSON.parse(JSON.stringify(this.reference.object));
    if (filterValue) {
      filterValue = filterValue.trim().toLowerCase();
      this.filterUsers = users.filter(x => {
        return x.Username?.toLowerCase().indexOf(filterValue) >= 0
          || x.Email?.toLowerCase().indexOf(filterValue) >= 0
          || x.PhoneNumber?.toLowerCase().indexOf(filterValue) >= 0
      });
    }
    else {
      this.filterUsers = users;
    }
  }

}
