import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountContactModel } from '../../../models/account.model';
import { AccountService } from '../../../services';

@Component({
  selector: 'app-account-contact-form-modal',
  templateUrl: './account-contact-form-modal.component.html',
  styleUrls: ['./account-contact-form-modal.component.scss']
})
export class AccountContactFormModalComponent implements OnInit {

  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;

  @Output() modalClose = new EventEmitter();

  @Input() contact: AccountContactModel | object = {};

  @Input() id: number;

  title: string;
  loading: boolean;

  @ViewChild('detailForm') detailForm: NgForm;

  constructor(private srv: AccountService,
    private toastr: ToastrService) { }

  ngOnInit() {
    if (this.id) {
      this.title = `Edit contact info for ${this.id}`;
    } else {
      this.title = `Add contact info`;
    }
  }

  close(refresh?: boolean) {
    this.open = false;
    this.modalClose.emit(refresh);
  }

  save() {
    this.loading = true;
    const request: AccountContactModel = JSON.parse(JSON.stringify(this.contact));
    request.isActive = Boolean(request.isActive);
    request.accountId = this.id;
    if(!this.id) {
      request.id = null;
    }
    this.srv.updateContactsByAccountId(this.id, request).subscribe(data => this.onGet(data),
      () => {
        this.toastr.error('error');
        this.loading = false;
      });
  }

  onGet(data) {
    this.loading = false;
    if (data.result) {
      this.toastr.success('Successfully Created.');
      this.close(true);
    } else {
      this.toastr.error(data.errorMessage);
    }
  }

}
