export class TicketModel {
  id: number;
  subject: string;
  status: string;
  createdBy: string;
  createdOn: string;
  assingTo: string;
  modifiedOn: string;

  constructor(id: number,
    subject: string,
    status: string,
    createdBy: string,
    createdOn: string,
    assingTo: string,
    modifiedOn: string) {
    this.id = id;
    this.subject = subject;
    this.status = status;
    this.createdBy = createdBy;
    this.createdOn = createdOn;
    this.assingTo = assingTo;
    this.modifiedOn = modifiedOn;
  }

}
