import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import swal from 'sweetalert2';
import { NgbModalOptions, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ModalTransferModel } from '../../../../models/modal-transfer.model';
import { ClientMainDashModel } from '../../../../models/ClientMainDash.model';
import { MainDashboardService } from '../../../../services/main-dashboard.service';
import { ClientDetailsModalComponent } from '../../../modals/client-details-modal/client-details-modal.component';
import { InvoiceModalComponent } from '../../../modals/invoice-modal/invoice-modal.component';
import { CreditManagementComponent } from '../../../modals/credit-management/credit-management.component';
import { InternationalizationService, AuthService, CommonService } from '../../../../services';
import { ApiManagementComponent } from '../../../modals/api-management/api-management.component';
import { AvailableCreditComparatorComponent } from '../../datagrid-comparator/datagrid-comparator';
import { Subscription } from 'rxjs';
import { UsersManagementComponent } from '../../../modals/users-management/users-management.component';
import {ClrDatagridSortOrder} from '@clr/angular';
import { AccountRegionModel } from '../../../../models/account.model';

@Component({
    selector: 'app-accounts-region-table',
    templateUrl: './accounts-region-table.component.html',
    styleUrls: ['./accounts-region-table.component.scss']
})

export class AccountRegionTableComponent implements OnInit, OnChanges, OnDestroy {
    _regionId: number;

    @Input() regionList: AccountRegionModel[] = [];

@Input() isDashboardScreen: boolean;

    tableState = 0;

    regions: Array<any> = [];
    accounts: Array<any> = [];

    @Input() data: Array<ClientMainDashModel> = [];
    dataFiltered: Array<ClientMainDashModel> = [];
    dataSelected: ClientMainDashModel;

    openCreditManagement: boolean;
    modalReference: ModalTransferModel;

    openClientDetails: boolean;
    openInvoice: boolean;
    openApiManagement: boolean;
    openUserManagement: boolean;

    availableCreditComparator = new AvailableCreditComparatorComponent();

    private subscripe$: Subscription = new Subscription();

    ascSort = ClrDatagridSortOrder.ASC;

    
    // @Input() regionList: AccountRegionModel[] = [];
    // regionInit: boolean;

    @Output() refreshData: EventEmitter<any> = new EventEmitter(); 

    @Input() loading: boolean;
    @Input() loadingAll: boolean;
    @Input() initLoading: boolean;

    loadingLocal: boolean;

    constructor(private srv: MainDashboardService, private i18Svc: InternationalizationService,
        private auth: AuthService, public common: CommonService,
        ) {
        this.i18Svc.initialize();
        
        
    }
    canView = this.auth.canViewAccounts;
    canEdit = this.auth.canEditAccounts;
    canViewCredit = this.auth.canViewCredit;

    selectedRegion: number;

    ngOnInit() {
        if(!this.isDashboardScreen) {
        this.get(true);
        }
        this.subscripe$ = this.common.regionChange$.subscribe(_ => {
            if(!this.isDashboardScreen) {
                this.get(true)
            }
        });
    } 
    
    ngOnChanges(changes: SimpleChanges): void {
        if(changes?.data) {
            this.dataFiltered = this.data || [];
          }
      }

    setRegionId() {
        this._regionId = Number.parseInt((localStorage.getItem('current_region') ? localStorage.getItem('current_region') : '') ?? undefined);
    }

    filter(filterValue: string) {
        filterValue = filterValue?.toUpperCase();
        this.dataFiltered = this.data.filter(x => x.id?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.name?.toUpperCase().indexOf(filterValue) > -1
            || x.credit?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.revenue?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.balance?.toString()?.toUpperCase().indexOf(filterValue) > -1);
    }
    refresh() {
        if(!this.isDashboardScreen) {
            this.get();
        } else {
            if(!this.loadingAll) {
                this.refreshData.emit();
            }
        }
    }

    get isLoading(): boolean {
        return ((this.loading || this.initLoading || this.loadingAll) && (this.data || [])?.length === 0) || this.loadingLocal;
      }

    get(isRegionChange?: boolean) {
        if (!this.isDashboardScreen) {
            if (isRegionChange) {
                this.setRegionId();
            }
            if (this._regionId && this.canView) {
                this.tableState = 1;
                this.data = [];
                this.dataFiltered = [];
                this.loadingLocal = true;
                this.srv.getAccounts(this._regionId)
                    .subscribe(data => this.onGet(data),
                        err => this.onGetError(err));
                this.dataFiltered = this.data;
            }
        }

    }

    onGet(data) {
        if (data['result']) {
            this.data = [];
            this.dataFiltered = [];
            data['result'].forEach(x => this.data.push(ClientMainDashModel.parse(x)));
            this.tableState = 2;
            this.loadingLocal = false;
        } else {
            this.loadingLocal = false;
            this.tableState = 4;
        }
        this.dataFiltered = this.data;
    }
    onGetError(err) {
        this.loadingLocal = false;
        this.onError(err);
    }
    onError(msg) {
        swal({
            position: 'top-end',
            type: 'error',
            title: 'Oops...',
            text: msg,
            footer: '<a href>Why do I have this issue?</a>'
        });
    }


    openModal(modalName: string, account: ClientMainDashModel = null) {
        this.dataSelected = account;
        let options: NgbModalOptions = null;
        switch (modalName) {
            case 'Account':
                this.openClientDetails = true;
                this.setCurrentRegionId();
                this.modalReference = new ModalTransferModel('edit', ClientDetailsModalComponent, null, null);
                break;

            case 'NewAccount':
                if (this.canEdit) {
                    this.openClientDetails = true;
                    this.setCurrentRegionId();
                    this.modalReference = new ModalTransferModel('new', ClientDetailsModalComponent, this.selectedRegion, null, options);
                }
                break;

            case 'Invoice':
                this.openInvoice = true;
                this.modalReference = new ModalTransferModel('open', InvoiceModalComponent, null, null);
                break;

            case 'CreditManagement':
                this.openCreditManagement = true;
                this.setCurrentRegionId();
                this.modalReference = new ModalTransferModel('open', CreditManagementComponent, this.selectedRegion, null);
                break;

            case 'ApiManagement':
                this.openApiManagement = true;
                this.modalReference = new ModalTransferModel('open', ApiManagementComponent, null, null);
                break;
            case 'Users':
                this.openUserManagement = true;
                this.modalReference = new ModalTransferModel('open', UsersManagementComponent, null, null);
                break;
        }
    }

    setCurrentRegionId() {
        if(this.isDashboardScreen) {
            this.selectedRegion = null;
        } else {
            this.setRegionId();
            this.selectedRegion = this._regionId;
        }
    }

    onCreditManagementClose(_): void {
        this.openCreditManagement = false;
        this.refresh();
    }

    onClientDetailsClose(_): void {
        this.openClientDetails = false;
        this.refresh();
    }

    onInvoiceModalClose(_): void {
        this.openInvoice = false;
        this.refresh();
    }

    onApiManagementModalClose(_): void {
        this.openApiManagement = false;
        this.refresh();
    }
    onUserManagementModalClose(_): void {
        this.openUserManagement = false;
        this.refresh();
    }

    swalTransfer(result: ModalTransferModel) {
        if (result.error) {
            swal(result.title, result.msg, 'error');
        } else {
            swal(result.title, result.msg, 'success');
        }
    }

    getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    satisfactionScore(type: number, score: number): boolean {
        switch (type) {
            case 1:
                return score >= 90;
            case 2:
                return score >= 75 && score < 90;
            case 3:
                return score >= 50 && score < 75;
            case 4:
                return score < 50;
            default:
        }
    }

    ngOnDestroy() {
        this.subscripe$?.unsubscribe();
    }

}






