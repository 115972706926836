

import { SelectableModel } from '../general/selectable.model';

export class TokenGroupEsdViewModel extends SelectableModel {
  id: number;
  name: string;
  internalSku: string;
  purchaseSku: string;
  channel: string;
  tokens: number;
  available: number;
  expired: number;
  redeemed: number;
  returned: number;
  error: number;

  public static parse(data: any): TokenGroupEsdViewModel {
    return new TokenGroupEsdViewModel(
      data['name'],
      data['internalSku'],
      data['purchaseSku'],
      data['channel'],
      data['tokens'],
      data['available'],
      data['expired'],
      data['redemed'],
      data['returned'],
      data['error']);
  }

  constructor(name: string,
    internalSku: string,
    purchaseSku: string,
    channel: string,
    tokens: number,
    available: number,
    expired: number,
    redeemed: number,
    returned: number,
    error: number) {
    super();
    this.name = name;
    this.channel = channel;
    this.internalSku = internalSku;
    this.purchaseSku = purchaseSku;
    this.tokens = tokens;
    this.available = available;
    this.expired = expired;
    this.redeemed = redeemed;
    this.returned = returned;
    this.error = error;
  }

  isValid(): any {
    return true;
  }
}
export class TokenOtkViewModel {
  id: number;
  productVersionId: number;
  invoiceItemId: number;
  channelId: number;
  link: string;
  token: string;
  expiration: Date;
  isActive: boolean;
  createdOn: Date;
  updatedOn: Date;
  soldOn: Date;
  invoiceId:number;
  constructor(id: number,
    productVersionId: number,
    invoiceItemId: number,
    channelId: number,
    link: string,
    token: string,
    expiration: Date,
    isActive: boolean,
    createdOn: Date,
    updatedOn: Date,
    soldOn: Date,
    invoiceId:number) {
    this.id = id;
    this.productVersionId = productVersionId;
    this.invoiceItemId = invoiceItemId;
    this.channelId = channelId;
    this.link = link;
    this.token = token;
    this.expiration = expiration;
    this.isActive = isActive;
    this.createdOn = createdOn;
    this.updatedOn = updatedOn;
    this.soldOn = soldOn;
    this.invoiceId = invoiceId;
  }

  public static parse(data: any): TokenOtkViewModel {
    return new TokenOtkViewModel(
      data['id'],
      data['productVersionId'],
      data['invoiceItemId'],
      data['channelId'],
      data['link'],
      data['token'],
      data['expiration'],
      data['isActive'],
      data['createdOn'],
      data['updatedOn'],
      data['soldOn'],
      data['invoiceId'])
  }

}

export class TokenEsdFilter {
  regionId: number;
  accountId: number;
  clientId: number;
  versionId: number;

  constructor(regionId: number,
    accountId: number,
    clientId: number,
    versionId: number) {
    this.regionId = regionId;
    this.accountId = accountId;
    this.clientId = clientId;
    this.versionId = versionId;
  }
}

export class TokenGeneralViewModel extends SelectableModel {
  id: number;
  name: string;
  internalSku: string;
  purchaseSku: string;
  status: string;
  token: string;
  link: string;
  type: string;

  createdOn: Date;
  updateOn: Date;
  expiration: Date;

  error: string;

  channel: string;
  storeId: string;
  productVersionId: number;
  invoiceId: number;

  clientTransactionId: string;
  resellerId: string;

  public static parse(data: any): TokenGeneralViewModel {
    return new TokenGeneralViewModel(
      data['id'],
      data['name'],
      data['internalSku'],
      data['purchaseSku'],
      data['status'],
      data['token'],
      data['link'],
      data['type'],
      data['createdOn'],
      data['updateOn'],
      data['expiration'],
      data['error'],
      data['channel'],
      data['storeId'],
      data['productVersionId'],
      data['invoiceId'],
      data['clientTransactionId'],
      data['resellerId']);
  }

  constructor(id: number,
    name: string,
    internalSku: string,
    purchaseSku: string,
    status: string,
    token: string,
    link: string,
    type: string,
    createdOn: Date,
    updateOn: Date,
    expiration: Date,
    error: string,
    channel: string,
    storeId: string,
    productVersionId: number,
    invoiceId: number,
    clientTransactionId: string,
    resellerId: string) {
    super();
    this.id = id;
    this.name = name;
    this.internalSku = internalSku;
    this.purchaseSku = purchaseSku;
    this.status = status;
    this.token = token;
    this.link = link;
    this.type = type;
    this.createdOn = createdOn;
    this.updateOn = updateOn;
    this.expiration = expiration;
    this.error = error;
    this.channel = channel;
    this.storeId = storeId;
    this.productVersionId = productVersionId;
    this.invoiceId = invoiceId;
    this.clientTransactionId = clientTransactionId;
    this.resellerId = resellerId;
  }
}
