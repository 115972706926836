import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MarketingService } from '../../../../services/marketing.service';
import { BannerModel } from '../../../../models/marketing/banner.model';
import { ModalTransferModel } from '../../../../models/modal-transfer.model';
import { BannerTableModalComponent } from '../../../modals';
import { CommonService, InternationalizationService } from '../../../../services';
import {ClrDatagridSortOrder} from '@clr/angular';

@Component({
  selector: 'app-side-banner-table',
  templateUrl: './side-banner-table.component.html',
  styleUrls: ['./side-banner-table.component.scss']
})
export class SideBannerTableComponent implements OnInit {

  tableState = 0;
  selectCount = 0;
  selectedBanner: BannerModel;

  data: Array<BannerModel> = [];
  dataFiltered: Array<BannerModel> = [];

  openBannerTableModal: boolean;
  modalReference: ModalTransferModel;
  ascSort = ClrDatagridSortOrder.ASC;
  isLoading:boolean = false;

  constructor(private readonly srv: MarketingService,
    private readonly i18Svc: InternationalizationService,private readonly cdref: ChangeDetectorRef,
    public common: CommonService) {
    this.i18Svc.initialize();
  }

  ngOnInit() {
    this.get(false);
  }

  ngDoCheck() {
    this.reloadLbl();
}
ngAfterViewInit(){
    this.reloadLbl();
}
reloadLbl(){
       
    this.cdref.detectChanges();
}

  public refresh() {
    this.selectedBanner = null;
    this.get(true);
  }

    public get(force: boolean) {
    this.tableState = 1;
        this.srv.getBanner(1, force)
      .subscribe(data => this.onGetData(data));
  }

  private onGetData(data) {
    if (data['result']) {
      this.data = [];
      this.dataFiltered = [];
      data['result'].forEach(x => {
        this.data.push(BannerModel.parse(x, 1));
        this.dataFiltered.push(BannerModel.parse(x, 1));
      });
      this.tableState = 2;
    } else {
      this.tableState = 4;
    }
  }

  bannerAdd() {
    this.openBannerTableModal = true;
    this.modalReference = new ModalTransferModel('newSideBanner', BannerTableModalComponent, null, null, null);
  }

  bannerEdit() {
    if (this.selectedBanner?.id) {
      this.openBannerTableModal = true;
      this.modalReference = new ModalTransferModel('Edit', BannerTableModalComponent, this.selectedBanner, null, null);
    }
  }

  onBannerTableModalClose(refresh: boolean): void {
    this.openBannerTableModal = false;
    if (refresh) {
      this.refresh();
    }
  }

  bannerDisable(): void {
    this.isLoading = true;
    if (this.selectedBanner) {
      this.srv.disableBanner(this.selectedBanner.id).subscribe(data => {
        if (data.statusCode === 200) {
          this.isLoading = false;
          this.common.showSuccess(this.i18Svc.getTranslateVal('marketing_disable_info'));
          this.refresh();
        } else {
          this.isLoading = false;
          this.common.showError(`${this.i18Svc.getTranslateVal('error_text')}: ${data.errorMessage}`);
        }
      });
    }
  }

  filter(filterValue: string) {
    filterValue = filterValue?.toUpperCase();
    this.dataFiltered = this.data.filter(x =>
      x.versionId?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.redirectUrl?.toUpperCase().indexOf(filterValue) > -1);
  }

}
