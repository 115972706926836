
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyModel } from '../../../../models/billing/currency.model';
import { ModalTransferModel } from '../../../../models/modal-transfer.model';
import swal from 'sweetalert2';
import { AuthService, InternationalizationService, ConfigurationService } from '../../../../services';

@Component({
    selector: 'app-currency-modal',
    templateUrl: './currency-modal.component.html',
    styleUrls: ['./currency-modal.component.scss']
})
/** CurrencyModal component*/
export class CurrencyModalComponent implements OnInit {
    @Input() reference: ModalTransferModel;

    model: CurrencyModel;

    editMode: boolean;
    isNew: boolean;
    title: string;
    canRemoveCurrency = false;
    loading = false;

    @Input() set open(open: boolean) {
        this._open = open;
    }
    get open(): boolean {
        return this._open;
    }
    private _open: boolean;

    @Output() modalClose = new EventEmitter();

    constructor(public activeModal: NgbActiveModal, public srv: ConfigurationService, private readonly auth: AuthService,
        private readonly i18Svc: InternationalizationService) {
        this.i18Svc.initialize();
    }
    canView = this.auth.canViewCurrency;
    canEdit = this.auth.canEditCurrency;
    ngOnInit() {
        this.initMode();
    }

    initMode() {
        // Set Mode
        this.isNew = !this.reference?.object;
        this.editMode = this.isNew;

        // Set Model
        if (!this.isNew) {
            this.model = this.reference.object;
        } else {
            this.model = CurrencyModel.create();
            this.reference.object = this.model;
        }

        // Set Title
        if (this.isNew) {
            this.title = 'Add Currency';
        } else {
            this.title = 'Currency: ' + this.model.name;
        }
    }

    handleError(err) {
        console.log(' Error: ' + err);
        this.loading = false;
        swal(' Ops...', ' Something went wrong', 'error');
    }

    validModel(): boolean {
        return true;
    }
    cancel() {
        this.editMode = false;
    }
    goEditMode() {
        this.editMode = true;
    }

    save() {
        if (this.validModel()) {
            this.loading = true;
            if (this.isNew) {
                this.srv.currencyCreate(this.model)
                    .subscribe(data => this.onResponse(data),
                        err => this.handleError(err));
            } else {
                this.srv.currencyUpdate(this.model)
                    .subscribe(data => this.onResponse(data),
                        err => this.handleError(err));
            }
        }
    }
    onResponse(data) {
        if (!this.reference) {
            this.reference = new ModalTransferModel('open', CurrencyModalComponent, null, null);
        }
        this.model = CurrencyModel.parse(data['result']);
        this.reference.object = this.model;
        this.initMode();
        this.cancel();
        this.closeModal(true);
        this.loading = false;
    }
    close() {
        this.reference.close();
        this.closeModal(false);
    }

    closeModal(refresh?: boolean): void {
        this.open = false;
        this.modalClose.emit(refresh);
    }

}
