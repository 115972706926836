

import { SelectableModel } from '../general/selectable.model';

export class ProductVersionModel extends SelectableModel {
  id: number;
  productId: number;
  channelId: number;
  internalSku: string;
  purchaseSku: string;
  cost: number;
  purchaseCurrencyId: number;
  purchaseCurrency: string;
  featuredImageId: number;
  featuredImageUrl: string;
  tagsCount: number;
  isActive: boolean;
  productName: string;
  sortingOrder: number;
  isOtk: boolean;
  stockLimit: number;
  validFrom: Date;
  validTo: Date;
  expireInMonths: number;
  family: string;
  posaBarcode: string;
  otkStock: number;
  isPhysical: boolean;
  dimensionsUnit: string;
  width: number;
  height: number;
  length: number;
  weightUnit: string;
  versionIdString:string;
  devicesLicensed: number;
  monthLicensed: number;
  weight: number;
  isCNETProduct:boolean;
  enableCnetLogos:boolean;
  enableCnetContent:boolean;
  enableCnetIcons:boolean;
  enableCnetReview:boolean;

  public static parse(data: any): ProductVersionModel {
    return new ProductVersionModel(
      data['id'],
      data['productId'],
      data['internalSku'],
      data['purchaseSku'],
      data['cost'],
      data['purchaseCurrencyId'],
      data['purchaseCurrency'],
      data['featuredImageId'],
      data['tagsCount'],
      data['isActive'],
      data['productName'],
      data['channelId'],
      data['sortingOrder'],
      data['validFrom'],
      data['validTo'],
      data['isOtk'],
      data['featuredImageUrl'],
      data['expireInMonths'],
      data['family'],
      data['posaBarcode'],
      data['stockLimit'],
      data['otkStock'],
      data['isPhysical'],
      data['dimensionsUnit'],
      data['width'],
      data['height'],
      data['length'],
      data['weightUnit'],
      data['versionIdString'],
      data['devicesLicensed'],
      data['monthLicensed'],
      data['weight'],
      data['isCNETProduct'],
      data['enableCnetLogos'],
      data['enableCnetContent'],
      data['enableCnetIcons'],
      data['enableCnetReview']
      );
  }
  public static create(): ProductVersionModel {
    return new ProductVersionModel(0, 0, '', '', 0, null, null, null, 0, true, '', 0, 1, null, null, null, null, null, null, null, null, 0, false, '', 0, 0, 0, '','',null,null,0,false,false,false,false,false);
  }

  constructor(id: number,
    productId: number,
    internalSku: string,
    purchaseSku: string,
    cost: number,
    purchaseCurrencyId: number,
    purchaseCurrency: string,
    featuredImageId: number,
    tagsCount: number,
    isActive: boolean,
    productName: string,
    channelId: number,
    sortingOrder: number,
    validFrom: Date,
    validTo: Date,
    isOtk: boolean,
    featuredImageUrl: string,
    expireInMonths: number,
    family: string,
    posaBarcode: string,
    stockLimit: number,
    otkStock: number,
    isPhysical: boolean,
    dimensionsUnit: string,
    width: number,
    height: number,
    length: number,
    weightUnit: string,
    versionIdString: string,
    devicesLicensed: number,
    monthLicensed: number,
    weight: number,
    isCNETProduct:boolean,
    enableCnetLogos:boolean,
    enableCnetContent:boolean,
    enableCnetIcons:boolean,
    enableCnetReview:boolean,
    
    ) {
    super();
    this.id = id;
    this.productId = productId;
    this.internalSku = internalSku;
    this.purchaseSku = purchaseSku;
    this.cost = cost;
    this.purchaseCurrencyId = purchaseCurrencyId;
    this.purchaseCurrency = purchaseCurrency;
    this.featuredImageId = featuredImageId;
    this.tagsCount = tagsCount;
    this.productName = productName;
    this.isActive = isActive;
    this.channelId = channelId;
    this.sortingOrder = sortingOrder;
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.isOtk = isOtk;
    this.stockLimit = stockLimit;
    this.featuredImageUrl = featuredImageUrl;
    this.expireInMonths = expireInMonths;
    this.family = family;
    this.posaBarcode = posaBarcode;
    this.otkStock = otkStock;
    this.isPhysical = isPhysical;
    this.dimensionsUnit = dimensionsUnit;
    this.width = width;
    this.height = height;
    this.length = length;
    this.weightUnit = weightUnit;
    this.versionIdString = versionIdString;
    this.devicesLicensed = devicesLicensed || null;
    this.monthLicensed = monthLicensed || null;
    this.weight = weight;
    this.isCNETProduct = isCNETProduct;
    this.enableCnetLogos = enableCnetLogos;
    this.enableCnetContent = enableCnetContent;
    this.enableCnetIcons = enableCnetIcons;
    this.enableCnetReview = enableCnetReview;
  }

  isValid(): any {
    return true;
  }
}

export class ProductVersionVerboseModel {
  id: number;
  languageId: number;
  versionId: number;
  name: string;
  shortDesc: string;
  desc: string;

  public static parse(data: any): ProductVersionVerboseModel {
    return new ProductVersionVerboseModel(
      data['id'],
      data['languageId'],
      data['versionId'],
      data['name'],
      data['shortDesc'],
      data['desc']);
  }
  public static create(languageId: number, versionId: number): ProductVersionVerboseModel {
    return new ProductVersionVerboseModel(0, languageId, versionId, '', '', '');
  }

  constructor(id: number,
    languageId: number,
    versionId: number,
    name: string,
    shortDesc: string,
    desc: string) {
    this.id = id;
    this.languageId = languageId;
    this.versionId = versionId;
    this.name = name;
    this.shortDesc = shortDesc;
    this.desc = desc;
  }

  isValid(): any {
    return true;
  }
}

export class ProductVersionImageModel extends SelectableModel {
  id: number;
  languageId:number;
  versionId: number;
  priority: number;
  url: string;
  alt: string;
  isActive: boolean;
  public static parse(data: any): ProductVersionImageModel {
    return new ProductVersionImageModel(
      data['id'],
      data['languageId'],
      data['versionId'],
      data['priority'],
      data['url'],
      data['alt'],
      data['isActive']);
  }
  public static create(versionId: number): ProductVersionImageModel {
    return new ProductVersionImageModel(0,0, versionId, 1000, '', '', true);
  }

  constructor(
    id: number,
    languageId:number,
    versionId: number,
    priority: number,
    url: string,
    alt: string,
    isActive: boolean
    ) {
    super();
    this.id = id;
    this.languageId = languageId;
    this.versionId = versionId;
    this.priority = priority;
    this.url = url;
    this.alt = alt;
    this.isActive = isActive;
  }

  isValid(): any {
    return true;
  }
}

