import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { TokenGeneralViewModel } from '../../../models/tokens/token.model';
import { LicenseService } from '../../../services/license.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { AuthService, CommonService } from '../../../services';
import { SalesInvoiceListViewModel } from '../../../models/billing/sales-invoice.model';

@Component({
  selector: 'app-license-order-table',
  templateUrl: './license-order-table.component.html',
  styleUrls: ['./license-order-table.component.scss']
})

export class LicenseOrderTableComponent implements OnInit {
  @Input() name: string;
  @Input() reference: ModalTransferModel;

  data: Array<TokenGeneralViewModel> = [];
  dataFiltered: Array<TokenGeneralViewModel> = [];
  dataSelected: Array<TokenGeneralViewModel> = [];

  tableState = 0;
  tier:number;
  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;

  @Output() modalClose = new EventEmitter();

  showCompleteProvisionBtn: boolean;
  loadingProvision: boolean;

  @Input() selectedLicenseData: SalesInvoiceListViewModel;
  loadingCheckStatus: boolean;
  loadingReturnToken: boolean;


  constructor(private svc: LicenseService, public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private authsrv: AuthService,
    public common: CommonService) {
  }
  ngOnInit() {
    this.get();
    this.tier = this.authsrv.getTier();
  }

  ngDoCheck() {
    this.reloadLbl();
  }
  ngAfterViewInit() {
    this.reloadLbl();
  }
  reloadLbl() {

    this.cdref.detectChanges();
  }

  filter(filterValue: string) {
    filterValue = filterValue?.toUpperCase();
    this.dataFiltered = this.data.filter(x => x.channel?.toUpperCase().indexOf(filterValue) > -1
      || x.name?.toUpperCase().indexOf(filterValue) > -1
      || x.purchaseSku?.toUpperCase().indexOf(filterValue) > -1
      || x.internalSku?.toUpperCase().indexOf(filterValue) > -1
      || x.status?.toUpperCase().indexOf(filterValue) > -1
      || x.token?.toUpperCase().indexOf(filterValue) > -1
      || x.link?.toUpperCase().indexOf(filterValue) > -1
      || x.createdOn?.toString()?.toUpperCase().indexOf(filterValue) > -1
      || x.updateOn?.toString()?.toUpperCase().indexOf(filterValue) > -1);
  }
  refresh() {
    this.get();
  }
  checkStatus() {
    const apiList = [];
    this.dataSelected.forEach((res: TokenGeneralViewModel) => {
      apiList.push(this.svc.getCheckStatus(this.reference.object, res.token, res.productVersionId));
    });
    this.loadingCheckStatus = true;
    forkJoin(apiList).subscribe((results: any[]) => {
      this.loadingCheckStatus = false;
      results.forEach(res => {
        if (res.result) {
          this.toastr.success(`Token Status: ${res.result}`);
        } else {
          this.toastr.error(`Token Status: ${res.errorMessage}`);
        }
      });
    }, () => {
      this.loadingCheckStatus = false;
      this.toastr.error('error');
    });
  }
  get() {
    if (this.reference.object) {
      this.tableState = 1;
      this.data = [];
      this.dataFiltered = [];
      this.showCompleteProvisionBtn = false;
      this.svc.getLicenseInvoice(this.reference.object).subscribe(
        data => this.onGet(data),
        err => this.onGetError(err));
      this.dataFiltered = this.data;
    }
  }
  onGet(data) {
    if (data['result']) {
      this.data = [];
      this.dataFiltered = [];
      let tokenCount = 0;
      data['result'].forEach(x => {
        this.data.push(TokenGeneralViewModel.parse(x));
        if(x.token) {
          tokenCount++;
        }
      });
      this.showCompleteProvisionBtn = this.selectedLicenseData?.status !== 'D' && ((tokenCount !== this.data.length) || this.selectedLicenseData?.items > this.data.length);
      this.tableState = 2;
    } else {
      this.tableState = 4;
    }
    this.dataFiltered = this.data;
  }
  onGetError(err) {
    this.onError(err);
  }
  onError(msg) {
    swal({
      position: 'top-end',
      type: 'error',
      title: 'Oops...',
      text: msg,
      footer: '<a href>Why do I have this issue?</a>'
    });
  }
  close(): void {
    this.open = false;
    this.modalClose.emit(false);
  }
  reToken() {
    const apiList = [];
    this.dataSelected.forEach((res: TokenGeneralViewModel) => {
      apiList.push(this.svc.getReturnToken(this.reference.object, res.token, res.productVersionId))
    });
    this.loadingReturnToken = true;
    forkJoin(apiList).subscribe((results: any[]) => {
      this.loadingReturnToken = false;
      results.forEach(res => {
        if (res?.result) {
          this.toastr.success(`Token Status: ${res.result}`);
        } else {
          this.toastr.error(`Token Status: ${res.errorMessage}`);
        }
      });
    }, err => {
      this.onGetError(err);
      this.loadingReturnToken = false;
    });
  }

  provisionOrder() {
    this.loadingProvision = true;
    this.svc.provisionOrder(this.selectedLicenseData?.invoiceGuid).subscribe(data => {
      if(data?.result) {
        this.toastr.success(`Success.`);
        this.get();
      } else {
        this.toastr.error(`Error: ${data.errorMessage}`);
      }
    }, (err) => {
      this.loadingProvision = false;
      this.onGetError(err);
    });
  }

  get isLoading(): boolean {
    return this.tableState === 1 || this.loadingCheckStatus || this.loadingReturnToken || this.loadingProvision;
  }

}
