import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { ModalTransferModel } from '../../../../../models/modal-transfer.model';
import { ConfigurationService } from '../../../../../services/configuration.service';
import { TaxGroupModel } from '../../../../../models/billing/tax.model';
import { ResponseModel } from '../../../../../models/core/ResponseModel.model';
import { TaxModalComponent } from '../../tax-modal/tax-modal.component';
import { AuthService, CommonService, InternationalizationService } from '../../../../../services';

@Component({
    selector: 'app-taxes-table',
    templateUrl: './taxes-table.component.html',
    styleUrls: ['./taxes-table.component.scss']
})

export class TaxTableComponent implements OnInit {
    data: Array<TaxGroupModel> = [];
    dataFiltered: Array<TaxGroupModel> = [];
    selectedTax: TaxGroupModel;

    tableState = 0;
    selectCount = 0;

    openTaxModal: boolean;
    modalReference: ModalTransferModel;
     

    constructor(private confSvc: ConfigurationService, private auth: AuthService,
        private i18Svc: InternationalizationService,
        public common: CommonService) {
            this.i18Svc.initialize();
         }
    canView = this.auth.canViewTax;
    canEdit = this.auth.canEditTax;
    ngOnInit() {
        this.get();
    }
      
    filter(filterValue: string) {
        filterValue = filterValue?.toUpperCase();
        this.dataFiltered = this.data.filter(x => x.id?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.name?.toUpperCase().indexOf(filterValue) > -1
            || x.total?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.taxCount?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.useBy?.toString()?.toUpperCase().indexOf(filterValue) > -1);
    }
    refresh() {
        this.get();
    }


    edit(): void {
        if (this.canView) {
            let modalTitle = '';
            if (this.canEdit) {
                modalTitle = 'edit';
            }

            else {
                modalTitle = 'open';
            }
            this.openTaxModal = true;
            const selected = JSON.parse(JSON.stringify(this.selectedTax));
            this.modalReference = new ModalTransferModel(modalTitle, TaxModalComponent, selected, null, null);
        }
    }

    add(): void {
        if (this.canEdit) {
            this.openTaxModal = true;
            this.modalReference = ModalTransferModel.createMode(TaxModalComponent);
        }
    }

    onTaxModalClose(refresh): void {
        this.openTaxModal = false;
        if(refresh) {
            this.refresh();
        }
    }


    get() {
        if (this.canView) {
            this.tableState = 1;
            this.data = [];
            this.dataFiltered = [];
            this.selectCount = 0;
            this.confSvc.getTaxGroups()
                .subscribe(data => this.onGet(data), err => this.onGetError(err));
        }
    }
    onGet(response: ResponseModel<Array<any>>) {
        if (response.result) {
            this.data = [];
            this.dataFiltered = [];
            response.result.forEach(x => {
                this.data.push(TaxGroupModel.parse(x));
                this.dataFiltered.push(TaxGroupModel.parse(x));
            });
            this.tableState = 2;
        } else {
            this.tableState = 4;
        }
    }
    onGetError(err) {
        this.onError(err);
    }
    onError(msg) {
        swal({
            position: 'top-end',
            type: 'error',
            title: 'Oops...',
            text: msg,
            footer: '<a href>Why do I have this issue?</a>'
        });
    }
}
