import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ProductAlertModel } from '../../../models/product/product-alert.model';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InternationalizationService } from '../../../services';

@Component({
  selector: 'app-product-alert',
  templateUrl: './product-alert-modal.component.html',
  styleUrls: ['./product-alert-modal.component.scss']
})
export class ProductAlertModalComponent implements OnInit {
  @Input() reference: ModalTransferModel;

  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;

  title: string;
  model: ProductAlertModel;

  @Output() modalClose = new EventEmitter();

  constructor(public activeModal: NgbActiveModal, private i18Svc: InternationalizationService) {
    this.i18Svc.initialize();
  }

  ngOnInit() {
    this.title = 'Product Alert';
    this.model = new ProductAlertModel(0, 'test', 10, { daily: 10, weekly: 2, montly: 3 }, ['test@gmail.com']);
  }

  close() {
    this.open = false;
    this.modalClose.emit(false);
  }
}
