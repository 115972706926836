// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dbcLargeModal > .modal-dialog {
  max-width: 1000px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/main/dashboard/dashboard.component.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;AAAF","sourcesContent":["\n.dbcLargeModal > .modal-dialog {\n  max-width: 1000px\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
