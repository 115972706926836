import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import {
  LoginComponent,
  BillingSalesManagementComponent
} from './modules/main';
import { MarketingSliderComponent } from './modules/main/marketing/marketing-slider/marketing-slider.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { RetailRedeemComponent } from './modules/main/retail-redeem/retail-redeem.component';
import { MsalGuard } from '@azure/msal-angular';
import { LogoutComponent } from './modules/main/security/logout/logout.component';

const isIframe = window !== window.parent && !window.opener;

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  //{ path: 'resetPassword', component: ResetPasswordComponent, data: { title: 'Reset Password' } },
  { path: 'redeemPortal', component: RetailRedeemComponent, data: { title: 'Redeem Portal' } },
  
  {
    path: 'dashboard', loadChildren: () => import('./modules/main/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [MsalGuard],
  },
  // User
  // {
  //   path: 'user', loadChildren: () => import('./modules/main/user/user.module').then(m => m.UserModule),
  //   canActivate: [MsalGuard],
  // },

  // Sales
  {
    path: 'salesBillingManagement', component: BillingSalesManagementComponent,
    canActivate: [MsalGuard], data: { title: 'Sales Billing Management', Id: 1 }
  },

  // Settings
  {
    path: 'settings', loadChildren: () => import('./modules/main/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [MsalGuard],
  },

  // Products
  {
    path: 'productManagement', loadChildren: () => import('./modules/main/product/product.module').then(m => m.ProductModule),
    canActivate: [MsalGuard],
  },

  // search 
  {
    path: 'search', loadChildren: () => import('./modules/shared/shared.module').then(m => m.SharedModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'marketing', loadChildren: () => import('./modules/main/marketing/marketing.module').then(m => m.MarketingModule),
    canActivate: [MsalGuard],
  },

  { path: 'marketing-slider', component: MarketingSliderComponent, canActivate: [MsalGuard] },
  {
    path: 'report', loadChildren: () => import('./modules/main/report/report.module').then(m => m.ReportModule),
  },

  { path: 'error', component: ErrorPageComponent, canActivate: [MsalGuard] },
  { path: 'logout', component: LogoutComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled', useHash: false })],
  exports: [RouterModule],
  declarations: []
})


export class AppRoutingModule { }
