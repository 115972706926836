import { ListItemModel } from '../list-item.model';

export class UserDetailModel {
    FirstName: string;
    LastName: string;
    Username: string;
    Email: string;
    Phone: string;
    TwoFactorEnabled: boolean;
    EmailConfirmed: boolean;
    PhoneNumberConfirmed: boolean;
    languageOption: string;
    languageId: number;
    accountId: number;

    tier: number;

    Regions: Array<ListItemModel>;
    Accounts: Array<ListItemModel>;
    Roles: Array<string>;
    RoleGroup: Array<string>;

    private IsSelected: boolean;
    SendEmailConfirmation:boolean;

    constructor(Username: string,
        FirstName: string,
        LastName: string,
        Email: string,
        Phone: string,
        TwoFactorEnabled: boolean,
        EmailConfirmed: boolean,
        PhoneNumberConfirmed: boolean,
        tier: number,
        Regions: Array<ListItemModel>,
        Accounts: Array<ListItemModel>,
        Roles: Array<string>,
        RoleGroup: Array<string>,
        languageId: number,
        accountId: number,
        SendEmailConfirmation:boolean) {
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.Username = Username;
        this.Email = Email;
        this.Phone = Phone;
        this.TwoFactorEnabled = TwoFactorEnabled;
        this.EmailConfirmed = EmailConfirmed;
        this.PhoneNumberConfirmed = PhoneNumberConfirmed;
        this.tier = tier;
        this.Regions = Regions;
        this.Accounts = Accounts;
        this.Roles = Roles;
        this.languageId = languageId;
        this.accountId = accountId;
        this.RoleGroup = RoleGroup;
        this.SendEmailConfirmation = SendEmailConfirmation;
    }

    static Parse(x: any): UserDetailModel {
        return new UserDetailModel(
            x['firstName'],
            x['lastName'],
            x['username'],
            x['email'],
            x['phone'],
            x['twoFactorEnabled'],
            x['emailConfirmed'],
            x['phoneNumberConfirmed'],
            x['tier'],
            x['regions'],
            x['accounts'],
            x['roles'],
            x['roleGroup'],
            x['languageId'],
            x['accountId'],
            x['SendEmailConfirmation']);
    }

    static Create(): UserDetailModel {
        return new UserDetailModel('', '', '', '', '', false, false, false, null, null, null, null, null, null, null,false);
    }

    getSelected(): boolean {
        return this.IsSelected;
    }
    toggleSelected() {
        this.IsSelected = !this.IsSelected;
    }
}
