// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dbk-modal {
  position: relative;
  top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/progress-loader/progress-loader.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,SAAA;AACJ","sourcesContent":[".dbk-modal {\n    position: relative;\n    top: 1rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
