import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { ChannelModel } from '../../../models/product/channel.model';
import { ChannelService } from '../../../services/channel.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService, InternationalizationService } from '../../../services';

@Component({
  selector: 'app-channel-modal',
  templateUrl: './channel-modal.component.html',
  styleUrls: ['./channel-modal.component.scss']
})
/** channel-modal component*/
export class ChannelModalComponent implements OnInit {

  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;

  @Input() reference: ModalTransferModel;

  model: ChannelModel;
  providers: Array<string>;
  filterProviderList: Array<string>;
  engines: Array<string>;
  types: Array<string>;
  isNew: boolean;
  title: string;

  @Output() modalClose = new EventEmitter();

  loading: boolean;

  constructor(private readonly srv: ChannelService, private readonly toastr: ToastrService,
    private readonly i18Svc: InternationalizationService, public common: CommonService) {
    this.i18Svc.initialize();
  }

  ngOnInit() {
    this.initMode();

    this.srv.getEngines().subscribe(data => this.onGetEngines(data), err => this.handleError(err));
    this.srv.getProviders().subscribe(data => this.onGetProviders(data), err => this.handleError(err));
    this.srv.getTypes().subscribe(data => this.onGetTypes(data), err => this.handleError(err));
  }
  initMode() {
    // Set Mode
    this.isNew = !this.reference?.object;
    // Set Model
    if (!this.isNew) {
      this.model = this.reference.object;
    } else {
      this.model = new ChannelModel(0, '', '', '', '', '', 0, true, '');
    }

    // Set Title
    if (this.isNew) {
      this.title = 'Create Channel';
    } else {
      this.title = 'Channel ' + this.model.name;
    }
  }

  handleError(err) {
    this.loading = false;
    console.log(err);
  }
  onGetProviders(data) {
    this.providers = data['result'];
    this.filterProviderList = data['result'];
  }
  onGetEngines(data) {
    this.engines = data['result'];
  }
  onGetTypes(data) {
    this.types = data['result'];
  }
  validModel(): boolean {
    return true;
  }
  save() {
    if (this.validModel()) {
      this.loading = true;
      if (this.isNew) {
        this.srv.create(this.model)
          .subscribe(data => this.onResponse(data),
            err => this.handleError(err));
      } else {
        this.srv.update(this.model)
          .subscribe(data => this.onResponse(data),
            err => this.handleError(err));
      }
    }
  }
  onResponse(data) {
    this.loading = false;
    if (data['result']) {
      this.model = data['result'];
      this.initMode();
    }
    this.showMessage(data['result']);
  }

  showMessage(success): void {
    const mode = this.isNew ? 'create' : 'update';
    if (success) {
      this.toastr.success('your channel have been ' + mode + 'd: ' + this.model.name);
      this.closeModal(true);
    } else {
      this.toastr.error('failed to ' + mode + ' channel: ' + this.model.name);
    }
  }

  filterproviders(val: string) {
    if (val) {
      const filterValue = val.toLowerCase();
      return this.providers.filter(state => state.toLowerCase().startsWith(filterValue));
    }
    return this.providers;
  }

  close() {
    this.reference.close();
    this.closeModal();
  }

  closeModal(refresh?: boolean): void {
    this.open = false;
    this.modalClose.emit(refresh);
  }

}
