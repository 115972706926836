import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef  } from '@angular/core';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { UserViewModel } from '../../../models/user/user-view.model';
import { CommonService, ExcelService, InternationalizationService, UserService } from '../../../services';
import { UserImportEditComponent } from '../user-import-edit/user-import-edit.component';

@Component({
  selector: 'app-user-import',
  templateUrl: './user-import.component.html',
  styleUrls: ['./user-import.component.scss']
})
export class UserImportComponent implements OnInit {

  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;

  @Output() modalClose = new EventEmitter();

  users: UserViewModel[] = [];
  filterUsers: UserViewModel[] = [];
  selectedUsers: UserViewModel[] = [];
  userTableState: number;
  @Input() tier: number;

  openUserEditModal: boolean;
  modalReference: ModalTransferModel;
   


  constructor(private usrService: UserService,
    private excelSvc: ExcelService,private i18Svc: InternationalizationService,private cdref: ChangeDetectorRef,
    public common: CommonService) { 
      this.i18Svc.initialize();
    }

  ngOnInit(): void {
    this.getImportUsers();
  }
  ngDoCheck() {
    this.reloadLbl();
}
ngAfterViewInit(){
    this.reloadLbl();
}
reloadLbl(){
       
    this.cdref.detectChanges();
}

  getImportUsers() {
    this.userTableState = 1;
    this.usrService.getImportUsers(false).subscribe(
      data => this.onGetImportUsers(data),
      err => this.onGetImportUserError(err)
    );
  }

  onGetImportUsers(data) {
    this.selectedUsers = [];
    if (data['result']) {
      this.users = (data['result'] || []).map(x => {
        const parseUser: UserViewModel = UserViewModel.Parse(x);
        parseUser.tier = this.tier;
        return parseUser;
      });
      if (this.users.length > 0) {
        this.userTableState = 2; // Table
      } else {
        this.userTableState = 3; // No Data
      }
      this.filterUsers = JSON.parse(JSON.stringify(this.users));
    } else {
      this.userTableState = 4; // Error
    }
  }

  onGetImportUserError(err) {
    this.userTableState = 4; // Error
  }

  close() {
    this.open = false;
    this.modalClose.emit();
  }

  onUsersExport(): void {
    if (this.filterUsers.length > 0) {
      const exportData: any[] = this.selectedUsers?.length > 0 ? this.selectedUsers : this.filterUsers;
      exportData?.forEach(x => {
        x.Regions = x.Regions?.join(",");
        x.Accounts = x.Accounts?.join(",");
        x.Roles = x.Roles?.join(",");
      });
      this.excelSvc.exportAsExcelFile(exportData, 'ImportUsers');
    }
  }

  usersFilter(filterValue: string) {
    if (filterValue) {
      filterValue = filterValue.trim().toLowerCase();
      this.filterUsers = this.users.filter(x => {
        return x.Username?.toLowerCase().indexOf(filterValue) >= 0
          || x.Email?.toLowerCase().indexOf(filterValue) >= 0
          || x.PhoneNumber?.toLowerCase().indexOf(filterValue) >= 0
      });
    }
    else {
      this.filterUsers = this.users;
    }
  }

  userImport() {
    if (this.filterUsers && this.selectedUsers?.length > 0) {
        this.openUserEditModal = true;
        const users = JSON.parse(JSON.stringify(this.selectedUsers));
        this.modalReference = new ModalTransferModel('Edit', UserImportEditComponent, users, null, null);
    }
}

onUserModalCloseModalClose(refresh?: boolean): void {
    this.openUserEditModal = false;
    if(refresh) {
      this.modalClose.emit(refresh);
    }
}

}
