// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.hiddenRow {
  padding: 0 !important;
}
/*DBC Override*/
.mat-input-element .mat-form-field-label {
  font-size: 16px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":";AACA;EACE,qBAAqB;AACvB;AACA,eAAe;AACf;EACE,0BAA0B;AAC5B","sourcesContent":["\n.hiddenRow {\n  padding: 0 !important;\n}\n/*DBC Override*/\n.mat-input-element .mat-form-field-label {\n  font-size: 16px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
