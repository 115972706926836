// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .dbcLargeModal .modal-dialog {
  max-width: 1000px;
}

::ng-deep .cdk-overlay-container {
  z-index: 1200 !important;
}

::ng-deep .mat-form-field {
  font-size: 16px !important;
}

table.dataTable thead .sorting:after {
  content: "\\f0dc" !important;
  left: 80px;
  position: relative;
}

table.dataTable thead .sorting_asc:after {
  content: "\\f0dd" !important;
  left: 80px;
  position: relative;
}

table.dataTable thead .sorting_desc:after {
  content: "\\f0de" !important;
  left: 80px;
  position: relative;
}

.btn {
  margin-right: 6px;
}

.itemActive {
  border-left: 5px solid #5cb85c;
}

.itemWarning {
  border-left: 5px solid #f0ad4e;
}

.itemInactive {
  border-left: 5px solid #d9534f;
}

.itemGray {
  border-left: 5px solid #dcdcdc;
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.dbc-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.dbc-search-header {
  min-height: 64px;
  padding: 8px 24px 0;
}

.mat-column-name {
  margin-right: 25px;
}

.hide-overflow {
  height: 0 !important;
  overflow: hidden;
}

.show-overflow {
  height: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/main/product/product-admin/product-admin.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAEA;EACI,0BAAA;AACJ;;AAEA;EACI,2BAAA;EACA,UAAA;EACA,kBAAA;AACJ;;AAEA;EACI,2BAAA;EACA,UAAA;EACA,kBAAA;AACJ;;AAEA;EACI,2BAAA;EACA,UAAA;EACA,kBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,oBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ","sourcesContent":["::ng-deep .dbcLargeModal .modal-dialog {\n    max-width: 1000px;\n}\n\n::ng-deep .cdk-overlay-container {\n    z-index: 1200 !important;\n}\n\n::ng-deep .mat-form-field {\n    font-size: 16px !important;\n}\n\ntable.dataTable thead .sorting:after {\n    content: '\\f0dc' !important;\n    left: 80px;\n    position: relative;\n}\n\ntable.dataTable thead .sorting_asc:after {\n    content: '\\f0dd' !important;\n    left: 80px;\n    position: relative;\n}\n\ntable.dataTable thead .sorting_desc:after {\n    content: '\\f0de' !important;\n    left: 80px;\n    position: relative;\n}\n\n.btn {\n    margin-right: 6px;\n}\n\n.itemActive {\n    border-left: 5px solid #5cb85c;\n}\n\n.itemWarning {\n    border-left: 5px solid #f0ad4e;\n}\n\n.itemInactive {\n    border-left: 5px solid #d9534f;\n}\n\n.itemGray {\n    border-left: 5px solid #dcdcdc;\n}\n\n.hidden {\n    visibility: hidden;\n}\n\n.visible {\n    visibility: visible;\n}\n\n.dbc-container {\n    display: flex;\n    flex-direction: column;\n    min-width: 300px;\n}\n\n.dbc-search-header {\n    min-height: 64px;\n    padding: 8px 24px 0;\n}\n\n.mat-column-name {\n    margin-right: 25px;\n}\n\n.hide-overflow {\n    height: 0!important;\n    overflow: hidden;\n}\n\n.show-overflow {\n    height: auto!important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
