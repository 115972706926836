import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreService } from './core.service';
import { ResponseModel } from '../models/core/ResponseModel.model';

@Injectable()
export class IdentityRoleService {
  constructor(private srv: CoreService) {
  }
  public getRoleGroupsForTier(tier: number): Observable<ResponseModel<any>> {
    return this.srv.get(`${this.srv.identityUrl}/role/getRoleGroupsForTier/${tier}`);
  }

    public updateUserRoles(id: string, roles: Array<string>): Observable<ResponseModel<any>> {
        let query = `${this.srv.identityUrl}/role/updateUserRoles?user=${id}`;
        let rolesQuery = '';
        roles.forEach(x => rolesQuery = rolesQuery + `&roles=${x}`)
        return this.srv.post(query + rolesQuery, {});
  }
}
