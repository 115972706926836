import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BillingSalesService } from './billing-sales.service';
import { InternationalizationService } from './internationalization.service';
import Swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class EmailService {

  constructor(private i18Svc: InternationalizationService,
    private toastr: ToastrService,private srv: BillingSalesService) {

  }

  sendMail(opt:string,dataSelected:any){
    Swal({
      title: this.i18Svc.getTranslateVal(opt==='SL'?'lic_email_title_text':'lic_email_title_text_order'),
      input: 'text',
      showCancelButton: true,
      confirmButtonText: this.i18Svc.getTranslateVal('lic_email_send_btn_text'),
      cancelButtonText: this.i18Svc.getTranslateVal('lic_email_cancel_btn_text'),
      showLoaderOnConfirm: true,
      focusConfirm:true,
      preConfirm: (email) => {
          return this.srv.sendEmailApi(dataSelected, email, opt)
              .toPromise().then(response => {
                  if (!response.result) {
                      throw new Error(response.errorMessage);
                  }
                  return true;
              })
              .catch(error => {
                  Swal.showValidationMessage(
                      this.i18Svc.getTranslateVal('lic_email_error_text') + `: ${error}`
                      );
              });

      },
      allowOutsideClick: () => !Swal.isLoading()
  }).then((result) => {
      if (result && !result.dismiss) {
          this.toastr.info(this.i18Svc.getTranslateVal('lic_email_sent_notification_text'));

      }
  });
  
  }

  sendPW(){
    
    return Swal({
        title: this.i18Svc.getTranslateVal('send_pw_text'),
        showCancelButton: true,
        cancelButtonText: this.i18Svc.getTranslateVal('btn_deny_send_pw'),
        confirmButtonText: this.i18Svc.getTranslateVal('btn_accept_send_pw'),
      })
  };


}
