import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonService } from '../services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private common: CommonService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add instance id if available
    let instanceId = request.headers.get('instanceId');
    if(!instanceId && this.common.tenantId) {
      instanceId = this.common.tenantId;
    }
    if (instanceId) {
      request = request.clone({
        setHeaders: {
          instanceID: instanceId
        }
      });
    }

    return next.handle(request);
  }
}
