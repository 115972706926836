import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/core/ResponseModel.model';
import { CoreService } from './core.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {

  constructor(private srv: CoreService) { }
  public getAccounts(query: string): Observable<ResponseModel<any>> {
    return this.srv.get(`${this.srv.baseUrl}/Account/GetAccountsBySearchQuery/${query}` );
  }
  public getInvoicesBySearchQuery(query: string): Observable<ResponseModel<any>> {
    return this.srv.get(`${this.srv.baseUrl}/Billing/Sales/GetInvoicesBySearchQuery/${query}` );
  }
  public getUsersBySearchQuery(query: string): Observable<ResponseModel<any>> {
    return this.srv.get(`${this.srv.baseUrl}/User/GetUsersBySearchQuery/${query}` );
  }
}
