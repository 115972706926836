import { Injectable } from '@angular/core';
import { ClrCommonStringsService } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { French } from '../models/clrInternazionalitation/clrInt.model';
import { English } from '../models/clrInternazionalitation/clrIntEn.model';

@Injectable({
  providedIn: 'root'
})
export class InternationalizationService {

  constructor(private translate: TranslateService,private commonStrings: ClrCommonStringsService) {
    
   }
  

  initialize() {
    this.translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang('en');
    // Get Language from localstorage or as a parameter in function
    const currentLang = localStorage.getItem('current_lang') ? localStorage.getItem('current_lang') : 'en';
    this.translate.use(currentLang);
    if (localStorage.getItem('current_lang') !== 'en') {
      this.commonStrings.localize(French);
    }else{
      this.commonStrings.localize(English);
    }
    
  }

  getLanguageID() {
    const currentLang = localStorage.getItem('current_lang') ? localStorage.getItem('current_lang') : 'en';
    if (currentLang === 'fr') {
      return 2;
    }
    return 1;
  }

  getLanguageName() {
    const currentLang = localStorage.getItem('current_lang') ? localStorage.getItem('current_lang') : 'en';
    if (currentLang === 'fr') {
      return 'Français (FR)';
    }
    return 'English (EN)';
  }

  getTranslateVal(key) {
    return this.translate.instant(key);
  }

}
