import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/core/ResponseModel.model';

@Injectable()
export class ReportService {

    constructor(private srv: CoreService) { }

    public getReports(reportUrl: string, accountId: number, regionId: number, startDate: Date, endDate: Date): string {

        let query = `${this.srv.baseUrl}/report/${reportUrl}`;
        let notFirst = false;
        if (regionId) {
            notFirst = true;
            query = query + `?regionId=${regionId}`;
        }
        if (accountId) {
            query = query + `&accountId=${accountId}`;
        }
        if (startDate) {
            if (notFirst) {
                query = query + `&start=${(startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' + startDate.getFullYear()}`;
            } else {
                query = query + `?start=${(startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' + startDate.getFullYear()}`;
            }
            notFirst = true;
        }
        if (endDate) {
            if (notFirst) {
                query = query + `&end=${(endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' + endDate.getFullYear()}`;
            } else {
                query = query + `?end=${(endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' + endDate.getFullYear()}`;
            }
        }
        return query;
    }
    public getReportsEsd(accountId: number, regionId: number, startDate: Date, endDate: Date): Observable<ResponseModel<any>> {
        let query = this.getReports('get/1', accountId, regionId, startDate, endDate);
        return this.srv.get(query);
    }

    public getReportsOtk(accountId: number, regionId: number, startDate: Date, endDate: Date): Observable<ResponseModel<any>> {
        let query = this.getReports('getOtk', accountId, regionId, startDate, endDate);
        return this.srv.get(query);
    }

    public getReportsAll(accountId: number, regionId: number, startDate: Date, endDate: Date): Observable<ResponseModel<any>> {
        let query = this.getReports('getGeneral', accountId, regionId, startDate, endDate);
        return this.srv.get(query);
    }



}
