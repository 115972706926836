

export class PriceListModel {
  Id: number;
  Name: string;
  ValidFrom: Date;
  ValidTo: Date;
  Currency: string;
  CurrencyId: number;
  Priority: number;
  IsActive: boolean;
  Products: number;
  UsedBy: number;
  Notes: string;

  private _Selected: boolean;
  private _Loading: boolean;


  constructor(Id: number,
    Name: string,
    ValidFrom: Date,
    ValidTo: Date,
    Currency: string,
    CurrencyId: number,
    Priority: number,
    Products: number,
    UsedBy: number,
    IsActive: boolean,
    Notes: string) {
    this.Id = Id;
    this.Name = Name;
    this.ValidFrom = ValidFrom;
    this.ValidTo = ValidTo;
    this.Currency = Currency;
    this.CurrencyId = CurrencyId;
    this.Priority = Priority;
    this.Products = Products;
    this.UsedBy = UsedBy;
    this.IsActive = IsActive;
    this.Notes = Notes;
    this._Selected = false;
  }

  public static Parse(x: any): PriceListModel {
    return new PriceListModel(
      x['id'],
      x['name'],
      x['validFrom'],
      x['validTo'],
      x['currency'],
      x['currencyId'],
      x['priority'],
      x['products'],
      x['usedBy'],
      x['isActive'],
      x['notes']);
  }
  public static Cast(x: any): PriceListModel {
    return new PriceListModel(
      x['Id'],
      x['Name'],
      x['ValidFrom'],
      x['ValidTo'],
      x['Currency'],
      x['CurrencyId'],
      x['Priority'],
      x['Products'],
      x['UsedBy'],
      x['IsActive'],
      x['Notes']);
  }
  public static Create(): PriceListModel {
    return new PriceListModel(0, '', null, null, '', null, 10000, 0, 0, true, '');
  }

  SelectedToggle() {
    this._Selected = !this._Selected;
  }
  SetLoading(value: boolean) {
    this._Loading = value;
  }
  IsLoading(): boolean {
    return this._Loading;
  }
  IsSelected() {
    return this._Selected;
  }
  IsValid() {
    return !(!this.Name || !this.CurrencyId || !this.Priority);
  }
  IsExpire() {
    if (!this.ValidTo) {
      return false;
    }
    if (typeof this.ValidTo === 'string') {
      return Date.parse(this.ValidTo) > new Date().getTime();
    }
    return this.ValidTo.getTime() > new Date().getTime();
  }
  IsFuture() {
    if (!this.ValidFrom) {
      return false;
    }
    if (typeof this.ValidFrom === 'string') {
      return Date.parse(this.ValidFrom) > new Date().getTime();
    }
    return this.ValidFrom.getTime() > new Date().getTime();
  }
}
export class PriceListItemModel {
  percent?:number;
  Id: number;
  PriceListId: number;
  ProductVersionId: number;
  ProductAddOnId: number;
  Name: string;
  Sku: string;
  VersionName: string;
  PriceCode: string;
  CostCode: string;
  Price: number;
  DiscountPrice: number;
  IsActive: boolean;
  PriceSheetName: string;
  Cost: number;
  Margin:number;


  // private _Margin: number;
  // get Margin(): number {
  //     return this._Margin;
  // }
  // set Margin(margin: number) {
  //   if (this.Cost && margin) {
  //     this.Price = this.Cost / Math.abs(1 - (margin / 100));
  //     this._Margin = margin;
  //   }
  // }

  private _Selected: boolean;

  constructor(Id: number,
    PriceListId: number,
    ProductVersionId: number,
    ProductAddOnId: number,
    Name: string,
    Sku: string,
    VersionName: string,
    PriceCode: string,
    CostCode: string,
    Price: number,
    DiscountPrice: number,
    IsActive: boolean,
    Cost: number,
    // Margin: number,
    ) {
    this.Id = Id;
    this.Name = Name;
    this.PriceListId = PriceListId;
    this.ProductVersionId = ProductVersionId;
    this.ProductAddOnId = ProductAddOnId;
    this.Sku = Sku;
    this.VersionName = VersionName;
    this.PriceCode = PriceCode;
    this.CostCode = CostCode;
    this.Price = Price;
    this.DiscountPrice = DiscountPrice;
    this.IsActive = IsActive;
    this.Cost = Cost;
    this.Margin = 0;
    this.PriceSheetName = '';
    console.log('mi margin', this.Margin);
  }

  static Parse(x: any): PriceListItemModel {
    return new PriceListItemModel(
      x['id'],
      x['priceListId'],
      x['productVersionId'],
      x['productAddOnId'],
      x['name'],
      x['sku'],
      x['versionName'],
      x['priceCode'],
      x['costCode'],
      x['price'],
      x['discountPrice'],
      x['isActive'],
      x['cost'],
      // x['margin'],
      );
  }

  SelectedToggle() {
    this._Selected = !this._Selected;
  }
  IsSelected() {
    return this._Selected;
  }
}

export class ProductFilterModel  {
  keywords: string;
  priceMin: number;
  priceMax: number;

  constructor() {
    this.keywords = '';
  }
}

export class ProductItemModel {
  id: number;
  productId: number;
  imageUrl: string;
  name: string;
  desc: string;
  sku: string;
  price: number;
  sellCurrencyCode: string;
  tags: Array<string>;
  regularPrice?: number;
  versionCount?: number;
  outOfStock?: boolean;
  devicesLicensed?: number;
  monthLicensed?: number;
  sortingOrder?: number;
}