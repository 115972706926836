// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.print-logo {
  position: relative;
  top: -20px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/modals/invoice-view-modal/invoice-view-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;AACJ","sourcesContent":[".print-logo {\n    position: relative;\n    top: -20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
