// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .clr-control-container {
  width: 100% !important;
}

::ng-deep .clr-textarea-wrapper {
  width: 100% !important;
}

::ng-deep .clr-form-control {
  width: 100% !important;
}

::ng-deep .clr-wizard-content {
  width: 100% !important;
}

.input-group-text {
  width: 120px;
  text-align: left;
}

.dbc-material-full-width {
  width: 100%;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 99999 !important;
}

::ng-deep .mat-select {
  font-size: 16px !important;
}

.clr-control-container {
  width: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/modals/client-details-modal/client-details-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,YAAA;EACA,gBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;;EAEI,yBAAA;AACJ;;AAEA;EACI,0BAAA;AACJ;;AAEA;EACI,sBAAA;AACJ","sourcesContent":["::ng-deep .clr-control-container {\n    width: 100% !important\n}\n\n::ng-deep .clr-textarea-wrapper {\n    width: 100% !important\n}\n\n::ng-deep .clr-form-control {\n    width: 100% !important\n}\n\n::ng-deep .clr-wizard-content {\n    width: 100% !important\n}\n\n.input-group-text {\n    width: 120px;\n    text-align: left;\n}\n\n.dbc-material-full-width {\n    width: 100%;\n}\n\n.cdk-global-overlay-wrapper,\n.cdk-overlay-container {\n    z-index: 99999 !important;\n}\n\n::ng-deep .mat-select {\n    font-size: 16px !important;\n}\n\n.clr-control-container {\n    width: 100% !important\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
