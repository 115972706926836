export class BannerModel {
  id: number;
  versionId: number;
  positionId: number;
  priority: number;
  imageUrl: string;
  redirectUrl: string;
  regionId: number;
  languageId: number;
  validFrom: Date;
  validTo: Date;
  isActive: boolean;

  constructor(id: number,
    versionId: number,
    positionId: number,
    priority: number,
    imageUrl: string,
    redirectUrl: string,
    regionId: number,
    languageId: number,
    validFrom: Date,
    validTo: Date,
    isActive: boolean) {
    this.id = id;
    this.versionId = versionId;
    this.positionId = positionId;
    this.priority = priority;
    this.imageUrl = imageUrl;
    this.redirectUrl = redirectUrl;
    this.languageId = languageId;
    this.validFrom = validFrom;
    this.validTo = validTo;
    this.isActive = isActive;
    this.regionId = regionId;
  }

  public static parse(data, positionId: number): BannerModel {
    return new BannerModel(
      data['id'],
      data['versionId'],
      positionId,
      data['priority'],
      data['imageUrl'],
      data['redirectUrl'],
      data['regionId'],
      data['languageId'],
      data['validFrom'],
      data['validTo'],
      data['isActive']);
  }
}
