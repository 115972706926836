import { SelectableModel } from "../general/selectable.model";

export class TaxModel extends SelectableModel {
  id: number;
  groupId: number;
  name: string;
  percentage: number;
  isActive: boolean;
  constructor(id: number,
    groupId: number,
    name: string,
    percentage: number,
    isActive: boolean) {
    super();
    this.id = id;
    this.name = name;
    this.groupId = groupId;
    this.percentage = percentage;
    this.isActive = isActive;
  }

  static parse(x: any): TaxModel {
    return new TaxModel(x['id'],
      x['groupId'],
      x['name'],
      x['percentage'],
      x['isActive']);
  }

  static create(groupId: number): TaxModel {
    return new TaxModel(0,
      groupId,
      "",
      0,
      true);
  }
}
export class TaxGroupModel extends SelectableModel{
   
  id: number;
  name: string;
  total: number;
  taxCount: number;
  useBy: number;
  isActive: boolean;
  taxes: Array<TaxModel>;

  constructor(id: number,
    name: string,
    total: number,
    taxCount: number,
    useBy: number,
    isActive: boolean,
    taxes: Array<any>) {
    super();
    this.id = id;
    this.name = name;
    this.total = total;
    this.taxCount = taxCount;
    this.useBy = useBy;
    this.isActive = isActive;
    if (taxes) {
      this.taxes = [];
      taxes.forEach(x => this.taxes.push(TaxModel.parse(x)))
    }
 
  }

  toString(): string {
    return `${this.name} (${this.total})`;
  }
  static parse(x: any): TaxGroupModel {
    return new TaxGroupModel(x['id'],
      x['name'],
      x['total'],
      x['taxCount'],
      x['useBy'],
      x['isActive'],
      x['taxes']);
  }

  static create(): TaxGroupModel {
    return new TaxGroupModel(0,
      "",
      0,
      0,
      0,
      true,
      null);
  }
}
