import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AccountService, InternationalizationService } from '../../../services';
import { ApiModel } from '../../../models/account.model';

@Component({
  selector: 'app-api-management',
  templateUrl: './api-management.component.html',
  styleUrls: ['./api-management.component.scss']
})
export class ApiManagementComponent implements OnInit {
  @Input() name: string;
  @Input() id: number;

  model: ApiModel;

  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;
  @Output() modalClose = new EventEmitter();

  loading: boolean;

  constructor(public srv: AccountService, private i18Svc: InternationalizationService) {
    this.i18Svc.initialize();
  }

  ngOnInit() {
    this.srv.getApi(this.id?.toString()).subscribe(data => this.onGet(data));
  }

  onGet(data) {
    this.loading = false;
    if (data.result) {
      this.model = ApiModel.parse(data.result);
    }
  }

  enable() {
    this.loading = true;
    this.srv.enableApi(this.model.id.toString()).subscribe(data => this.onGet(data), () => this.loading = false);
  }

  disable() {
    this.loading = true;
    this.srv.disableApi(this.model.id.toString()).subscribe(data => this.onGet(data), () => this.loading = false);
  }

  close() {
    this.open = false;
    this.modalClose.emit(false);
  }
}
