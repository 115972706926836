import { SelectableModel } from './general/selectable.model';

export class LanguageModel extends SelectableModel {
  id: number;
  name: string;
  code: string;
  flag: string;

  constructor(id: number,
    name: string,
    code: string,
    flag: string) {
    super();
    this.id = id;
    this.name = name;
    this.code = code;
    this.flag = flag;
  }

  public static parse(data: any): LanguageModel {
    return new LanguageModel(
      data['id'],
      data['name'],
      data['code'],
      data['flag']);
  }

  public static create(): LanguageModel {
    return new LanguageModel(0, '', '', '');
  }
}
