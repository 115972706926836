import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';
import { ProductModel } from '../models/product/product.model';
import { ListItemModel } from '../models/list-item.model';
import { ProductVersionModel, ProductVersionImageModel, ProductVersionVerboseModel } from '../models/product/product-version.model';
import { PriceListModel, PriceListItemModel, ProductItemModel } from '../models/product/price-list/price-list.model';
import { ResponseModel } from '../models/core/ResponseModel.model';
import { TokenOtkViewModel } from '../models/tokens/token.model';
import { ProductBundlerModel } from '../models/product/product-bundler.model';
import { TagModel } from '../models/product/tag-model';

@Injectable()
export class ProductService {


    constructor(private srv: CoreService) {
    }

    public getTypes(): Observable<ResponseModel<any>> {
        return this.srv.get(this.srv.baseUrl + '/channel/getTypesAsOptions/');
    }
    public getChannels(): Observable<ResponseModel<any>> {
        return this.srv.get(this.srv.baseUrl + '/channel/getChannelAsOptions/');
    }
    public get(_id: number): Observable<ResponseModel<any>> {
        return this.srv.get(this.srv.baseUrl + '/product/get/' + _id);
    }
    public getVersionsAsOptions(productId: number) {
        return this.srv.get(this.srv.baseUrl + '/product/GetVersionsAsOptions/' + productId);
    }
    public getProductByChannel(_id: number, tenantId?: string): Observable<ResponseModel<any>> {
        return this.srv.get(this.srv.baseUrl + '/product/getProductByChannel/' + _id, tenantId);
    }
    public getAll(): Observable<ResponseModel<any>> {
        return this.srv.get(this.srv.baseUrl + '/product/getAll/');
    }
    public getProductsAsOptions(): Observable<ResponseModel<any>> {
        return this.srv.get(this.srv.baseUrl + '/product/getProductsAsOptions/');
    }
    public create(model: ProductModel): Observable<ResponseModel<any>> {
        return this.srv.post(this.srv.baseUrl + '/product/create', model);
    }
    public update(model: ProductModel): Observable<ResponseModel<any>> {
        return this.srv.post(this.srv.baseUrl + '/product/update', model);
    }

    public getVersionsForProduct(productId: number, tenantId?: string): Observable<ResponseModel<any>> {
        return this.srv.get(this.srv.baseUrl + '/product/getVersions/' + productId, tenantId);
    }

    public getVersionsVerbose(versionId: number): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/product/GetVersionsVerbose/${versionId}`);
    }
    public updateVersion(model: ProductVersionModel): Observable<ResponseModel<any>> {
        return this.srv.post(this.srv.baseUrl + '/product/updateVersion/', model);
    }
    public createVersion(model: ProductVersionModel): Observable<ResponseModel<any>> {
        return this.srv.post(this.srv.baseUrl + '/product/createVersion/', model);
    }
    public updateBundler(model: ProductBundlerModel): Observable<ResponseModel<any>> {
        return this.srv.post(this.srv.baseUrl + '/product/BundlerUpdate/', model);
    }
    public createBundler(model: ProductBundlerModel): Observable<ResponseModel<any>> {
        return this.srv.post(this.srv.baseUrl + '/product/BundlerCreate/', model);
    }
    public uploadImage(formData: any, id: number): Observable<any> {
        console.log('uploadImage');
        return this.srv.postProgress(`${this.srv.baseUrl}/product/UploadImage/${id}`, formData);
    }

    public uploadBundlerImage(formData: FormData, id: number): Observable<any> {
        return this.srv.postProgress(`${this.srv.baseUrl}/product/BundlerAddImage/${id}`, formData);
    }

    public getImages(id: number): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/product/getImages/${id}`);
    }
    public updateVersionImage(model: ProductVersionImageModel): Observable<any> {
        return this.srv.post(`${this.srv.baseUrl}/product/updateVersionImage/`, model);
    }
    public uploadImageUrl(id: number): string {
        return `${this.srv.baseUrl}/product/UploadImage/${id}`;
    }
    public getAllPriceList(): Observable<ResponseModel<any>> {
        return this.srv.get(this.srv.baseUrl + '/product/PriceList/GetAll');
    }

    public getPriceListAvailableItems(): Observable<ResponseModel<any>> {
        return this.srv.get(this.srv.baseUrl + '/product/PriceList/GetAvailableItems');
    }
    public getProductVersionAvailableItems(id: number): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/product/PriceList/GetAvailableVersionItems/${id}`);
    }
    public getPriceListItems(id: number): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/product/PriceList/GetItems/${id}`);
    }
    public createPriceList(model: PriceListModel): Observable<ResponseModel<any>> {
        return this.srv.post(`${this.srv.baseUrl}/product/PriceList/Create`, model);
    }
    public updatePriceList(model: PriceListModel): Observable<any> {
        return this.srv.post(`${this.srv.baseUrl}/product/PriceList/Update`, model);
    }
    public updatePriceListItems(models: Array<PriceListItemModel>): Observable<ResponseModel<any>> {
        return this.srv.post(`${this.srv.baseUrl}/product/PriceList/UpdateItems`, models);
    }


    // Language Version
    public updateVersionLang(model: ProductVersionVerboseModel): Observable<ResponseModel<any>> {
        return this.srv.post(this.srv.baseUrl + '/product/updateVersionLang', model);
    }

    // TAGS
    public updateVersionTags(items: Array<ListItemModel>, versionId: number): Observable<ResponseModel<any>> {
        return this.srv.post(`${this.srv.baseUrl}/product/tags/update/${versionId}`, items);
    }
    public getTagsAvailableForVersion(versionId: number): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/product/Tags/getAvailableForVersion/${versionId}`);
    }

    public getTagsInVersion(versionId: number): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/product/Tags/getInVersion/${versionId}`);
    }

    // Bundlers
    public getBundlers(): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/product/GetBundler/`);
    }
    public bundlerDisable(ids: number[]): Observable<ResponseModel<any>> {
        return this.srv.post(`${this.srv.baseUrl}/product/BundlerDisable`, ids);
    }
    public bundlerEnable(ids: number[]): Observable<ResponseModel<any>> {
        return this.srv.post(`${this.srv.baseUrl}/product/BundlerEnable`, ids);
    }
    public getVersionsForBundler(id: number): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/product/GetVersionsByBundler/${id}`);
    }
    public deleteImage(id: number): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/product/DeleteImage/${id}`);
    }

    public getTokensByVersion(id: number): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/token/GetAvailableOtkByProductVersion/${id}`);
    }

    public uploadOtk(models: Array<TokenOtkViewModel>): Observable<ResponseModel<any>> {
        return this.srv.post(`${this.srv.baseUrl}/token/uploadOtk/`, models);
    }

    public setDefaultVersion(id: number): Observable<ResponseModel<any>> {
        return this.srv.post(`${this.srv.baseUrl}/Product/SetVersionAsDefault?versionId=${id}`, {});
    }

    public setDefaultImage(id: number): Observable<ResponseModel<any>> {
        return this.srv.post(`${this.srv.baseUrl}/Product/SetImageAsFeature?imageId=${id}`, {});
    }

    public getHealthCheck(id: number): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/Product/GetProductVersionHealthCheck/${id}`);
    }

    public getProductVersionTags(): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/Product/GetProductVersionTags/`);
    }

    public createProductVersionTags(model: TagModel): Observable<ResponseModel<any>> {
        return this.srv.post(`${this.srv.baseUrl}/Product/CreateProductVersionTags/`, model);
    }

    public updateProductVersionTags(model: TagModel): Observable<ResponseModel<any>> {
        return this.srv.put(`${this.srv.baseUrl}/Product/UpdateProductVersionTags/`, model);
    }

    public removeProductVersionTags(id: number): Observable<ResponseModel<any>> {
        return this.srv.delete(`${this.srv.baseUrl}/Product/RemoveProductVersionTags/${id}`);
    }

    public setProductStatus(row: ProductModel, isActive: boolean): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/Product/SetProductStatus/productId/${row.id}/isActive/${isActive}`);

    }

    public getProductDisplayByPriceList(priceListId: number, languageId: number): Observable<ResponseModel<ProductItemModel[]>> {
        return this.srv.get(this.srv.baseUrl + `/product/GetProductDisplayByPriceList/priceList/${priceListId}/language/${languageId}`);
    }

    public updateVersionSorting(model: Array<{ versionId: number, sorting: number }>): Observable<ResponseModel<ProductItemModel[]>> {
        return this.srv.post(this.srv.baseUrl + `/product/Update/Version/Sorting`, model);
    }
    
    public UpdateVersionImagePriorities(id: number, priority: number,imgId:number): Observable<any> {
        console.log('id',id);
        console.log('priority',priority);
        console.log('img',imgId);
        let obj ={}
        obj[imgId.toString()]=priority;
        return this.srv.postProgress(`${this.srv.baseUrl}/Product/UpdateVersionImagePriorities/${id}`, obj);
    }
    public productRearrangePriority(model:any[]): Observable<any> {
        return this.srv.post(this.srv.baseUrl + `/product/RearrangePriority`, model);
    }
}
