// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login.login-v2 {
  color: #ccc;
  width: 400px;
  position: absolute;
  border-radius: 4px;
}
.login.login-v2 .login-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login.login-v2 button {
  max-width: 400px;
}

.login.login-v2 a {
  color: #fff;
}

.login-cover,
.login-cover-bg,
.login-cover-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: background 0.2s linear;
}

.login-cover-image img {
  max-width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.login-cover-bg {
  background: rgba(0, 0, 0, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/app/modules/main/security/login/login.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AAAI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AAEN;AAAI;EACE,gBAAA;AAEN;;AAEE;EACE,WAAA;AACJ;;AAEE;;;EAGE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,sBAAA;EACA,4BAAA;EACA,2BAAA;EACA,kCAAA;AACJ;;AAEE;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,kBAAA;AACJ;;AAEE;EACE,8BAAA;AACJ","sourcesContent":[".login.login-v2 {\n    color: #ccc;\n    width: 400px;\n    position: absolute;\n    border-radius: 4px;\n    .login-buttons {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n    button {\n      max-width: 400px;\n    }\n  }\n  \n  .login.login-v2 a {\n    color: #fff;\n  }\n  \n  .login-cover,\n  .login-cover-bg,\n  .login-cover-image {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center;\n    transition: background 0.2s linear;\n  }\n  \n  .login-cover-image img {\n    max-width: 100%;\n    top: 0;\n    left: 0;\n    position: absolute;\n  }\n  \n  .login-cover-bg {\n    background: rgba(0, 0, 0, 0.5);\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
