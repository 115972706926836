import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { SalesInvoiceListViewModel } from '../../../../../models/billing/sales-invoice.model';
import { BillingSalesService } from '../../../../../services/billing-sales.service';

@Component({
  selector: 'app-billing-sales-table',
  templateUrl: './billing-sales-table.component.html',
  styleUrls: ['./billing-sales-table.component.scss']
})

export class BillingSalesTableComponent implements OnInit {
  data: Array<SalesInvoiceListViewModel> = [];

  tableState = 0;
  selectCount = 0;

  regions: Array<any> = [];
  accounts: Array<any> = [];
  clients: Array<any> = [];

  displayedColumns: string[] = ['accountName', 'accountNumber', 'status', 'id',
    'items', 'cost', 'tax', 'total', 'createdOn', 'updateOn', 'invoiceGuid'];

  constructor(private billingSvc: BillingSalesService) {
  }
  ngOnInit() {
    this.get();
  }
  refresh() {
    this.get();
  }
  getSelectedIds(setToLoading: boolean): number[] {
    const selectedIs: number[] = [];
    this.data.forEach(x => {
      if (x.isSelected()) {
        selectedIs.push(x.id);
        if (setToLoading) {
          x.setLoading(true);
        }
      }
    });
    return selectedIs;
  }
  deselectAll() {
    this.data.forEach(x => {
      if (x.isSelected()) {
        this.select(x);
      }
    });
  }
  selectAll() {
    this.data.forEach(x => {
      if (!x.isSelected()) {
        this.select(x);
      }
    });
  }
  select(x: SalesInvoiceListViewModel): void {
    x.selectedToggle();
    if (x.isSelected()) {
      this.selectCount++;
    } else {
      this.selectCount--;
    }
  }
  get() {
    this.tableState = 1;
    this.data = [];
    this.selectCount = 0;
    this.billingSvc.getInvoices(null).subscribe(
      data => this.onGet(data),
      err => this.onGetError(err));
  }
  onGet(data) {
    if (data['result']) {
      this.data = [];
      data['result'].forEach(x => this.data.push(SalesInvoiceListViewModel.parse(x)));
      this.tableState = 2;
    } else {
      this.tableState = 4;
    }
  }
  onGetError(err) {
    this.onError(err);
  }
  onError(msg) {
    swal({
      position: 'top-end',
      type: 'error',
      title: 'Oops...',
      text: msg,
      footer: '<a href>Why do I have this issue?</a>'
    });
  }
}
