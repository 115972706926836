import { Component } from "@angular/core";
import { ClrDatagridComparatorInterface } from "@clr/angular";
import { AccountRegistratinModel } from "../../../models/account/account-registration.model";
import { ClientMainDashModel } from "../../../models/ClientMainDash.model";

@Component({
    selector: 'app-available-credit-comparator',
    template: ''
})
export class AvailableCreditComparatorComponent implements ClrDatagridComparatorInterface<ClientMainDashModel> {
    compare(a: ClientMainDashModel, b: ClientMainDashModel) {
        return this.getAvailableCredit(a) - this.getAvailableCredit(b);
    }

    private getAvailableCredit(item: ClientMainDashModel): number {
        return item.credit - item.balance;
    }
}
@Component({
    selector: 'app-agent-name-comparator',
    template: ''
})
export class AgentNameComparatorComponent implements ClrDatagridComparatorInterface<AccountRegistratinModel> {
    compare(a: AccountRegistratinModel, b: AccountRegistratinModel) {
        const nameA = `${a.firstName?.toUpperCase()} ${a.lastName?.toUpperCase()}`; 
        const nameB = `${b.firstName?.toUpperCase()} ${b.lastName?.toUpperCase()}`; 
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }
}