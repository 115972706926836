// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dbc-material-full-width {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/modals/product-alert-modal/product-alert-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ","sourcesContent":[".dbc-material-full-width {\n    width: 100%;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
