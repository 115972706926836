// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.superCenter {
  display: flex;
  justify-items: center;
  align-items: center;
}

.superCenter-col {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
}

.clr-form-control,
.clr-control-container,
.clr-input-wrapper.clr-input {
  margin-top: 0em !important;
  width: 100% !important;
}

.clr-input,
.clr-input-wrapper {
  max-width: 100%;
  width: 100%;
}

.work-around {
  margin-top: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/search/search.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,sBAAA;AACJ;;AAEA;;;EAGI,0BAAA;EACA,sBAAA;AACJ;;AAEA;;EAEI,eAAA;EACA,WAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".superCenter {\n    display: flex;\n    justify-items: center;\n    align-items: center;\n}\n\n.superCenter-col {\n    display: flex;\n    justify-items: center;\n    align-items: center;\n    flex-direction: column;\n}\n\n.clr-form-control,\n.clr-control-container,\n.clr-input-wrapper.clr-input {\n    margin-top: 0em!important;\n    width: 100%!important;\n}\n\n.clr-input,\n.clr-input-wrapper {\n    max-width: 100%;\n    width: 100%;\n}\n\n.work-around {\n    margin-top: 1em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
