import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { ModalTransferModel } from '../../../../../models/modal-transfer.model';
import { AccountRegionModel } from '../../../../../models/account.model';
import { AccountService } from '../../../../../services/account.service';
import { ResponseModel } from '../../../../../models/core/ResponseModel.model';
import { RegionModalComponent } from '../../region-modal/region-modal.component';
import { AuthService, CommonService, InternationalizationService } from '../../../../../services';

@Component({
    selector: 'app-region-table',
    templateUrl: './region-table.component.html',
    styleUrls: ['./region-table.component.scss']
})

export class RegionTableComponent implements OnInit {
    data: Array<AccountRegionModel> = [];
    dataFiltered: Array<AccountRegionModel> = [];
    selectedRegion: AccountRegionModel;

    tableState = 0;
    selectCount = 0;

    openRegionModal: boolean;
    modalReference: ModalTransferModel;
     


    constructor(private svc: AccountService, private auth: AuthService,
        private i18Svc: InternationalizationService,
        public common: CommonService) {
        this.i18Svc.initialize();
    }
    canView = this.auth.canViewRegion;
    canEdit = this.auth.canEditRegion;
    ngOnInit() {
        this.get();
    }
      
    filter(filterValue: string) {
        filterValue = filterValue?.toUpperCase();
        this.dataFiltered = this.data.filter(x => x.id?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.name?.toUpperCase().indexOf(filterValue) > -1
            || x.tax?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.marginDefault?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.priceList?.Name?.toString()?.toUpperCase().indexOf(filterValue) > -1
            || x.currency?.toString()?.toUpperCase().indexOf(filterValue) > -1);
    }
    refresh() {
        this.get();
    }
    add(): void {
        if (this.canEdit) {
            this.openRegionModal = true;
            this.modalReference = ModalTransferModel.createMode(RegionModalComponent);
        }
    }
    edit(): void {
        if (this.canView) {
            let modalTitle = '';
            if (this.canEdit) {
                modalTitle = 'edit';
            }

            else {
                modalTitle = 'open';
            }
            this.openRegionModal = true;
            const selected = JSON.parse(JSON.stringify(this.selectedRegion));
            this.modalReference = new ModalTransferModel(modalTitle, RegionModalComponent, selected, null, null);
        }
    }
    onRegionModalClose(refresh: boolean): void {
        this.openRegionModal = false;
        if(refresh) {
            this.refresh();
        }
    }
    get() {
        if (this.canView) {
            this.tableState = 1;
            this.data = [];
            this.dataFiltered = [];
            this.selectCount = 0;
            this.svc.getAllRegions()
                .subscribe(data => this.onGet(data), err => this.onGetError(err));
        }
    }
    onGet(data: ResponseModel<any>) {
        if (data.result) {
            this.data = [];
            this.dataFiltered = [];
            data.result.forEach(x => {
                this.data.push(AccountRegionModel.parse(x));
                this.dataFiltered.push(AccountRegionModel.parse(x));
            });
            this.tableState = 2;
        } else {
            this.tableState = 4;
            this.onError(data.errorMessage);
        }
    }
    onGetError(err) {
        this.onError(err);
    }
    onError(msg) {
        swal({
            position: 'top-end',
            type: 'error',
            title: 'Oops...',
            text: msg,
            footer: '<a href>Why do I have this issue?</a>'
        });
    }
}
