import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CreditClientLineModel } from '../../../models/ClientMainDash.model';
import { MainDashboardService, AuthService, InternationalizationService, CommonService } from '../../../services';
import swal from 'sweetalert2';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountRegionModel } from '../../../models/account.model';

@Component({
    selector: 'app-credit-management',
    templateUrl: './credit-management.component.html',
    styleUrls: ['./credit-management.component.scss']
})
export class CreditManagementComponent implements OnInit {

    @Input() set open(open: boolean) {
        this._open = open;
      }
      get open(): boolean {
        return this._open;
      }
      private _open: boolean;

      @Output() modalClose = new EventEmitter(); 

    @Input() reference: ModalTransferModel;
    @Input() isDashboardScreen: boolean;
    @Input() regionList: AccountRegionModel[] = [];

    _regionId: number;

    data: Array<CreditClientLineModel> = [];
    dataFiltered: Array<CreditClientLineModel> = [];
    dataSelected: Array<CreditClientLineModel> = [];
    tableState = 0;
    editCreditLineMode = false;
    requestSent = 0;

    constructor(private srv: MainDashboardService, public activeModal: NgbActiveModal, private auth: AuthService,
        private i18Svc: InternationalizationService,
        public common: CommonService) {
            this.i18Svc.initialize();
    }
    canView = this.auth.canViewCredit;
    canEdit = this.auth.canEditCredit;

    ngOnInit() {
        this._regionId = this.reference.object;
        this.get();
    }

      

    filter(filterValue: string) {
        filterValue = filterValue?.toUpperCase();
        this.dataFiltered = this.data.filter(x => x.id?.toString()?.toUpperCase().indexOf(filterValue) > -1
        || x.name?.toUpperCase().indexOf(filterValue) > -1
        || x.credit?.toString()?.toUpperCase().indexOf(filterValue) > -1
        || x.balance?.toString()?.toUpperCase().indexOf(filterValue) > -1
        || x.newBalance?.toString()?.toUpperCase().indexOf(filterValue) > -1);
    }
    refresh() {
        this.get();
    }

    get() {
        if (this._regionId && this.canView) {
            this.tableState = 1;
            this.data = [];
            this.dataFiltered = [];
            this.srv.getAccounts(this._regionId)
                .subscribe(data => this.onGet(data),
                    err => this.onGetError(err));
        }
    }
    onGet(data) {
        if (data['result']) {
            this.data = [];
            this.dataFiltered = [];
            data['result'].forEach(x => {
                this.data.push(CreditClientLineModel.parse(x));
                this.dataFiltered.push(CreditClientLineModel.parse(x));
            });
            this.tableState = 2;
        } else {
            this.tableState = 4;
        }
    }

    zeroSelection() {
        this.dataFiltered.forEach(x => {
            const isSelected = this.dataSelected.find(y => x.id === y.id);
            if (isSelected) {
                x.newBalance = 0;
            }
        });
    }
    save() {
        if (this.canEdit) {
            this.requestSent = 0;
            this.dataFiltered.forEach(x => {
                if (x.isEdited) {
                    const credit = this.editCreditLineMode ? x.newCredit : x.credit;
                    x.setLoading(true);
                    this.editCreditLineMode = false;
                    this.requestSent++;
                    this.srv.updateCredit({
                        accountId: x.id,
                        creditLine: credit,
                        balance: x.newBalance,
                        oldBalance: x.balance
                    }).subscribe(data => this.onUpdateCredit(data), () => this.requestSent--);
                }
            });
        }
    }
    onUpdateCredit(data) {
        this.requestSent--;
        if (data['result']) {
            const row = this.dataFiltered.find(x => x.id === data['result'].accountId);
            row.setLoading(false);
            row.credit = data['result'].creditLine;
            row.newCredit = 0;
            row.balance = data['result'].balance;
            row.newBalance = data['result'].balance;
        }
    }

    get loading() {
        return this.requestSent > 0;
    }

    onGetError(err) {
        this.onError(err);
    }
    onError(msg) {
        swal({
            position: 'top-end',
            type: 'error',
            title: 'Oops...',
            text: msg,
            footer: '<a href>Why do I have this issue?</a>'
        });
    }

    close() {
        this.open = false;
        this.modalClose.emit(false);
    }


}
