import { Injectable } from '@angular/core';
import { BrandingModel } from '../models/branding.model';
import { build_Configurations } from '../../environments/environment';

@Injectable()
export class BrandingService {
  public model: BrandingModel;

  public getBranding(_url:string) {
    this.model = {
      id: 1,
      cssUrl: '',
      name: build_Configurations.Name,
      logoUrl: build_Configurations.logoUrl, 
      faviconUrl: '',
      aboutUs: build_Configurations.AboutUs, 
      twitterUrl: '',
      likedinUrl: ''
    };
  }
}
