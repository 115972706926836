import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../services/account.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-user-template-modal',
  templateUrl: './user-template-modal.component.html',
  styleUrls: ['./user-template-modal.component.scss']
})

export class UserTemplateModalComponent implements OnInit {
  constructor(private actService: AccountService, private usrService: UserService, private activeModal: NgbActiveModal) { }
  name;
  levelOptions = [
    { value: 'Tier 0', id: 0 },
    { value: 'Tier 1', id: 1 },
    { value: 'Tier 2', id: 2 },
    { value: 'Tier 3', id: 3 }];
  regionOptions = [];
  accountOptions = [];
  roleGroupsOptions = [];
  level: number;

  regions = [];
  accounts = [];
  roleGroups = [];



  ngOnInit(): void {
    this.getRegions();
  }

  getRegions() {
    this.actService.getAllRegions().subscribe(
      data => this.onGetAllRegions(data),
      err => this.onError(err));
  }
  getAccounts(regionIds: number[]) {
    this.actService.getAccountsAsListItemForRegions(regionIds).subscribe(
      data => this.onGetAccounts(data),
      err => this.onError(err));
  }
  getRoleGroups(tier: number) {
    this.usrService.getRoles().subscribe(
      data => this.onGetRoleGroups(data),
      err => this.onError(err));
  }
  onError(err) {

  }

  onGetAllRegions(data) {
    if (data['result']) {
      this.regionOptions = data['result'];
    }
  }
  onGetAccounts(data) {
    if (data['result']) {
      this.accountOptions = data['result'];
    }
  }
  onGetRoleGroups(data) {
    if (data['result']) {
      this.roleGroupsOptions = data['result'];
    }
  }
  regionSelectionChange() {
    this.getAccounts(this.regions);
  }

  levelSelectionChange(): void {
    this.getRoleGroups(this.level);
  }
  validModel() {
    return false;
  }

  dismiss() {
    this.activeModal.dismiss();
  }
  close() {
    this.activeModal.close();
  }

  save() {
    console.log('save');
  }

}
