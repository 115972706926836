import { Injectable } from '@angular/core';
import { TicketModel } from '../models/ticket-model.module';

@Injectable()
export class SupportService {


  public get(id: number): Array<TicketModel> {
    const result: Array<TicketModel> = [];
    result.push(new TicketModel(5487, 'Server Down D20-ORM-01',
      'New', 'Jose Moreno', 'Today 1:00 PM', 'Janis Fegter', ' Today 1:45 PM'));
    result.push(new TicketModel(5482, 'Server Slow D20-ORM-01',
      'In Progress', 'Jose Moreno', 'Yesterday 10:07 AM', 'Janis Fegter', 'Today 1:43 PM'));
    result.push(new TicketModel(5483, 'Office error activating account',
      'In Progress', 'Jose Moreno', 'Today 9:14 AM', 'Janis Fegter', 'Today 1:45 PM'));
    result.push(new TicketModel(5401, 'Azure deployment failed',
      'In Progress', 'Jose Moreno', '8/15/2018 1:00 PM', 'Javier Gaugt', 'Today 1:45 PM'));
    return result;
  }
}
