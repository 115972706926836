import { AfterContentChecked, ChangeDetectorRef, Component } from '@angular/core';
import swal from 'sweetalert2';
import { CommonService } from '../../../services';
import { GlobalSearchService } from '../../../services/global-search.service';
import { AccountResponse } from './models/accounts.response';
import { UserResponse } from './models/user.response';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements AfterContentChecked {
  //variables
  query:string = '';
  filter:string = '';
  loading:boolean = false;
  //arrays
  arrUser:  UserResponse[]= [];
  arrInvoice: any[] = [];
  arrAccount: AccountResponse[] = [];
  rows: any = [];

  tableState = 0;
  dataFiltered: Array<any>[] = [];
  dataSelected: Array<any>[] = [];
  columnsUser: any = [
    {title:'regQueue_firstname_text'},
    {title:'regQueue_lastname_text'},
    {title:'email_text'},
    {title:'regQueue_regionid_text'},
    {title:'userRegCode_region_text'},
    {title:'account_name_text'},
  ]
  columnsAcc: any = [
    {title:'account_name_text'},
    {title:'account_nunmber_text'},
    {title:'creditDtl_accountingNo_text'},
    {title:'email_text'},
    {title:'id_text'},
    {title:'regQueue_regionid_text'},
    {title:'userRegCode_region_text'},
  ]
  columnsInv: any = [
    {title:'userMgnt_createdBy_text'},
    {title:'userMgnt_createdBy_text'},
    {title:'created_On_text'},
    {title:'invoice_Guid_text'},
    {title:'invoice_Id_text'},
    {title:'regQueue_regionid_text'},
    {title:'userRegCode_region_text'},
  ]
   

  constructor(private srv: GlobalSearchService,
              private cdref: ChangeDetectorRef,
              public common: CommonService) { }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  ngDoCheck() {
    this.reloadLbl();
}
ngAfterViewInit(){
    this.reloadLbl();
}
reloadLbl(){
       
    this.cdref.detectChanges();
}
  search(){
    if (this.query.length === 0 || this.filter === '') {
      if (this.query.length === 0) {
        this.onError('Search can be empty');
        return;
      } else {
        this.onError('You must choose a filter');
        return;
      }
    }
    this.loading = true;
    switch (this.filter) {
      case 'users':
        this.srv.getUsersBySearchQuery(this.query).subscribe(res =>{
          this.arrUser = [];
          this.arrUser = res.result;
          this.loading = false;
        },err => console.log(err));
        break;
        case 'invoices':
          this.srv.getInvoicesBySearchQuery(this.query).subscribe(res =>{
            this.arrInvoice = [];
            this.arrInvoice = res.result;
            this.loading = false;
          });
          break;
          case 'accounts':
            this.srv.getAccounts(this.query).subscribe(res =>{
              this.arrAccount = [];
              this.arrAccount = res.result;
              this.loading = false;
        });
        break;
    
      default:
        break;
    }
  }
  keyDown(e:any){
    if (e.key === 'Enter') {
      e.preventDefault();
      this.search();
    }
  }
  onError(msg) {
    swal({
      position: 'top-end',
      type: 'error',
      title: 'Oops...',
      text: msg,
    });
  }
}
