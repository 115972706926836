import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';
import { ResponseModel } from '../models/core/ResponseModel.model';

@Injectable()
export class RetailService {
  public Redeem(reailId: string, code: string, email: string): Observable<ResponseModel<any>> {
    return this.srv.get(this.srv.baseUrl + `/retail/redemeCode/${reailId}/${code}?email=${email}`);
    }
  constructor(private srv: CoreService) {
  }


}
