import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'listFilter'
})
export class FilterPipe implements PipeTransform {

    constructor(private translate: TranslateService) { }

    transform(list: any[], filterText: string): any {
        return filterText ? 
        ((list || [])?.filter(item => {
            const translateValue = this.translate.instant(item.value || '');
            return translateValue?.toString().toLowerCase()
                .trim().startsWith(filterText.toString().toLowerCase().trim());
        })) :
         list;
    }
}
