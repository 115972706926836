import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';

@Injectable()
export class BillingSalesService {
    constructor(private srv: CoreService) {
    }

    public approveOrder(id: number): Observable<any> {
        return this.srv.post(`${this.srv.baseUrl}/billing/sales/ApproveOrder/${id}`, {});
    }

    public denyOrder(id: number): Observable<any> {
        return this.srv.post(`${this.srv.baseUrl}/billing/sales/DenyOrder/${id}`, {});
    }
    public getInvoices(model: any): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/billing/sales/GetInvoices`);
    }

    public getQueueInvoices(id: number): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/billing/sales/GetQueueInvoices/${id}`);
    }
    public getInvoiceByRegion(id: number): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/billing/sales/GetInvoiceByRegion/${id}`);
    }
    public getInvoicesByAccount(id: number): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/billing/sales/GetInvoicesByAccount/${id}`);
    }

    public getInvoice(id: number): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/billing/sales/GetInvoice/${id}`);
    }

    public generateLabel(id: string): Observable<any> {
        return this.srv.post(`${this.srv.baseUrl}/shipping/GenerateLabel/${id}`, null);

    }
    public getLabel(id: string): Observable<any> {
        return this.srv.post(`${this.srv.baseUrl}/shipping/GetLabel/${id}`, null);
    }
    public sendEmailApi(id: string, email: string, opt: string): Observable<any> {
        let url: string = '';
        if (opt === 'SL') {
            url = `${this.srv.storeUrl}/Order/SendLicenses/${id}?email=${email}`;
        } else {
            url = `${this.srv.storeUrl}/Order/SendConfirmation/${id}?email=${email}`;

        }
        return this.srv.post(url, null);

    }

    public getDeniedInvoices(regionId: number, loopbackDays: number): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/billing/sales/GetDeniedInvoices/${regionId}?loopbackDays=${loopbackDays}`);
    }
}
