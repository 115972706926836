import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import swal from 'sweetalert2';
import { RetailService } from '../../../services/retail.service';
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-retail-redeem',
  templateUrl: './retail-redeem.component.html'
})

export class RetailRedeemComponent implements OnInit {
  constructor(private app: AppComponent,
    private formBuilder: UntypedFormBuilder, private retailService: RetailService) {
    this.app.setPageSettings({
      pageEmpty: true
    });
  }

  bg;
  redeemForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  get f() { return this.redeemForm.controls; }

  onError(err) {
    swal({
      type: 'error',
      title: 'Oops...',
      text: 'Could not confirm the Email Address',
      footer: '<a href>Why do I have this issue?</a>'
    });
  }
  ngOnInit() {
    this.bg = '/assets/img/login-bg/login-bg-17.jpg';
    this.redeemForm = this.formBuilder.group({
      email: [''],
      code: ['', Validators.required],
      retailId: ['D3119CBE-ACD5-452A-AF4B-29D38224D736', Validators.required]
    });
  }

  onRedeemResult(data) {

    this.loading = false;
    if (data['result']) {
      swal({
        type: 'success',
        title: 'Token Redeem',
        html: `Token: ${data['result'].token}<br/> <a href="${data['result'].link}">activation link</a> `,
        footer: '<a href>How to use this token</a>'
      });
      this.redeemForm = this.formBuilder.group({
        email: [''],
        code: ['', Validators.required],
        retailId: ['D3119CBE-ACD5-452A-AF4B-29D38224D736', Validators.required]
      });
    }
  }
  onRedeem() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.redeemForm.invalid) {
      return;
    }
    this.loading = true;
    this.retailService.Redeem(this.f.retailId.value, this.f.code.value, this.f.email.value )
      .pipe(first())
      .subscribe(
      data => this.onRedeemResult(data),
        error => {
          this.loading = false;
          swal({
            type: 'error',
            title: 'Oops...',
            text: 'Could not login',
            footer: '<a href>Why do I have this issue?</a>'
          });
        });
  }
}
