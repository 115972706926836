import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreService } from './core.service';
import { ResponseModel } from '../models/core/ResponseModel.model';

@Injectable()
export class MainDashboardService {

  constructor(private srvCore: CoreService) { }

  public getAccounts(regionId: number): Observable<ResponseModel<any>> {
    return this.srvCore.get(`${this.srvCore.baseUrl}/Dashboard/GetAccountOverview/` + regionId);
  }

  getRegionOverview(regionId: number): Observable<any> {
    return this.srvCore.get(`${this.srvCore.baseUrl}/dashboard/GetRegionOverview/${regionId}`);
  }

  getSalesSummary(): Observable<any> {
    return this.srvCore.get(`${this.srvCore.baseUrl}/sales/SalesSummaryResponse`);
  }
  updateCredit(model: any): Observable<any>{
    return this.srvCore.post(`${this.srvCore.baseUrl}/account/UpdateCredit`, model);
  }
  GetProductsRanking() {
    return [];
   }
}
