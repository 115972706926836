import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { ProductModel } from '../../../models/product/product.model';
import { ProductService } from '../../../services/product.service';
import { ListItemModel } from '../../../models/list-item.model';
import { ToastrService } from 'ngx-toastr';
import { InternationalizationService } from '../../../services';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss']
})

export class ProductModalComponent implements OnInit {
  @Input() reference: ModalTransferModel;

  model: ProductModel;
  types: Array<ListItemModel>;
  channels: Array<ListItemModel>;
  versions: Array<ListItemModel>;
  isNew: boolean;
  title: string;

  selectedChannelId: number;
  selectedChannelType: number;
  selectedVersion: number;

  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;

  @Output() modalClose = new EventEmitter();

  loading: boolean;


  constructor(
    private readonly productService: ProductService,
    private readonly toastr: ToastrService,
    private readonly i18Svc: InternationalizationService
    ) {
    this.i18Svc.initialize();
  }

  ngOnInit() {
    this.initMode();
    this.productService.getTypes().subscribe(data => this.onGetTypes(data), err => this.handleError(err));
    this.productService.getChannels().subscribe(data => this.onGetChannels(data), err => this.handleError(err));
    if (!this.isNew) {
      this.productService.getVersionsAsOptions(this.model.id).subscribe(
        data => this.onGetVersions(data),
        err => this.handleError(err));
    } else {
      this.versions = null;
    }
  }

  initMode() {
    // Set Mode
    this.isNew = !this.reference?.object || this.reference.action === 'new';

    // Set Model
    if (!this.isNew) {
      this.model = this.reference.object;
    } else {
      this.model = ProductModel.create();

      this.model.channel = new ListItemModel(this.reference.object?.id,
        this.reference.object?.name);
      this.reference.object = this.model;
    }

    // Set Title
    if (this.isNew) {
      this.title = 'Create Product';
    } else {
      this.title = 'Product ' + this.model.name;
    }
  }

  handleError(err) {
    this.loading = false;
    console.log(err);
  }
  onGetChannels(data) {
    this.channels = data['result'];
    const channelId = this.isNew ? this.model.channel.id : this.model.channelId;
      if (channelId) {
          let channel = this.channels.find(x => x.id === channelId);
          this.model.channel = channel;
          this.model.channelId = channel.id;
      this.selectedChannelId = this.model.channel.id;
    }
  }

  onGetTypes(data) {
    this.types = data['result'];
    if (this.model.channelTypeId) {
      this.model.channelType = this.types.find(x => x.id === this.model.channelTypeId);
      this.selectedChannelType = this.model.channelType.id;
    }
  }
  onGetVersions(data) {
    this.versions = data['result'];
    if (this.model.defaultVersionId) {
      this.model.defaultVersion = this.versions.find(x => x.id === this.model.defaultVersionId);
      this.selectedVersion = this.model.defaultVersion.id;
    }
  }
  validModel(): boolean {
    return !!this.selectedChannelId;
  }
    setChannel() {
        let channel = this.channels.find(x => x.id?.toString() === this.selectedChannelId?.toString());
        this.model.channel = channel;
        this.model.channelId = channel.id;
  }
    setType() {
        const type = this.types.find(x => x.id?.toString() === this.selectedChannelType?.toString());
        this.model.channelType = type;
        this.model.channelTypeId = type.id
  }
    setVersion() {
        let version = this.versions.find(x => x.id?.toString() === this.selectedVersion?.toString());
        this.model.defaultVersion = version;
        this.model.defaultVersionId = version.id;
  }
  save() {
    if (this.validModel()) {
      this.loading = true;
      if (this.isNew) {
        this.productService.create(this.model)
          .subscribe(data => this.onProductResponse(data),
            err => this.handleError(err));
      } else {
        this.productService.update(this.model)
          .subscribe(data => this.onProductResponse(data),
            err => this.handleError(err));
      }
      // console.log(this.model);
    }
  }
  onProductResponse(data) {
    this.loading = false;
    if (data?.result) {
      if (!this.reference) {
        this.reference = new ModalTransferModel('open', ProductModalComponent, null, null);
      }
      const model: ProductModel = ProductModel.parse(data);
      model.expandModel();
      this.model = model;
      this.reference.object = this.model;
      this.initMode();
    }
    this.showMessage(data);
  }

  showMessage(data): void {
    const mode = this.isNew ? 'create' : 'update';
    if (data?.result) {
      this.toastr.success('your product have been ' + mode + 'd: ' + this.model.name);
      this.closeModal(true);
    } else {
      this.toastr.error(data?.errorMessage);
    }
  }

  closeModal(refresh?: boolean): void {
    this.open = false;
    this.modalClose.emit(refresh);
  }

}
