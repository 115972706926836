import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { AccountModel, IAccountRegionModel } from '../../../models/account.model';
import { ListItemModel } from '../../../models/list-item.model';
import { ConfigurationService, AccountService, UserService } from '../../../services';
import { AccountRegistratinModel } from '../../../models/account/account-registration.model';
import { UserDetailModel } from '../../../models/user/user-detail.model';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-registration-approve-modal',
    templateUrl: './registration-approve-modal.component.html',
    styleUrls: ['./registration-approve-modal.component.scss']
})
export class RegistrationApprovalModalComponent implements OnInit {
    @Input() set open(open: boolean) {
        this._open = open;
      }
      get open(): boolean {
        return this._open;
      }
      private _open: boolean;
    @Input() reference: ModalTransferModel;
    model: AccountRegistratinModel;
    title: string;

    languages: Array<ListItemModel> = [];
    currencies: Array<ListItemModel> = [];
    taxgroups: Array<ListItemModel> = [];
    priceSheets: Array<ListItemModel> = [];


    account: AccountModel;
    user: UserDetailModel;
    region: IAccountRegionModel;

    isLinear = true;
    loading = false;
    regionRegFormGroup;
    userRegFormGroup;
    confirmationFormGroup;
    billingDateOptions = [];
    roles = [];
    @ViewChild('stepper') stepper;

    @Output() modalClose = new EventEmitter();

    constructor(public activeModal: NgbActiveModal, private confSrv: ConfigurationService, private accountSrv: AccountService,
        private usrService: UserService, private toastr: ToastrService) { }

    ngOnInit() {
        this.initMode();
        this.confSrv.getCurrencyOptions()
            .subscribe(data => this.onGetCurrency(data), err => this.handleError(err));

        this.confSrv.getPriceSheetOptions()
            .subscribe(data => this.onGetPriceSheetOptions(data), err => this.handleError(err));



        this.confSrv.getLanguageOptions()
            .subscribe(data => this.onGetLanguage(data), err => this.handleError(err));

        this.confSrv.getTaxGroups()
            .subscribe(data => this.onGetTaxGroup(data), err => this.handleError(err));

        this.accountSrv.getRegion(this.model.regionId)
            .subscribe(data => this.onGetRegion(data), err => this.handleError(err));
    }

    onGetPriceSheetOptions(data) {
        this.priceSheets = data['result'];
        if (this.priceSheets.length > 0) {
            this.account.priceSheetId = this.priceSheets[0].id;
        }

    }

    initMode() {
        this.model = <AccountRegistratinModel>this.reference.object;
        console.log(this.model)

        // Set Title
        this.title = 'Registration Queue: ' + this.reference.object.regionId;
        this.account = this.model.getAccount();
        this.user = this.model.getUser();
    }

    handleError(err, close: boolean = false) {
        // TODO: Toster error
        // if (close) {
        //  this.close(true, false);
        // }
    }

    isValidPage1(): boolean {
        return !!(this.account.priceSheetId
            && this.account.languageId
            && this.account.currencyId
            && this.account.accountingNo
            && this.account.number
            && this.account.name);
    }

    isValidPage2(): boolean {
        return !!(this.user.Username
            && this.user.FirstName
            && this.user.LastName
            && this.user.Email);
    }
    isValidModel(): boolean {
        return this.isValidPage1()
            && this.isValidPage2()
    }
    createAccount() {
        this.loading = true;
        this.account.id = 0;
        this.accountSrv.create(this.account)
            .subscribe(data => this.onGet(data), err => this.handleError(err));
    }

    onGet(data) {
        this.account = (data['result'] as AccountModel);
        this.createUser();
    }

    doCustomClick(buttonType: string) {
        if ('custom-finish' === buttonType) {
          this.createAccount();
        }
      }

    createUser() {
        this.user.languageId = this.account.languageId;
        this.user.accountId = this.account.id;
        this.user.tier = 2;
        this.usrService.createUser(this.user).subscribe(userResult => this.onSuccess(userResult), err => this.handleError(err));
    }

    onSuccess(user): void {
        if (user.result) {
            this.usrService.updateUserRolesGroup(user.result.id, ['Reseller']).subscribe(() => this.onUserRoleUpdate());
        }

        this.accountSrv.declineRegistreation(this.model.id).subscribe(() => this.onCompleteReg(), err => this.handleError(err));
        this.close(true);

    }
    onUserRoleUpdate() {

    }
    onCompleteReg() {
        this.loading = false;
        this.toastr.success('User have been saved');

    }
    
    close(refresh?: boolean): void {
        this.open = false;
        this.modalClose.emit(refresh);
    }

    onGetLanguage(data): void {
        this.languages = data['result'];
    }

    onGetCurrency(data): void {
        this.currencies = data['result'];
    }
    onGetRegion(data): void {
        this.region = data['result'];
        //this.account.priceSheetId = this.region.priceList
        this.account.currencyId = this.region.currency.id;
        this.account.taxId = this.region.tax.id;
    }
    onGetTaxGroup(data): void {
        this.taxgroups = data['result'].map(x => {
            x['value'] = x['name'];
            return x;
        });
    }

    onNextStep(): void {
        this.stepper.next();
    }
    
    onRoleChange() { }

    setAccountNo() {
        this.account.accountingNo = `A:${this.account.number}`;
    }
}
