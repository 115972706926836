// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .dbcLargeModal .modal-dialog {
  max-width: 1000px;
}

.dbc-material-full-width {
  width: 100%;
}

.img_slider_card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/modals/banner-table-modal/banner-table-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACF","sourcesContent":["::ng-deep .dbcLargeModal .modal-dialog {\n    max-width: 1000px;\n}\n\n.dbc-material-full-width {\n  width: 100%;\n}\n\n.img_slider_card {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
