import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-loader',
  templateUrl: './progress-loader.component.html',
  styleUrls: ['./progress-loader.component.scss']
})
export class ProgressLoaderComponent implements OnInit {
    @Input() show: boolean;
    @Input() size?: 'sm' | 'md' | 'lg';
    progressBarHeight: number;

    ngOnInit(): void {
        this.setHeight();
    }

    setHeight() {
        this.progressBarHeight = this.getHeight();
    }

    getHeight(): number {
        if(this.size === 'md') {
            return .5;
        } else if(this.size === 'lg') {
            return .75;
        }
        return .25;
    }
    
}
