import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver'

const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

  private fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  private fileExtension = '.xlsx';
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const today = new Date();
    const date = today.getFullYear() + '' + (today.getMonth() + 1) + '' + today.getDate() + '_';
    const time = today.getHours() + '-' + today.getMinutes() + '-' + today.getSeconds();
    const name = excelFileName + date + time + EXCEL_EXTENSION;
    XLSX.writeFile(wb, name);


  }

  getCurrency(currency: string) {
    if(currency === 'T EURO') {
      return "€";
    } else if(currency === 'T US DOLLAR') {
      return "$";
    }
    return '';
  }

  public exportDocument(type: string, data: any[], titles: any[], excelFileName: string) {
    let exportData: any[] = this.formatArraySheet(data);
    exportData.unshift(titles);
    const fileName = excelFileName + Math.floor(Date.now() / 1000);
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(exportData, { cellDates: true });
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
   
    const columnWidths: number[] = titles.map((header: string) => {
      const columnData: any[] = data.map((row: any) => row[header]);
      const maxLength: number = Math.max(header.length, ...columnData.map((value: any) => String(value || '').length));
      const minWidth: number = 12; // Minimum width value
      return Math.max(maxLength, minWidth);
    });
  
    ws['!cols'] = columnWidths.map((width: number) => ({ width }));
    
    const range = XLSX.utils.decode_range(ws['!ref']);
for (let row = range.s.r; row <= range.e.r; row++) {
  const soldCurrencyCellRef = XLSX.utils.encode_cell({ r: row, c: 12 }); // Assuming "soldCurrency" column is at V
  const purchaseCurrencyCellRef = XLSX.utils.encode_cell({ r: row, c: 9 }); // Assuming "purchaseCurrency" column is at W
  const soldCurrency = ws[soldCurrencyCellRef]?.v;
  const purchaseCurrency = ws[purchaseCurrencyCellRef]?.v;

  const soldCurrencySymbol = this.getCurrency(soldCurrency);
  const purchaseCurrencySymbol = this.getCurrency(purchaseCurrency);

  for (let col = range.s.c; col <= range.e.c; col++) {
    const cellRef = XLSX.utils.encode_cell({ r: row, c: col });
    const cell = ws[cellRef];
    const columnName = XLSX.utils.encode_col(col);
    const cellValue = cell?.v;
    console.log('antes if ', columnName);

    if (typeof cellValue === "number" && columnName !== "H" && columnName!=="I") {
      console.log('en el if ', columnName);
      let currencySymbol = "";

      if (columnName === "K" || columnName === "L") {
        currencySymbol = purchaseCurrencySymbol;
      } else {
        currencySymbol = soldCurrencySymbol;
      }

      const format = `${currencySymbol}0.00`;
      cell.t = "n";
      cell.z = format;
      cell.w = currencySymbol + cellValue.toFixed(2);
    }
  }
}

   
    for (let item in wb.Sheets.data) {
      if (/\d{4}-\d{2}-\d{2}/.test(wb.Sheets.data[item].v)) {
        wb.Sheets.data[item] = { ...wb.Sheets.data[item], t:"d", z: "m/d/yy" }
      }
      if (/([01]?[0-9]|2[0-3]):[0-5][0-9]/.test(wb.Sheets.data[item].v)) {
        wb.Sheets.data[item] = { ...wb.Sheets.data[item], z: "hh:mm:ss" }
      }
      //Force exclude qty = 1
      // if (wb.Sheets.data[item].t === 'n' && wb.Sheets.data[item] !== "1") {
      //   wb.Sheets.data[item] = { ...wb.Sheets.data[item], z: "[$$-80A]#,##0.00;[RED]-[$$-80A]#,##0.00" }
      // }
    }
    let excelBuffer: any;
    switch (type) {
      case 'xls':
        excelBuffer = XLSX.write(wb, {
          bookType: 'xlsx',
          type: 'array',
          cellStyles: true
        });
        break;
      case 'csv':
        excelBuffer = XLSX.write(wb, {
          bookType: 'csv',
          type: 'array',
          cellStyles: true
        });
        break;
      case 'txt':
        excelBuffer = XLSX.write(wb, {
          bookType: 'txt',
          type: 'array'
        });
        break;

      default:
        break;
    }
    this.saveExcelFile(excelBuffer, fileName, type)
  }

  public saveExcelFile(buffer: any, fileName: string, type: string) {
    switch (type) {
      case 'xls':
        this.fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        this.fileExtension = '.xlsx'
        break;
      case 'csv':

        this.fileType = 'text/csv';
        this.fileExtension = '.csv'
        break;
      case 'txt':

        this.fileType = 'text/txt';
        this.fileExtension = '.txt'
        break;

      default:
        break;
    }
    const dataBlob: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(dataBlob, fileName + this.fileExtension);
  }
  formatArraySheet(data: any) {
    let arrAux = [];
    let arrAux2 = [];
    let regex = /[$€?]+/;

    data.forEach(res => {
      Object.keys(res).forEach(key => {
        arrAux2.push(res[key]);
      });
      arrAux.push(arrAux2);
      arrAux2 = [];
    });
    arrAux.forEach(res => {
      for (let index = 0; index < res.length - 1; index++) {
        if (regex.test(res[index])) {
          res[index] = res[index].replace(/[, $ ]/g, '');
          res[index] = res[index].replace(/[, € ]/g, '');
          // res[index] = res[index].replace('.00', '');
          res[index] = Number(res[index].substring(res))
        }
      }
    })
    return arrAux;
  }
  formatArraySheetImport(data: any) {
    let arrAux = [];
    let arrAux2 = [];
    let regex = /[$€%?]+/;

    data.forEach(res => {
      Object.keys(res).forEach(key => {
        arrAux2.push(res[key]);
      });
      arrAux.push(arrAux2);
      arrAux2 = [];
    });
    arrAux.forEach(res => {
      for (let index = 0; index < res.length - 1; index++) {
        if (regex.test(res[index])) {
          res[index] = res[index].replace(/[, $ € % ]/g, '');
          res[index] = res[index].replace('.00', '');
          res[index] = Number(res[index].substring(res))
        }
      }
    })
    return arrAux;
  }
}
