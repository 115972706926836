// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .dbcLargeModal .modal-dialog {
  max-width: 1000px;
}

.dbcXLargeModal > .modal-dialog {
  max-width: 1400px;
}

.itemActive {
  border-left: 5px solid #5cb85c;
}

.itemWarning {
  border-left: 5px solid #f0ad4e;
}

.itemInactive {
  border-left: 5px solid #d9534f;
}

.btn {
  margin-right: 6px;
}

#toast-container > div {
  opacity: 1;
}

.toast {
  opacity: 1 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/user-management/user-management.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,qBAAA;AACF","sourcesContent":["::ng-deep .dbcLargeModal .modal-dialog {\n  max-width: 1000px;\n}\n\n.dbcXLargeModal > .modal-dialog {\n  max-width: 1400px;\n}\n\n.itemActive {\n  border-left: 5px solid #5cb85c;\n}\n\n.itemWarning {\n  border-left: 5px solid #f0ad4e;\n}\n\n.itemInactive {\n  border-left: 5px solid #d9534f;\n}\n\n.btn {\n  margin-right: 6px;\n}\n\n#toast-container > div {\n  opacity:1;\n}\n\n.toast {\n  opacity: 1 !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
