import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activeFilter'
})
export class ActiveFilterPipe implements PipeTransform {

  transform(arr: any[], args: { showInactive: boolean, key: string }): any[] {
    if(args?.showInactive || (arr || [])?.length === 0) {
      return arr;
    }
    return arr?.filter(x => x && x[args?.key]);
  }

}
