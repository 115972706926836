import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export class ModalTransferModel {
  action: string;
  modal: any;
  modalOptions: NgbModalOptions;
  object: any;
  returnRequest: any;
  returnModalOptions: NgbModalOptions;
  msg: string;
  title: string;
  header: string;
  error: boolean;
  swal: boolean;
  parentObject: any;

  constructor(action: string,
    modal: any,
    object: any,
    returnRequest: any,
    modalOptions: NgbModalOptions = null) {
    this.action = action;
    this.modal = modal;
    this.object = object;
    this.returnRequest = returnRequest;
    this.swal = false;
    this.modalOptions = modalOptions;
  }

  public static createMode(modal: any): ModalTransferModel {
    return new ModalTransferModel('new', modal, null, null, null);
  }

  public setError(title: string, msg: string) {
    this.msg = msg;
    this.title = title;
    this.error = true;
    this.swal = true;
  }

  public setSuccess(title: string, msg: string) {
    this.msg = msg;
    this.title = title;
    this.error = false;
    this.swal = true;
  }

  public close() {
    this.modal = this.returnRequest;
    this.returnRequest = null;
    this.modalOptions = this.returnModalOptions;
    this.returnModalOptions = null;
  }

  public transerWithReturn(newModal: any, newModalOptions: NgbModalOptions, obj: any) {
    this.returnRequest = this.modal;
    this.modal = newModal;
    this.object = obj;

    this.returnModalOptions = this.modalOptions;
    this.modalOptions = newModalOptions;
  }

}
