import { Component } from '@angular/core';

@Component({
    selector: 'app-billing-sales-management',
    templateUrl: './billing-sales-management.component.html',
    styleUrls: ['./billing-sales-management.component.scss']
})
export class BillingSalesManagementComponent {
 
}
