import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../services';
import { AccountRegionModel } from '../models/account.model';

@Component({
  selector: 'app-region-banner',
  templateUrl: './region-banner.component.html',
  styleUrl: './region-banner.component.scss'
})
export class RegionBannerComponent implements OnInit, OnDestroy {
  private subscripe$: Subscription = new Subscription();
  regionList: AccountRegionModel[] = [];
  selectedRegions: AccountRegionModel[] = [];
  allSelected: boolean;

  openModal = false;

  selection: AccountRegionModel[] = [];

  loadingRegionId: number;
  loadingRegions: boolean;

  constructor(private common: CommonService) { }

  ngOnInit(): void {
    this.initRegions();
    this.subscripe$.add(this.common.regionAllChange$.subscribe((list: AccountRegionModel[]) => this.initRegions()));
    this.subscripe$.add(this.common.loadingRegion$.subscribe((id: number) => this.loadingRegionId = id));
    this.subscripe$.add(this.common.loadingAllRegions$.subscribe((loading: boolean) => this.loadingRegions = loading));
  }

  initRegions() {
    this.regionList = this.common.allRegions || [];
    const localRegions = JSON.parse(localStorage.getItem('selected_regions') || '[]') || [];
    if (localRegions?.length > 0) {
      this.selectedRegions = [];
      localRegions.forEach(x => {
        const isExist = this.regionList.find(r => r.id === x.id);
        if (isExist) {
          this.selectedRegions.push(x);
        }
      });
    } else {
      this.selectedRegions = this.regionList;
    }
    this.checkSelection();
    this.common.selectedRegionsChange(this.selectedRegions);
  }

  checkSelection() {
    this.allSelected = this.regionList?.length === this.selectedRegions?.length;
  }

  changeRegion() {
    if (this.loadingRegionId == -1) {
      this.selection = [];
      this.selectedRegions.forEach(x => {
        const selectionData = this.regionList.find(r => r.id === x.id);
        if (selectionData) {
          this.selection.push(selectionData);
        }
      });
      this.openModal = true;
    }
  }
  changeRegionAction() {
    this.openModal = false;
    this.selectedRegions = this.selection || [];
    localStorage.setItem('selected_regions', JSON.stringify(this.selectedRegions));
    this.checkSelection();
    this.common.selectedRegionsChange(this.selectedRegions);
  }

  close() {
    this.openModal = false;
  }

  ngOnDestroy(): void {
    this.subscripe$?.unsubscribe();
  }

}
