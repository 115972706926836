import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiModel } from '../../../models/account.model';

@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.scss']
})
export class UsersManagementComponent {
  @Input() name: string;
  @Input() id: number;

  model: ApiModel;

  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;
  @Output() modalClose = new EventEmitter();

  close() {
    this.open = false;
    this.modalClose.emit(false);
  }

}
