import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  public getType(arr: any[], args: any): string {
    if (arr) {
      for (let i = 0; i < arr.length; i++) {
        if (typeof arr[i][args.sortBy] === 'number') {
          return 'number';
        } else if (typeof arr[i][args.sortBy] === 'string') {
          return 'string';
        }
      }
    }
    return '';
  }

  public transform(array: any[], args: any): any[] {
    if (this.getType(array, args)) {
      const initVal = this.getType(array, args) === 'string' ? '' : -1;
      if (args.sortType) {
        array.sort((a: any, b: any) => {
          const aSortBy = a[args.sortBy] === null || typeof a[args.sortBy] === 'undefined' ? initVal : a[args.sortBy];
          const bSortBy = b[args.sortBy] === null || typeof b[args.sortBy] === 'undefined' ? initVal : b[args.sortBy];
          if (aSortBy > bSortBy) {
            return -1;
          } else if (aSortBy < bSortBy) {
            return 1;
          } else {
            return 0;
          }
        });
        return array;
      } else {
        array.sort((a: any, b: any) => {
          const aSortBy = a[args.sortBy] === null || typeof a[args.sortBy] === 'undefined' ? initVal : a[args.sortBy];
          const bSortBy = b[args.sortBy] === null || typeof b[args.sortBy] === 'undefined' ? initVal : b[args.sortBy];
          if (aSortBy < bSortBy) {
            return -1;
          } else if (aSortBy > bSortBy) {
            return 1;
          } else {
            return 0;
          }
        });
        return array;
      }
    }
    return array;
  }

}
