import { Injectable } from '@angular/core';
import { CoreService } from './core.service';
import { Observable } from 'rxjs';
import { CurrencyModel } from '../models/billing/currency.model';
import { LanguageModel } from '../models/language.model';
import { TaxGroupModel } from '../models/billing/tax.model';
import { ResponseModel } from '../models/core/ResponseModel.model';

@Injectable()
export class ConfigurationService {

  constructor(private srv: CoreService) {

  }

  public getTaxGroups(): Observable<ResponseModel<any>> {
    return this.srv.get(`${this.srv.baseUrl}/tax/getallgroups/`);
    }

    public getPriceSheetOptions(): Observable<ResponseModel<any>> {
        return this.srv.get(`${this.srv.baseUrl}/product/pricelist/asoptions`);
    }

  public taxGroupsCreate(model: TaxGroupModel): Observable<any> {
    return this.srv.post(`${this.srv.baseUrl}/tax/Create/`, model);
  }

  public taxGroupsUpdate(model: TaxGroupModel): Observable<any> {
    return this.srv.post(`${this.srv.baseUrl}/tax/Update/`, model);
  }

  public getLanguageOptions(): Observable<any> {
    return this.srv.get(`${this.srv.baseUrl}/configuration/getLanguageOptions/`);
  }

  public getLanguage(): Observable<any> {
    return this.srv.get(`${this.srv.baseUrl}/configuration/getLanguage/`);
  }

  public languageCreate(model: LanguageModel): Observable<any> {
    return this.srv.post(`${this.srv.baseUrl}/configuration/languageCreate/`, model);
  }

  public languageUpdate(model: LanguageModel): Observable<any> {
    return this.srv.post(`${this.srv.baseUrl}/configuration/languageUpdate/`, model);
  }

  public getCurrencyOptions(): Observable<any> {
    return this.srv.get(`${this.srv.baseUrl}/configuration/getCurrencyOptions/`);
  }

  public getCurrency(): Observable<any> {
    return this.srv.get(`${this.srv.baseUrl}/configuration/getCurrency/`);
  }

  public currencyCreate(model: CurrencyModel): Observable<any> {
    return this.srv.post(`${this.srv.baseUrl}/configuration/currencyCreate/`, model);
  }

  public currencyUpdate(model: CurrencyModel): Observable<any> {
    return this.srv.post(`${this.srv.baseUrl}/configuration/currencyUpdate/`, model);
  }

  public getBillingDateOptions(): Observable<any> {
    return this.srv.get(`${this.srv.baseUrl}/configuration/getBillingDateOptions/`);
  }
}
