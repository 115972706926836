import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import swal from 'sweetalert2';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { PriceListItemModel } from '../../../models/product/price-list/price-list.model';
import { ProductService } from '../../../services';

@Component({
  selector: 'app-pricelist-available-products-modal',
  templateUrl: './pricelist-available-products-modal.component.html',
  styleUrls: ['./pricelist-available-products-modal.component.scss']
})
export class PricelistAvailableProductsModalComponent implements OnInit {
  @Input() reference: ModalTransferModel;
  priceListId: number;

  products: Array<PriceListItemModel> = [];
  selected: Array<number> = [];

  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;
  @Output() modalClose = new EventEmitter();
  
  validModel: boolean;
  loading: boolean;

  constructor(private prodSrv: ProductService) { }

  ngOnInit(): void {
    this.priceListId = this.reference.object;
    this.getPriceListItems(this.priceListId);
  }

  getPriceListItems(id: number) {
    this.loading = true;
    this.prodSrv.getProductVersionAvailableItems(id).subscribe(
      data => {
        this.loading = false;
        if (data['result']) {
          this.products = [];
          data['result'].forEach(x => {
            if (x) {
              this.products.push(PriceListItemModel.Parse(x));
            }
          });
        }
      },
      err => this.onError());
  }

  onError() {
    this.loading = false;
    swal({
      position: 'top-end',
      type: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
      footer: '<a href>Why do I have this issue?</a>'
    });
  }

  onCheckBoxChange() {
    this.validModel = this.products.some(x => x['checked']);
  }

  save() {
    const entry: PriceListItemModel[] = [];
    this.products.forEach(x => {
      if(x['checked']) {
        delete x['checked'];
        entry.push(x);
      }
    });
    this.close(entry);
  }

  close(entry?: PriceListItemModel[]) {
    this.open = false;
    this.modalClose.emit(entry);
  }
}
