import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { ConfigurationService, InternationalizationService } from '../../../services';
import { ListItemModel } from '../../../models/list-item.model';

@Component({
  selector: 'app-registration-queue-modal',
  templateUrl: './registration-queue-modal.component.html',
  styleUrls: ['./registration-queue-modal.component.scss']
})
export class RegistrationQueueModalComponent implements OnInit {
  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;
  @Input() reference: ModalTransferModel;
  model: any;
  title: string;
  languages: Array<ListItemModel> = [];
  currencies: Array<ListItemModel> = [];

  @Output() modalClose = new EventEmitter();

  constructor(public activeModal: NgbActiveModal, private confSrv: ConfigurationService,
    private i18Svc: InternationalizationService) {
      this.i18Svc.initialize();
     }

  ngOnInit() {
    this.initMode();

    this.confSrv.getCurrencyOptions()
      .subscribe(data => this.onGetCurrency(data), err => this.handleError(err));

    this.confSrv.getLanguageOptions()
      .subscribe(data => this.onGetLanguage(data), err => this.handleError(err));
  }
  initMode() {
    this.model = this.reference.object;
    // Set Title
    this.title = 'Registration Queue: ' + this.reference.object.firstName + ' ' + this.reference.object.lastName;
  }

  onGetLanguage(data): void {
    this.languages = data['result'];
    const selectedLanguage = this.languages.find(x => x.id === this.model.languageId);
    if (selectedLanguage) {
      this.model['languageValue'] = selectedLanguage.value;
    }
  }

  onGetCurrency(data): void {
    this.currencies = data['result'];
    const selectedCurrency = this.currencies.find(x => x.id === this.model.currencyId);
    if (selectedCurrency) {
      this.model['currencyValue'] = selectedCurrency.value;
    }
  }

  close(): void {
    this.open = false;
    this.modalClose.emit(false);
  }

  handleError(err) {
    console.error(err);
  }
}
